import * as React from 'react';
import * as ReactDataGrid from 'react-data-grid';
import * as moment from "moment";
import { Toolbar, Data } from 'react-data-grid-addons'

// Local modules
import { colsMeetings } from '../data/columns';
import { RSLink, Ellipsis, ActivityDate, PipedriveFormatter } from '../CustomComponents/GridComponents';
import GridCitasButton from "../CreateActivityButtons/GridCitasButton";
import ListoAuthService from '../utils/ListoAuthService';

const { Selectors } = Data

class GridCitas extends React.Component<any, any>{
  static defaultProps = {
    auth: new ListoAuthService()
  }

  private mounted: boolean = false

  constructor(props: any){
    super(props);
    this.state = {
      columns: [{key: 'id', name: 'ID', width: 50}],
      divheight: 950,
      rows: [],
      finishedLoading: false,
    };
    this.getRows = this.getRows.bind(this);
    this.getSize = this.getSize.bind(this);
    this.handleGridSort = this.handleGridSort.bind(this)
    this.handleRowUpdated = this.handleRowUpdated.bind(this)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.onClearFilters = this.onClearFilters.bind(this)
    this.rowGetter = this.rowGetter.bind(this)
  }

  componentWillUnmount(){
    this.mounted = false;
  }

  componentDidMount(){
    this.mounted = true;
    const cols = colsMeetings;
    let users: any;
    const usados: any[] = [], usa = {};
    cols.find(d => d.key === 'creacionCita')!.formatter =  <ActivityDate />;
    cols.find(d => d.key === 'fechaCita')!.formatter =  <ActivityDate />;
    cols.find(d => d.key === 'is_pipedrive')!.formatter =  <PipedriveFormatter />;
    cols.find(d => d.key === 'name')!.formatter =  <RSLink rowGetter={(x: any) => this.rowGetter(x)}/>;
    cols.find(d => d.key === 'edit')!.formatter =  <GridCitasButton edit={this.props.edit} auth={this.props.auth} rowGetter={(x: any) => this.rowGetter(x)}/>;
    const recursiveRequest = (offset: number, limit: number) => {
      this.props.auth.getLeadsCitas(offset, limit).then((res: any) => {
        const citas: any[] = [];
        res.data.results.forEach((lead: any) => {
          if(!usados.includes(lead.id)){
            const contact = lead.contacts.find((c: any) => c.id === lead.activity.concat)
            usados.push(lead.id);
            citas.push(Object.assign({}, lead.activity, {
              creacionCita: lead.activity.timestamp,
              fechaCita: lead.activity.estatus === 'Completada' ? lead.activity.timestamp : lead.activity.prox_act_prop,
              user: users.find((usr: any) => usr.id === lead.activity.user_id).name,
              encargadoName: users.find((usr: any) => usr.id === lead.activity.encargado).name,
              name: lead.name,
              leadId: lead.id,
              is_pipedrive: lead.is_pipedrive,
              tipoCita: lead.activity.tipo === 'Llamada' ? lead.activity.prox_act : lead.activity.tipo,
              contactName: contact ? contact.name : '',
              contactPhone: contact ? contact.phone : '',
            }))
          }
          else{ usa[lead.id] = lead.activity.timestamp }
        })
        const rows = this.state.rows.concat(citas).sort((x: any, y: any) => moment(y.fechaCita).diff(x.fechaCita, 'minutes'))
        Object.entries(usa).forEach(u => {
          rows.find((r: any) => r.leadId === parseInt(u[0], 10)).creacionCita = u[1]
        })
        this.setState({rows, finishedLoading: true})
        if(this.mounted && res.data.results.length !== 0){ recursiveRequest(offset + limit, limit);}
      })
    }

    this.setState({divheight: window.innerHeight, columns: cols});

    this.props.auth.getUsers().then((res: any)=> {
      users = res.data.objects
      recursiveRequest(0,50)
    })

    window.addEventListener("resize", () => {
      this.setState({divheight: window.innerHeight});
    });
  }

  getRows() {
    return Selectors.getRows(this.state);
  }

  getSize() {
    return this.getRows().length;
  }

  rowGetter(i: number){
    const rows = this.getRows();
    return rows[i];
  }

  handleGridSort(sortColumn: any, sortDirection: any) {
    const state = Object.assign({}, this.state, {sortColumn, sortDirection});
    this.setState(state);
  }

  handleRowUpdated(e: any){
    const rows = this.state.rows;
    Object.assign(rows[e.rowIdx], e.updated);
    this.setState({ rows });
  }

  handleFilterChange(filter: any){
    const newFilters = Object.assign({}, this.state.filters);
    if (filter.filterTerm) {
      newFilters[filter.column.key] = filter;
    } else {
     delete newFilters[filter.column.key];
    }
    this.setState({filters: newFilters});
  }

  onClearFilters(){
    this.setState({filters: {} });
  }

  render(){
    if (this.state.rows.length === 0){
      return <div className="no-columns-message">{this.state.finishedLoading ? 'No se tiene información' : <Ellipsis />}</div>
    }
    return (
      <div>
        <ReactDataGrid
          enableCellSelect={true}
          onGridSort={this.handleGridSort}
          onRowUpdated={this.handleRowUpdated}
          onAddFilter={this.handleFilterChange}
          onClearFilters={this.onClearFilters}
          toolbar={<Toolbar enableFilter={true} />}
          columns={this.state.columns}
          rowGetter={this.rowGetter}
          rowsCount={this.getSize()}
          minHeight={this.state.divheight-43-48-20-50} />;
      </div>);
  }
}

export default GridCitas;