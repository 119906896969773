import * as React from 'react';

export default (props: any) => {
  const buttonRows:any = [[]]
  if (props.options){
    props.options.map((option: any, i: any) => {
      const row = Math.floor(i / props.maxPerRow)
      const onClick = () => props.onClick(option)
      if(buttonRows.length === row){ buttonRows.push([]) }
      buttonRows[row].push(<div key={i} className='options-buttons'>
        <button className={`status-button ${option.class}${option.value === props.value ? '-selected':''}`}
          onClick={onClick}>
          {option.label}
        </button>
      </div>)
    })
  }
  return (
      <div className="button-dispatcher">
        <h4>{props.label}</h4>
        {buttonRows.map((row: any, i: any) => <div key={i} className='buttons-row'>{row}</div>)}
      </div>
  );
}