import * as moment from 'moment';
import * as React from 'react';
import {Button, Glyphicon} from 'react-bootstrap';
import Alert from 'react-s-alert';

// Local modules
import * as selectOptions from '../data/selectOptions';
import ActivityBaseForm from './ActivityBaseForm';
import ActivityRegister from './ActivityRegister';

class ActivityFormContainer extends React.Component<any, any>{

  constructor(props: any){
    super(props);
    this.state={dataRequired: [], marginBottom: 0, proximaHora: moment()}
    this.tipoOnChange = this.tipoOnChange.bind(this)
    this.encargadoOnChange = this.encargadoOnChange.bind(this)
    this.estatusOnChange = this.estatusOnChange.bind(this)
    this.filtroOnChange = this.filtroOnChange.bind(this)
    this.contactoOnChange = this.contactoOnChange.bind(this)
    this.razonOnChange = this.razonOnChange.bind(this)
    this.comentariosOnChange = this.comentariosOnChange.bind(this)
    this.proximaActividadOnChange = this.proximaActividadOnChange.bind(this)
    this.proximaDateTimeOnChange = this.proximaDateTimeOnChange.bind(this)
    this.proximoEncargadoOnChange = this.proximoEncargadoOnChange.bind(this)
    this.proximaFechaOnChange = this.proximaFechaOnChange.bind(this)
    this.proximaHoraOnChange = this.proximaHoraOnChange.bind(this)
    this.proximosComentariosOnChange = this.proximosComentariosOnChange.bind(this)
  }

  componentDidMount(){
    this.setState({initialTime: moment()})
    if(!this.props.contacts){
      this.props.auth.getContacts(this.props.leadId).then((res: any)=>{
        this.setState({ contacts: res.data.objects });
      })
    }
    else{
      this.setState({contacts: this.props.contacts})
    }
  }

  componentWillReceiveProps(nextProps: any){
    const pick = (o: any, ...someProps: any[]) => {
      return Object.assign({}, ...someProps.map(prop => ({[prop]: o[prop]})));
    }
    const props = ['tipo', 'encargado', 'estatus', 'area_contactada',
      'contact', 'razon_no_interes', 'prox_act', 'usuarios', 'listaSoftware']
    this.setState(Object.assign({}, pick(nextProps, ...props), this.state))
  }

  tipoOnChange(e: any){
    this.setState({tipo: e ? e.value : null});
    if(e && e.value === 'No enlazada'){
      this.setState({
        estatus: 'No Localizado',
        dataRequired: selectOptions.listaEstatusLlamada.find(i => i.value === 'No Localizado')!.required
      })
    }
  }

  encargadoOnChange(e: any){ this.setState({encargado: e ? e.id : null}); }

  estatusOnChange(e: any){ this.setState({estatus: e.value, dataRequired: e.required}); }

  filtroOnChange(e: any){ this.setState({area_contactada: e ? e.value : null}); }

  contactoOnChange(e: any){ this.setState({contact: e ? e.id : null}); }

  razonOnChange(e: any){ this.setState({razon_no_interes: e ? e.value : null}); }

  comentariosOnChange(e: any){ this.setState({descripcion: e.target.value});}

  proximaActividadOnChange(e: any){ this.setState({prox_act: e ? e.value : null}); }

  proximaDateTimeOnChange(e: any){ this.setState({prox_act_dt: e ? e.value : null}); }

  proximoEncargadoOnChange(e: any){ this.setState({encargado_prop: e ? e.id : null}); }

  proximaFechaOnChange(e: any){ this.setState({proximaFecha: e})}

  proximaHoraOnChange(e: any){ this.setState({proximaHora: e})}

  proximosComentariosOnChange(e: any){ this.setState({prox_act_anotacion: e.target.value}); }

  contactoNewOption(option: any){
    this.props.auth.createContact({name: option.name, lead_id: this.props.leadId})
    .then((res: any)=>{
      const ops = this.state.contacts;
      ops.unshift({name: option.name, id: res.data.iid})
      this.setState({contacts: ops})
    })
  }

  updateContacts(){
    this.props.auth.getContacts(this.props.leadId).then((res: any) => {
      this.setState({contacts: res.data.objects})
    })
  }

  buttonClick(ultimaLlamada: any, pipedrive: any){
    // Dont do anything if button was already clicked
    if (this.state.infoActualizada) {return true}

    // State to control when button was clicked
    this.setState({infoActualizada: true})

    ActivityRegister(ultimaLlamada, pipedrive,
      this.state, this.props.leadId, this.props.auth, this.props.campaignId)
    .then(res=>{
      Alert.success('Actividad guardada', {position: 'bottom-left', effect: 'jelly', timeout: 2000});
      this.props.onActivityCreate(res)
    })
    .catch(err=>{
      const message = err.error ? err.errorMessage : 'Error';
      console.log(ultimaLlamada, pipedrive, this.state, this.props.leadId, this.props.auth)
      this.setState({infoActualizada: false})
      Alert.error(message, {position: 'bottom-left', effect: 'jelly', timeout: 2000});
    })
    return
  }

  render(){
    let btnPipedrive, btnRejected, btnSave;
    const clickedPipedrive = () => this.buttonClick(true, true);
    const clickedRechazo = () => this.buttonClick(true, false);
    const clickedGuardar = () => this.buttonClick(false, true);

    if(this.state.dataRequired.includes('btn-pipedrive') && this.state.tipo !== selectOptions.listaTipo[0].value){
      btnPipedrive = (<div className="popup-tooltip">
          <Button className="btn-pipedrive" bsStyle="success" onClick={clickedPipedrive}>
            <Glyphicon glyph="ok" /> Mandar a Pipedrive</Button>
          <span className="popup-tooltiptext">Si el lead quiere conocer más sobre nosotros</span>
        </div>)
    }
    if(this.state.dataRequired.includes('btn-reject')){
      btnRejected = (<div className="popup-tooltip">
          <Button className="btn-rejected" bsStyle="danger" onClick={clickedRechazo}>
            <Glyphicon glyph="remove" /> Rechazo</Button>
          <span className="popup-tooltiptext">Si el lead rechazó nuestros servicios</span>
        </div>)
    }
    if(this.state.dataRequired.includes('btn-save')){
      btnSave = (
        <Button className="btn-save" bsStyle="primary" onClick={clickedGuardar}>
          <Glyphicon glyph="save" /> Guardar
        </Button>)
    }
    return (<div>
      <ActivityBaseForm
        tipoActividadProps={{
          label: 'Tipo',
          value: this.state.tipo,
          options: selectOptions.listaTipo,
          onClick: this.tipoOnChange,
        }}
        encargadoProps={{
          value: this.state.encargado,
          options: this.state.usuarios,
          onChange: this.encargadoOnChange,
          valueKey: 'id', labelKey: 'name',
        }}
        estatusProps={{
          label:'Estatus',
          value: this.state.estatus,
          dataRequired: this.state.dataRequired,
          options: this.state.tipo === 'Llamada' || this.state.tipo === 'Email' ? selectOptions.listaEstatusLlamada : (this.state.tipo === 'No enlazada' ? [] : selectOptions.listaEstatusCita),
          onClick: this.estatusOnChange,
        }}
        filtroProps={{
          value: this.state.area_contactada,
          options: selectOptions.listaAreaContactada,
          onChange: this.filtroOnChange,
        }}
        contactoProps={{
          value: this.state.contact,
          options: this.state.contacts,
          onChange: this.contactoOnChange,
          onNewOptionClick: this.contactoNewOption,
          valueKey: 'id', labelKey: 'name',
          onOpen: ()=>{this.props.auth.getContacts(this.props.leadId).then((res: any) => {
            this.setState({contacts: res.data.objects})
          })},
        }}
        razonNoInteresProps={{
          value: this.state.razon_no_interes,
          options: selectOptions.listaRazonesNoInteres,
          onChange: this.razonOnChange,
          onOpen: ()=>{this.setState({marginBottom: 100})},
          onClose: ()=>{this.setState({marginBottom: 0})},
        }}
        proximaActividadProps={{
          value: this.state.prox_act,
          options: selectOptions.listaTipo,
          onChange: this.proximaActividadOnChange,
        }}
        proximaDateTimeProps={{
          value: this.state.prox_act_dt,
          options: selectOptions.proximasFechas,
          onChange: this.proximaDateTimeOnChange,
          onOpen: ()=>{this.setState({marginBottom: 100})},
          onClose: ()=>{this.setState({marginBottom: 0})},
        }}
        proximoEncargadoProps={{
          value: this.state.encargado_prop,
          options: this.state.usuarios,
          onChange: this.proximoEncargadoOnChange,
          valueKey: 'id', labelKey: 'name',
          onOpen: ()=>{this.setState({marginBottom: 100})},
          onClose: ()=>{this.setState({marginBottom: 0})},
        }}
        descripcion={this.state.descripcion}
        comentariosOnChange={this.comentariosOnChange}
        proximosComentarios={this.state.prox_act_anotacion}
        proximosComentariosOnChange={this.proximosComentariosOnChange}
        proximaFecha={this.state.proximaFecha}
        proximaFechaOnChange={this.proximaFechaOnChange}
        proximaHoraOnChange={this.proximaHoraOnChange}
        leadId={this.props.leadId}
        />
        <div style={{marginBottom: this.state.marginBottom}}>
        {btnPipedrive}
        {btnRejected}
        {btnSave}
        </div>
    </div>)
  }
}

export default ActivityFormContainer;