import * as React from "react";
import * as Glyphicon from "react-bootstrap/lib/Glyphicon";
import Alert from 'react-s-alert';
import Popup from "../BasePopUp/Popup";
import PopupFormSinAsignar from "../CustomPopUps/PopupFormSinAsignar";
import {getMainOrDefault} from "../utils/commonFuncs";

export default class GridNuevosButton extends React.Component<any, any>{
  constructor(props: any){
    super(props);
    this.state={activityCreated: false}
    this.queuePopup = this.queuePopup.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.nextPopup = this.nextPopup.bind(this);
    this.deleteRow = this.deleteRow.bind(this);
  }

  handleClick(){
    this.queuePopup(this.props.rowIdx);
  }

  deleteRow(id: number){
    this.props.deleteRow(id);
    this.setState({activityCreated: true});
  }

  nextPopup(rowId: number){
    Popup.close();
    Alert.info('Abriendo siguiente Lead', {position: 'bottom-left', effect: 'jelly', timeout: 4000});
    // No se cambia el rowId porque previamente se borró el row, actualizando los rowId
    this.queuePopup(rowId);
  }

  queuePopup(rowIdx: number){
    const row = this.props.rowGetter(rowIdx);
    const changePopup = () => this.nextPopup(rowIdx)
    if(!row){ return false}
    this.props.auth.getLeadDetails(row.leadId).then((res: any) => {
      let branch;
      branch = getMainOrDefault(res.data.main_branch, res.data.branches)
      Popup.show({
          title: 'Introduce la información de la llamada',
          confirmCondition: () => this.state.activityCreated,
          content: <PopupFormSinAsignar
            auth={this.props.auth}
            rowId={rowIdx}
            deleteRow={this.deleteRow}
            software={res.data.software}
            numSucursales={res.data.branches.length}
            modules={res.data.modules}
            leadName={res.data.name}  // Para no confundir con branch.name
            changePopup={changePopup}
            main_contact={res.data.main_contact}
            main_branch={res.data.main_branch}
            {...row}  // Datos de la ultima actividad
            {...branch}  // Datos del Branch y Lead
            />
          });
      });
    return
  }

  render(){
    return (<div>
        <span
          onClick={this.props.edit===false ? () => undefined : this.handleClick.bind(this)}
          className={`edit-span ${this.props.edit===false ? 'disabled-edit' : ''}`}>
          <Glyphicon glyph="plus"/>
          <span> Crear actividad</span>
        </span>
      </div>);
  }
}
