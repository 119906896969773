import * as React from "react";
import * as moment from 'moment';
import * as ReactDataGrid from 'react-data-grid';
import Alert from "react-s-alert";
import { Toolbar, Data } from "react-data-grid-addons";

// Local modules
import { colsActivities } from "../data/columns";
import { RSLink, Estatus, ActivityDate, Ellipsis } from "../CustomComponents/GridComponents";
import GridActividadesButton from "../CreateActivityButtons/GridActividadesButton";
import ListoAuthService from "../utils/ListoAuthService";

const { Selectors } = Data

interface IState{
  columns: ReactDataGrid.Column[],
  divheight: number,
  rows: any[],
  finishedLoading: boolean,
  filters: object
}

interface IProps{
  auth: any,
  edit: boolean,
  campaignId: number,
}

export default class GridActividades extends React.Component<IProps, IState>{
  static defaultProps = {
    auth: new ListoAuthService()
  }

  private usados: any = []

  private mounted: boolean = false

  constructor(props: any){
    super(props);
    this.state = {
      columns: [],
      divheight: 900,
      rows: [],
      finishedLoading: false,
      filters: {}
    }
    this.getRows = this.getRows.bind(this);
    this.getSize = this.getSize.bind(this);
    this.rowGetter = this.rowGetter.bind(this);
    this.updateRow = this.updateRow.bind(this);
    this.recursiveRequest = this.recursiveRequest.bind(this);
    this.handleGridSort = this.handleGridSort.bind(this)
    this.handleRowUpdated = this.handleRowUpdated.bind(this)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.onClearFilters = this.onClearFilters.bind(this)
    this.rowGetter = this.rowGetter.bind(this)
  }

  componentWillUnmount(){
    this.mounted = false;
  }

  componentWillReceiveProps(np: IProps){
    if(np.campaignId !== this.props.campaignId){
      this.setState({rows: [], finishedLoading: false}, () => this.recursiveRequest(0, 50, np.campaignId))
    }
    this.usados = []
  }

  componentWillMount(){
    this.usados = []
  }

  componentDidMount(){
    this.mounted = true;
    const cols = colsActivities;
    cols.find(d => d.key === 'name' )!.formatter =  <RSLink rowGetter={(x: any) => this.rowGetter(x)}/>;
    cols.find(d => d.key === 'estatus' )!.formatter =  <Estatus rowGetter={(x: any) => this.rowGetter(x)}/>;
    cols.find(d => d.key === 'prox_act_prop' )!.formatter =  <ActivityDate />;
    cols.find(d => d.key === 'timestamp')!.formatter =  <ActivityDate />;
    cols.find(d => d.key === 'id')!.formatter =  <GridActividadesButton
      edit={this.props.edit} auth={this.props.auth}
      updateRow={this.updateRow} campaignId={this.props.campaignId}
      rowGetter={this.rowGetter}/>;

    this.setState({divheight: window.innerHeight, columns: cols});

    this.recursiveRequest(0, 50);

    window.addEventListener("resize", () => {
      this.setState({divheight: window.innerHeight});
    });
  }

  recursiveRequest(offset: number, limit: number, campaign: any=null){
    const colors = this.props.auth.getUserColors();
    this.props.auth.getLeadsActividades(campaign || this.props.campaignId, offset, limit).then((res: any)=>{
      const activities: any[] = [];
      res.data.results.forEach((lead: any)=> {
        // console.log(lead.name, lead.id, usados.includes(lead.id), usados)
        if(!this.usados.includes(lead.id)){
          this.usados.push(lead.id);
          activities.push(Object.assign({}, lead.activity, {
            color: colors[lead.activity.estatus],
            leadId: lead.id,
            name: lead.name,
            total_activities: lead.total_activites
          }))
        }
      })
      const rows = this.state.rows.concat(activities).sort((x,y) =>{
        return moment(y.prox_act_prop).diff(x.prox_act_prop, 'minutes');
      })
      this.setState({rows, finishedLoading: true})
      if(this.mounted && res.data.results.length !== 0){
        this.recursiveRequest(offset + limit, limit)
      }
    })
  }

  updateRow(leadId: number, newData: any){
    const colors = this.props.auth.getUserColors();
    let rows = this.state.rows;
    const index = rows.findIndex(row => row.leadId === leadId)
    console.log(rows[index], newData)
    rows[index] = Object.assign({}, rows[index], newData, {
      color: colors[newData.estatus],
      timestamp: moment()
    })
    rows = rows.filter((el, i, arr) => {
      return moment(el.prox_act_prop).isBefore('2099-01-01T00:00:00');
    }).sort((x,y) =>{
      return moment(y.prox_act_prop).diff(x.prox_act_prop, 'minutes');
    })
    this.setState({rows})
  }

  getRows() {
    return Selectors.getRows(this.state);
  }

  getSize() {
    return this.getRows().length;
  }

  rowGetter(i: number){
    const rows = this.getRows();
    return rows[i];
  }

  handleGridSort(sortColumn: any, sortDirection: any) {
      const state = Object.assign({}, this.state, {sortColumn, sortDirection});
      this.setState(state);
  }

  handleRowUpdated(e: any){
    const rows = this.state.rows;
    Object.assign(rows[e.rowIdx], e.updated);
    this.setState({ rows });
  }

  handleFilterChange(filter: any){
    const newFilters = Object.assign({}, this.state.filters);
    if (filter.filterTerm) {
      newFilters[filter.column.key] = filter;
    } else {
     delete newFilters[filter.column.key];
    }
    this.setState({filters: newFilters});
  }

  onClearFilters(){
    this.setState({filters: {} });
  }

  render(){
    if (this.state.rows.length === 0){
      return <div className="no-columns-message">{this.state.finishedLoading ? 'No se tiene información' : <Ellipsis />}</div>
    }
    return <div>
       <ReactDataGrid
        enableCellSelect={true}
        onGridSort={this.handleGridSort}
        onRowUpdated={this.handleRowUpdated}
        onAddFilter={this.handleFilterChange}
        onClearFilters={this.onClearFilters}
        toolbar={<Toolbar enableFilter={true} />}
        columns={this.state.columns}
        rowGetter={this.rowGetter}
        rowsCount={this.getSize()}
        minHeight={this.state.divheight-43-48-23} />;
        <Alert stack={{limit: 3}} html={true} />
      </div>
  }
}