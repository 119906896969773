import * as React from 'react';
import * as ReactDataGrid from 'react-data-grid';
import { Toolbar, Data } from 'react-data-grid-addons'

// Local modules
import { colsAllActivities } from '../data/columns';
import ListoAuthService from '../utils/ListoAuthService';
import { RSLink, Ellipsis, ActivityDate } from '../CustomComponents/GridComponents';

const { Selectors } = Data

interface Row{
  ejecutivo: string;
  encargado: string;
  total_activities: number;
  name: string;
  tipo: string;
  timestamp: string;
  estatus: string;
  prox_act: string;
  prox_act_prop: string;
}

interface State{
  columns: ReactDataGrid.Column[];
  divHeight: number;
  rows: Row[];
  rowsBack: Row[];
  filterActivitiesOverdue: boolean;
  finishedLoading: boolean;
  filters?: object;
}

class GridTodasActividades extends React.Component<any, State>{
  static defaultProps = {
    auth: new ListoAuthService()
  }

  private usados: any = new Set()

  private mounted: boolean = false

  constructor(props: any){
    super(props);
    this.state = {
      columns: [{key: 'color', name: ' ', width: 50}],
      divHeight: 900,
      rows: [],
      rowsBack: [],
      finishedLoading: false,
      filterActivitiesOverdue: false
    }
    this.getRows = this.getRows.bind(this);
    this.getSize = this.getSize.bind(this);
    this.handleGridSort = this.handleGridSort.bind(this)
    this.handleRowUpdated = this.handleRowUpdated.bind(this)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.onClearFilters = this.onClearFilters.bind(this)
    this.rowGetter = this.rowGetter.bind(this)
  }

  componentWillUnmount(){
    this.mounted = false;
  }

  componentDidMount(){
    this.mounted = true;
    this.usados = new Set()
    const cols = colsAllActivities;
    cols.find(d => d.key === 'name')!.formatter =  <RSLink rowGetter={(x: any) => this.rowGetter(x)}/>;
    cols.find(d => d.key === 'timestamp')!.formatter =  <ActivityDate />;
    cols.find(d => d.key === 'prox_act_prop')!.formatter =  <ActivityDate />;

    this.recursivePromise(0, 200, this.props.auth.getUserId())
    this.setState({divHeight: window.innerHeight, columns: cols});

    window.addEventListener("resize", () => {
      this.setState({divHeight: window.innerHeight});
    });
  }

  recursivePromise = (offset: number, limit: number, userId: number) => {
    this.props.auth.getAllActivities(offset, limit, userId).then((res: any)=>{
      const rows: Row[] = [];
      res.data.results.forEach((lead: any) => {
        if(!this.usados.has(lead.id)){
          this.usados.add(lead.id);
          rows.push(Object.assign(
            {}, lead.activity,
            {
              total_activities: lead.total_activities,
              leadId: lead.id,
              name: lead.name,
              ejecutivo: this.props.auth.getUsersList().find((u: any) => u.id === lead.activity.user_id)!.name,
              encargado: this.props.auth.getUsersList().find((u: any) => u.id === lead.activity.encargado)!.name
            })
          )
        }
      })

      this.setState({rows: this.state.rows.concat(rows), rowsBack: this.state.rows.concat(rows), finishedLoading: true})
      if(this.mounted && res.data.results.length !== 0){
        this.recursivePromise(offset + limit, limit, userId)
      }
    })
  }

  getRows() {
    return Selectors.getRows(this.state);
  }

  getSize() {
    return this.getRows().length;
  }

  rowGetter(i: number){
    const rows = this.getRows();
    return rows[i];
  }

  handleGridSort(sortColumn: any, sortDirection: any) {
      const state = Object.assign({}, this.state, { sortColumn, sortDirection});
      this.setState(state);
  }

  handleRowUpdated(e: any){
    const rows = this.state.rows;
    Object.assign(rows[e.rowIdx], e.updated);
    this.setState({ rows });
  }

  handleFilterChange(filter: any){
    const newFilters = Object.assign({}, this.state.filters);
    if (filter.filterTerm) {
      newFilters[filter.column.key] = filter;
    } else {
     delete newFilters[filter.column.key];
    }
    this.setState({filters: newFilters});
  }

  onClearFilters(){
    this.setState({filters: {} });
  }

  showActivitiesOverdue() {
    // Filter for activities overdue to the current date
    if (this.state.filterActivitiesOverdue) {
      this.setState(state => ({
        ...state,
        rows: state.rowsBack,
        filterActivitiesOverdue: false
      }))

    } else {
      this.setState(state => ({
        ...state,
        rows: state.rows.filter(activity => new Date(activity.prox_act_prop) <= new Date()),
        filterActivitiesOverdue: true
      }))
    }
  }
  
  render(){
    if (this.state.rows.length === 0){
      return (
        <>
          {this.state.filterActivitiesOverdue && 
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <button 
                className="btn" 
                style={{ margin: 10, backgroundColor: '#233f4b', color: 'white' }} 
                onClick={() => this.showActivitiesOverdue()}
              >
                Todas las actividades
              </button>
            </div>
          }
          <div className="no-columns-message">
            {this.state.finishedLoading ? 'No se tiene información' : <Ellipsis />}
          </div>
        </>
      )
    }
    return (
      <div>
       <ReactDataGrid
        enableCellSelect={true}
        onGridSort={this.handleGridSort}
        onRowUpdated={this.handleRowUpdated}
        onAddFilter={this.handleFilterChange}
        onClearFilters={this.onClearFilters}
        toolbar={
          <Toolbar 
            enableFilter={true}
            children={
              <button 
                className="btn" 
                style={{ marginLeft: 5, backgroundColor: '#233f4b', color: 'white' }} 
                onClick={() => this.showActivitiesOverdue()}
              >
                {this.state.filterActivitiesOverdue 
                  ? 'Todas las actividades'
                  : 'Actividades atrasadas'
                }
              </button>
            }
          />
        }
        columns={this.state.columns}
        rowGetter={this.rowGetter}
        rowsCount={this.getSize()}
        minHeight={this.state.divHeight-43-48-23} />;
      </div>);
  }
}

export default GridTodasActividades;