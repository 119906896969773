import * as React from 'react';
import {Form, FormGroup, FormControl, Button, ButtonToolbar} from 'react-bootstrap';
import {NewLeadPageState} from './models';
import ListoAuthService from "../utils/ListoAuthService";

const AUTH = new ListoAuthService()

export default class NewLeadPage extends React.Component<{}, NewLeadPageState>{

  constructor(props: {}){
    super(props);
    this.state = {
      name: '',
      phone: '',
      contactName: '',
      loading: false,
      creadosCampaignId: AUTH.getBaseCampaign(),
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.nameChange = this.nameChange.bind(this);
    this.phoneChange = this.phoneChange.bind(this);
    this.contactNameChange = this.contactNameChange.bind(this);
  }

  componentDidMount(){
    AUTH.getCampaigns().then(res => {
      this.setState({creadosCampaignId: res.data.objects.find((o: any) => o.name === 'Creados').id})
    })
  }

  nameChange(e: any){
    this.setState({name: e.target.value})
  }

  phoneChange(e: any){
    this.setState({phone: e.target.value})
  }
  contactNameChange(e: any){
    this.setState({contactName: e.target.value})
  }

  handleSubmit(e: any){
    e.preventDefault()
    if (this.state.loading){ return }

    this.setState({loading: true})
    const {name, phone, contactName} = this.state

    AUTH.addNewLead(name, phone, contactName).then(() => {
      window.location.replace(`/nuevos?campaign=${this.state.creadosCampaignId}`)
    })
  }

  render(){
    return (
      <div className="login-wrapper">
        <div className="login-internal-wrapper">
          <Form onSubmit={this.handleSubmit}>
            <FormGroup controlId="name">
              <FormControl placeholder="Nombre" value={this.state.name} onChange={this.nameChange} required={true} />
            </FormGroup>

            <FormGroup controlId="contactName">
              <FormControl placeholder="Nombre contacto" value={this.state.contactName} onChange={this.contactNameChange} required={true}/>
            </FormGroup>

            <FormGroup controlId="phone">
              <FormControl placeholder="Teléfono" value={this.state.phone} onChange={this.phoneChange} required={true}/>
            </FormGroup>

            <ButtonToolbar>
              <Button type="submit" bsStyle="primary">Agregar</Button>
            </ButtonToolbar>
          </Form>
        </div>
      </div>)
  }
}
