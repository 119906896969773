import * as React from 'react';
import {Form, FormGroup, FormControl, Button, ButtonToolbar} from 'react-bootstrap';
import ListoAuthService from "./utils/ListoAuthService";

export class Login extends React.Component<any, any>{
  static defaultProps = {
    auth: new ListoAuthService()
  }

  constructor(props: any, context: any){
    super(props, context);
    this.state = {email: '', pw: ''}
    this.handleSubmit = this.handleSubmit.bind(this)
    this.emailChange = this.emailChange.bind(this)
    this.pwChange = this.pwChange.bind(this)
  }

  handleSubmit(e: any) {
    e.preventDefault();
    this.props.auth.login(this.state.email, this.state.pw)
  }

  pwChange(e: any){
    this.setState({pw: e.target.value})
  }

  emailChange(e: any){
    this.setState({email: e.target.value})
  }

  render() {
    return (
      <div className="login-wrapper">
        <div className="login-internal-wrapper">
          <div style={{ borderBottom: '1px solid #ccc'}}>
            <img id="listo-logo-login" width="48" height="30" alt="" />
            <img id="listo-letters-login" width="64" height="40" alt="" />
          </div>
          <h3 style={{marginTop:15, marginBottom: 10}}>Iniciar Sesión</h3>
          <Form onSubmit={this.handleSubmit}>
            <FormGroup controlId="email">
              <FormControl placeholder="Correo electrónico" value={this.state.email} onChange={this.emailChange} required={true} />
            </FormGroup>

            <FormGroup controlId="password">
              <FormControl type="password" placeholder="Contraseña" value={this.state.pw} onChange={this.pwChange} />
            </FormGroup>

            <ButtonToolbar>
              <Button type="submit" bsStyle="primary">Entrar</Button>
            </ButtonToolbar>
          </Form>
        </div>
      </div>
    )
  }
}

export default Login