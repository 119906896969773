import * as React from 'react';
import { Button, Glyphicon, Table } from 'react-bootstrap';
import Alert from 'react-s-alert';

// Local modules
import { telFormat, telValue } from '../data/otros';

// Row con información
class ContactRow extends React.Component<any, any>{
  constructor(props: any){
    super(props);
    this.state={
      name:'',
      role:'',
      email:'',
      phone:''
    };
    this.update = this.update.bind(this)
    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.onClickFlag = this.onClickFlag.bind(this)
  }

  componentDidMount(){
    this.setState(Object.assign({editing:false},this.props))
  }

  handleKeyDown(e: any){
    if(e.key === 'Enter'){
      this.setState({editing:false});
      this.update();
    }
    else if(e.key === 'Escape'){
      this.setState({editing:false});
    }
  }

  update(){
    this.setState({editing:false});
    this.props.auth.editContact(this.props.id, {
        name: this.state.name,
        role: this.state.role,
        email: this.state.email,
        phone: this.state.phone
    }).then(()=>{
      Alert.success(`Contacto editado correctamente`, { position: 'bottom-left', effect: 'jelly', timeout: 2000});
    }).catch(()=>{
      Alert.error(`Error al editar`, { position: 'bottom-left', effect: 'jelly', timeout: 2000});
    })

  }

  onClickFlag(){
    this.props.auth.editLead(this.props.leadId, 'main_contact', this.props.id).then(()=>{
      this.props.mainContactChange(this.props.id);
      Alert.success(`Contacto principal definido`, { position: 'bottom-left', effect: 'jelly', timeout: 2000});
    }).catch(()=>{
      console.log('Error al setMainContact en ContactRow > this.props.auth.editLead');
      console.log(this.props.leadId, 'main_contact', this.props.id);
      Alert.error(`Error`, { position: 'bottom-left', effect: 'jelly', timeout: 2000});
    })
  }

  render(){
    const {editing} = this.state, opts = editing ? {} : {readOnly: true};
    let glyph
    if(editing){
      glyph = <Button bsStyle="link" onClick={this.update}
                      className="padding-zero black-font contact-add">
                <Glyphicon className='branches-glyph' glyph="saved"/>
              </Button>}
    else{
      glyph = <Button onClick={()=>{this.setState({editing: !this.state.editing})}}
                      className="padding-zero black-font contact-edit" bsStyle="link">
                <Glyphicon className='branches-glyph' glyph="pencil"/>
              </Button>}
    return(<tr>
      <td><input className="contact-input" onKeyDown={this.handleKeyDown}
                 value={this.state.name || ''}
                 onChange={e=>{this.setState({name: e.target.value})}} {...opts}/></td>
      <td><input className="contact-input" onKeyDown={this.handleKeyDown}
                 value={this.state.role || ''}
                 onChange={e=>{this.setState({role: e.target.value})}} {...opts}/></td>
      <td><input className="contact-input" onKeyDown={this.handleKeyDown}
                 value={this.state.email || ''}
                 onChange={e=>{this.setState({email: e.target.value})}} {...opts}/></td>
      <td><input className="contact-input" onKeyDown={this.handleKeyDown}
                 value={editing ? telValue(this.state.phone) : telFormat(this.state.phone)}
                 onChange={e=>{this.setState({phone: telValue(e.target.value)})}} {...opts}/></td>
      <td>
        {glyph}
        <Button onClick={this.onClickFlag} className="padding-zero black-font"
                bsStyle="link">
          <Glyphicon style={{color: this.props.mainContact === this.props.id ? 'red' : '#333'}}
                     className='branches-glyph' glyph="flag"/>
        </Button>
      </td>
    </tr>)}
}

class NewContactRow extends React.Component<any, any>{
  constructor(props: any){
    super(props);
    this.state={
      name: '',
      role: '',
      email: '',
      phone: ''
    }
    this.nameChange = this.nameChange.bind(this)
    this.roleChange = this.roleChange.bind(this)
    this.emailChange = this.emailChange.bind(this)
    this.phoneChange = this.phoneChange.bind(this)
    this.send = this.send.bind(this)
  }

  nameChange(e: any){this.setState({name: e.target.value});}

  roleChange(e: any){this.setState({role: e.target.value});}

  emailChange(e: any){this.setState({email: e.target.value});}

  phoneChange(e: any){this.setState({phone: e.target.value});}

  send(){
    if(this.state.name){
      this.props.auth.createContact(Object.assign({}, this.state, {lead_id:this.props.leadId})).then((res: any)=>{
        Alert.success('Contacto agregado', {
          position: 'bottom-left',
          effect: 'jelly',
          timeout: 2000,
        })
        this.props.pushContact(Object.assign({}, this.state, {id:res.data.iid}));
        this.setState({name: '', role: '', email: '', phone: ''})
      }).catch(() => {
        console.log(this.state)
        Alert.error(`Error`, {
          position: 'bottom-left',
          effect: 'jelly',
          timeout: 2000,
        })
      })
    }
    else{
      Alert.error(`Debes poner el nombre del contacto`, {
        position: 'bottom-left',
        effect: 'jelly',
        timeout: 2000,
      })
    }
  }

  render(){
    return(<tr>
      <td><input className="contact-input" value={this.state.name}
                 onChange={this.nameChange}/></td>
      <td><input className="contact-input" value={this.state.role}
                 onChange={this.roleChange}/></td>
      <td><input className="contact-input" value={this.state.email}
                 onChange={this.emailChange}/></td>
      <td><input className="contact-input" value={this.state.phone}
                 onChange={this.phoneChange}/></td>
      <td><Button onClick={this.send} className="contact-add" bsStyle="link">
        <Glyphicon glyph="plus"/></Button></td>
    </tr>)}
}

class ContactInformation extends React.Component<any, any>{
  constructor(props: any){
    super(props);
    this.state={
      key:1
    }
    this.addContact = this.addContact.bind(this)
  }

  componentWillReceiveProps(nextProps: any){
    this.setState({contacts:nextProps.contacts, mainContact: nextProps.mainContact})
  }

  addContact(contact: any){
    // Se modifica key para volver a formar el componente NewContactRow desde 0
    this.setState({contacts: this.state.contacts.concat([contact]), key:this.state.key+1})
  }

  render(){
    let prefilled, news;
    news = <NewContactRow
      auth={this.props.auth}
      leadId={this.props.leadId}
      pushContact={this.addContact} key={this.state.key} />
    prefilled = this.state.contacts && this.state.contacts.map((obj: any, i: number) => <ContactRow
      key={i}
      auth={this.props.auth}
      leadId={this.props.leadId}
      mainContact={this.state.mainContact}
      mainContactChange={(contactId: any)=>{this.setState({mainContact: contactId})}}
      {...obj}/>);
    return (<div><Table striped={true} bordered={true} condensed={true} hover={true}>
      <colgroup>
        <col style={{width:'22.5%'}}/>
        <col style={{width:'22.5%'}}/>
        <col style={{width:'22.5%'}}/>
        <col style={{width:'22.5%'}}/>
        <col style={{maxWidth:'5%'}}/>
      </colgroup>
      <thead>
        <tr>
          <th>Nombre</th>
          <th>Puesto</th>
          <th>Correo</th>
          <th>Teléfono</th>
          <th>
            <div className="popup-tooltip">
              <Button className="padding-zero black-font" bsStyle="link">
                <Glyphicon glyph="plus" className='branches-glyph'/></Button>
              <span className="popup-tooltiptext">Añadir nuevo</span>
            </div>
            <div className="popup-tooltip">
              <Button className="padding-zero black-font" bsStyle="link">
                <Glyphicon glyph="pencil" className='branches-glyph'/></Button>
              <span className="popup-tooltiptext">Editar contacto</span>
            </div>
            <div className="popup-tooltip">
              <Button className="padding-zero black-font" bsStyle="link">
                <Glyphicon glyph="flag" className='branches-glyph'/></Button>
              <span className="popup-tooltiptext">Marcar como contacto principal</span>
            </div>
            <div className="popup-tooltip">
              <Button className="padding-zero black-font" bsStyle="link">
                <Glyphicon glyph="saved" className='branches-glyph'/></Button>
              <span className="popup-tooltiptext">Guardar cambios</span>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        {prefilled}
        {news}
      </tbody>
      </Table>
    </div>);
  }
}

export default ContactInformation;