import * as React from 'react';
import {Button, Glyphicon, ListGroup, ListGroupItem} from 'react-bootstrap';
import Alert from 'react-s-alert';

class CustomInput extends React.Component<any, any>{
  constructor(props: any){
    super(props);
    this.state={
      value:'',
      transferido: false,
      options:[],
    }
    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.inputChange = this.inputChange.bind(this)
    this.getOptions = this.getOptions.bind(this)
    this.buttonClick = this.buttonClick.bind(this)
  }

  inputChange(e: any){
    const name = e.target.value;
    this.setState({value: name})
    this.getOptions(name);
  }

  handleKeyDown(e: any){
    if(e.key === 'Enter'){
      this.setState({editing:false});
      this.buttonClick();
    }
    else if(e.key === 'Escape'){
      this.setState({editing:false});
    }
  }

  getOptions(name: any){
    this.props.auth.searchLeads(name).then((res: any)=>{
      this.setState({options: res.data.objects.map((obj: any) => ({name: obj.name, id: obj.id}) ) });

    })
  }
  buttonClick(){
    this.props.auth.editBranch(this.props.branchId, 'leadId', this.state.options.find((o: any) => o.name === this.state.value).id).then(()=>{
      this.setState({transferido: true})
      this.props.onTransfer(this.props.branchId)
      Alert.success(`${this.props.name} transferido a ${this.state.value}`, {
        position: 'bottom-left', effect: 'jelly', timeout: 2000,
      });
    }).catch(()=>{
      console.log('Error en PopUpTransferBranch editBranch')
      console.log(this.props.branchId, 'leadId', this.state.options.find((o: any) => o.name === this.state.value).id)
      Alert.error(`Error`, {
        position: 'bottom-left', effect: 'jelly', timeout: 2000,
      });
    })
  }

  render(){
    let message;
    if(this.state.transferido){
      message = (
        <ListGroup className="popup-alert">
          <ListGroupItem bsStyle='success'>
            {`Sucursal ${this.props.name} transferida a: `}
            <a href={this.state.options.find((o: any) => o.name === this.state.value).id}>{this.state.value}</a>
          </ListGroupItem>
        </ListGroup>);
    }
    return (<div>
      <div style={{overflow: 'hidden'}}>
      <div style={{float: 'left', width: '80%'}}>
        <input
          list="groups"
          id="lead-input"
          placeholder="Escribe el nombre del Lead al que se va a transferir"
          value={this.state.value}
          ref={input => input && input.focus()}
          onKeyDown={this.handleKeyDown}
          onChange={this.inputChange}/>
          <datalist id="groups">
            {this.state.options.map((option: any, i: any) => <option value={option.name} key={i}/>)}
          </datalist>
      </div>
      <Button style={{float:'right'}} bsStyle="primary" onClick={this.buttonClick}><Glyphicon glyph="share" /> Transferir</Button>
      </div>
      {message}
    </div>);
  }
}

class PopupFormTransferBranch extends React.Component<any, any>{
  constructor(props: any){
    super(props);
    this.state = {
      infoActualizada: false,
      groupName: '',
      dominio: '',
      website: '',
    }
  }

  render(){
    return (
      <div id="popup-form-actividades-wrapper">
        <div className="with-margin">
          <h3 className="capitalize-all-words" style={{marginTop: 0}}>{this.props.name.toLowerCase()}</h3>
            <CustomInput
              auth={this.props.auth}
              name={this.props.name}
              branchId={this.props.branchId}
              onTransfer={this.props.onTransfer}/>
        </div>
        <Alert stack={{limit: 3}} html={true} />
      </div>
    );
  }
}

export default PopupFormTransferBranch;