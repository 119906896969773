import * as moment from "moment";
import TimePicker from 'rc-time-picker';
import * as React from 'react';
import { Glyphicon } from 'react-bootstrap';
import DatePicker from 'react-datepicker';

// Local modules
import ButtonsDispatcher from './ButtonsDispatcher';
import { CustomCreatable, CustomDropdown } from './CustomSelect';


export default (props: any) => {
  const contactoLabel = (<div>
    <div id="contacto-label">Contacto</div>
    <div id="contacto-label-link">
      <a target="_blank" href={`/${props.leadId}`} >
      Agregar más información<sup><Glyphicon glyph="new-window"/></sup>
      </a>
    </div>
  </div>)
  const dataRequired = [];
  let proxEncargado, specificDateTime, rendered;
  if(props.proximaActividadProps.value && props.proximaActividadProps.value !== 'Llamada'){
    proxEncargado = <CustomDropdown
      label="Próximo encargado"
      placeholder="Encargado"
      labelClassName="popup-nuevos-label dt"
      selectContClassName="popup-nuevos-dropdown sin-asignar"
      {...props.proximoEncargadoProps} />
  }
  if(props.proximaDateTimeProps.value === 0){
    specificDateTime = (<div style={{width: '100%', overflow: 'hidden'}}>
      <div className="popup-nuevos-label dt">Fecha y hora programada</div>
      <DatePicker
        dateFormat="DD MMM YYYY"
        className="date-picker-boxx"
        locale="es"
        utcOffset={-5}
        selected={props.proximaFecha}
        onChange={props.proximaFechaOnChange} />
      <TimePicker
        style={{marginLeft: 10}}
        onChange={props.proximaHoraOnChange}
        defaultValue={moment()}
        showSecond={false} />
    </div>)
  }
  if(props.estatusProps.dataRequired && props.estatusProps.dataRequired.includes('encargado')){
    dataRequired.push(<CustomDropdown
          label="Encargado"
          placeholder="Encargado"
          labelClassName="popup-nuevos-label dt"
          selectContClassName="popup-nuevos-dropdown sin-asignar"
          {...props.encargadoProps}/>)
  }
  if(props.estatusProps.dataRequired && props.estatusProps.dataRequired.includes('area_contactada')){
    dataRequired.push(<CustomDropdown
      label="Área contactada"
      placeholder="Filtro"
      labelClassName="popup-nuevos-label dt"
      selectContClassName="popup-nuevos-dropdown sin-asignar"
      {...props.filtroProps}/>)
  }
  if(props.estatusProps.dataRequired && props.estatusProps.dataRequired.includes('contact')){
    dataRequired.push(<CustomCreatable
      label={contactoLabel}
      placeholder="Contacto"
      labelClassName="popup-nuevos-label dt"
      selectContClassName="popup-nuevos-dropdown sin-asignar"
      {...props.contactoProps}/>)
  }
  if(props.estatusProps.dataRequired && props.estatusProps.dataRequired.includes('razon_no_interes')){
    dataRequired.push(<CustomDropdown
      label="Razón de no interés"
      placeholder="Razón de no interés"
      labelClassName="popup-nuevos-label dt"
      selectContClassName="popup-nuevos-dropdown sin-asignar"
      {...props.razonNoInteresProps}/>)
  }
  if(props.estatusProps.dataRequired && props.estatusProps.dataRequired.includes('descripcion')){
    dataRequired.push(<textarea
      rows={2}
      maxLength={255}
      placeholder="Comentarios o descripción..."
      value={props.descripcion}
      onChange={props.comentariosOnChange}/>)
  }
  if(props.estatusProps.dataRequired && props.estatusProps.dataRequired.includes('prox_act')){
    dataRequired.push(<div><h4 style={{marginTop: 0}} className="capitalize-all-words">Próxima Actividad:</h4>
      <CustomDropdown
        label="Tipo de actividad"
        placeholder="Actividad"
        labelClassName="popup-nuevos-label dt"
        selectContClassName="popup-nuevos-dropdown sin-asignar"
        {...props.proximaActividadProps}/>
      {proxEncargado}
      <CustomDropdown
        label="Fecha"
        placeholder="En cuánto tiempo"
        labelClassName="popup-nuevos-label dt"
        selectContClassName="popup-nuevos-dropdown sin-asignar"
        {...props.proximaDateTimeProps}/>
      {specificDateTime}
      <textarea
        rows={2}
        maxLength={255}
        placeholder="Anotaciones para la próxima actividad..."
        value={props.proximosComentarios}
        onChange={props.proximosComentariosOnChange}/>
      </div>)
  }
  rendered = dataRequired.map((component, i) => React.cloneElement(component, {key: i}))
  if(rendered.length === 0){
    rendered = <div className="no-dataRequired">Selecciona el estatus para desplegar información requerida</div>
  }
  return (
      <div>
        <ButtonsDispatcher
          maxPerRow={3}
          {...props.tipoActividadProps}/>
        <ButtonsDispatcher
          maxPerRow={3}
          {...props.estatusProps}/>
        {rendered}
      </div>
  );
}

