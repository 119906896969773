import * as React from 'react';
import { Glyphicon } from 'react-bootstrap';

// Local modules
import Popup from '../BasePopUp/Popup';
import PopupFormCitas from '../CustomPopUps/PopupFormCitas';
import {getMainOrDefault} from '../utils/commonFuncs'

export default class GridCitasButton extends React.Component<any, any>{
  constructor(props: any){
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }
  handleClick(){
    const row = this.props.rowGetter(this.props.rowIdx);
    Promise.all([this.props.auth.getLeadDetails(row.leadId), this.props.auth.getContacts(row.leadId)]).then(responses => {
      let branch, contacts;
      contacts = responses[0].data.contacts;
      branch = getMainOrDefault(responses[0].data.main_branch, responses[0].data.branches)
      Popup.show({
        title: 'Introduce la información de la llamada',
        content: <PopupFormCitas
          ejecutivos={this.props.ejecutivos}
          leadId={row.leadId}
          auth={this.props.auth}
          rs={row.name}
          contacts={contacts} // Lista de contactos del lead
          contacto={responses[1].data.objects.find((cntct: any) => cntct.id === row.contact)}
          software={responses[0].data.software}
          totalBranches={responses[0].data.branches.length}
          {...row}
          {...branch}
          />
        });
      });
  }

  render(){
    if(this.props.edit===false){
      return (<div>
        <span className="edit-span disabled-edit">
          <Glyphicon glyph="edit"/>
          <span> Editar</span>
        </span>
      </div>);
    }
    return (
      <div>
        <span onClick={this.handleClick} className="edit-span">
          <Glyphicon glyph="edit"/>
          <span> Editar</span>
        </span>
      </div>);
  }
}