export const baseUrl = 'https://allende.listo.mx';
// Solo para dev
// export const baseUrl = 'http://54.209.255.26:3000';
export const mainMarker = 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png';
export const defaultMarker = 'http://maps.google.com/mapfiles/ms/icons/red-dot.png';
export const draggableMarker = 'http://maps.google.com/mapfiles/ms/icons/yellow-dot.png';
export function telFormat(phone: string){
    if(!phone){
        return phone;}
    if(phone.length > 10){
        return [phone.slice(0, -10), ' ', phone.slice(-10, -8), ' ', phone.slice(-8, -4), ' ', phone.slice(-4)].join('')}
    else if(phone.length > 8 && phone.length <= 10){
        return [phone.slice(0, -8), ' ', phone.slice(-8, -4), ' ', phone.slice(-4)].join('')}
    else if(phone.length === 8){
        return [phone.slice(-8, -4), ' ', phone.slice(-4)].join('')}
    else{
        return phone}
}
export function telValue(phone: string){
    return phone ? phone.replace(/ /g, '') : '';
}