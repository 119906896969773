import * as React from 'react';
import {Button, Glyphicon} from 'react-bootstrap';
import Alert from 'react-s-alert';
import Select from 'react-select';

export default class PopupFormTransferir extends React.Component<any, any>{
  constructor(props: any){
    super(props);
    this.state = {
      infoActualizada: false,
      additionalheight: 0
    }
    this.ejecutivoOnChange = this.ejecutivoOnChange.bind(this)
    this.shrinkDiv = this.shrinkDiv.bind(this)
    this.expandDiv = this.expandDiv.bind(this)
    this.buttonClick = this.buttonClick.bind(this)
  }

  buttonClick(){
    if (this.state.infoActualizada){ return true; }
    const config = {
      lead_id: this.props.leadId,
      email: this.state.ejecutivo,
    }
    Promise.all([
      this.props.auth.assignAnotherUser(config),
      this.props.auth.editLead(config.lead_id, 'score', 2)]
    ).then((res: any)=> {
        this.props.callback(this.state.ejecutivo)
        Alert.success(`Transferido a ${this.state.ejecutivo}`, {
          position: 'bottom-left',
          effect: 'jelly',
          timeout: 2000,
        });
      })
      .catch(e => {
        console.log("Error en assignAnotherUser");
        console.log(config);
        console.log(config.lead_id, 'score', 100);
        Alert.error(`Error`, {
          position: 'bottom-left',
          effect: 'jelly',
          timeout: 2000,
        });
      });
    return
  }

  ejecutivoOnChange(e: any){ this.setState({ejecutivo: e ? e.value : null}); }

  expandDiv(){ this.setState({additionalheight: Math.min(this.props.options.length*36, 36*6)}); }

  shrinkDiv(){ this.setState({additionalheight: 0}); }

  render(){
    let sucursal;
    if(this.props.sucursal){
      sucursal = <h4 className="capitalize-all-words">{`Sucursal: ${this.props.sucursal}`}</h4>
    }
    return (
      <div id="popup-form-actividades-wrapper">
        <div className="with-margin">
          <h3 className="capitalize-all-words" style={{marginTop: 0}}>{this.props.razon_social}</h3>
          {sucursal}
          <div>
            <div className="popup-transferir-label dt">Transferir a:</div>
            <div
              className="popup-transferir-dropdown" style={{marginBottom: 15 + this.state.additionalheight}}>
              <Select
                placeholder="Ejecutivo"
                value={this.state.ejecutivo}
                onChange={this.ejecutivoOnChange}
                onClose={this.shrinkDiv}
                onOpen={this.expandDiv}
                options={this.props.options}/>
            </div>
          </div>
          <Button style={{float:'right'}} bsStyle="primary" onClick={this.buttonClick}><Glyphicon glyph="share" /> Transferir</Button>
        </div>
        <Alert stack={{limit: 3}} html={true} />
      </div>
    );
  }
}