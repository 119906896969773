import * as React from 'react';
import { render } from 'react-dom'
import Routes from './Routes';
import { BrowserRouter } from 'react-router-dom';

import './static/css/bootstrap-theme.css';
import './static/css/bootstrap.min.css';
import './static/css/custom_style.css';
import 'react-select/dist/react-select.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'rc-time-picker/assets/index.css';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/jelly.css';
import 'react-tabs/style/react-tabs.css';

render(<div>
  <BrowserRouter>
    <Routes />
  </BrowserRouter>
  </div>, document.getElementById('root'));