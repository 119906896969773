import * as moment from 'moment';

export default (ultimaLlamada: boolean, pipedrive: boolean, state: any, leadId: number, auth: any, campaignId: number)=>{
    let config: any, promises: any, date;
    let encargado = state.encargado;
    const {tipo, estatus, area_contactada, contact, razon_no_interes,
        descripcion, prox_act, prox_act_dt, encargado_prop, proximaFecha, proximaHora,
        prox_act_anotacion, initialTime, dataRequired} = state;

    // Validación
    if (!estatus){ return new Promise((resolve, reject)=>{
        reject({error:true, errorMessage: 'Estatus no puede ser vacío'})
      })}
    if (!encargado){ return new Promise((resolve, reject)=>{
        reject({error:true, errorMessage: 'Encargado no puede estar vacío'})
      })}
    if (!area_contactada && dataRequired.includes('area_contactada')){ return new Promise((resolve, reject)=>{
        reject({error:true, errorMessage: 'Área contacatada no puede estar vacía'})
      })}
    if (!prox_act && !ultimaLlamada){ return new Promise((resolve, reject)=>{
        reject({error:true, errorMessage: 'Próxima actividad no puede estar vacía'})
      })}
    if (prox_act_dt == null && !ultimaLlamada){ return new Promise((resolve, reject)=>{
        reject({error:true, errorMessage: 'Selecciona una proxima fecha'})
      })}
    if (proximaFecha && moment().diff(proximaFecha, 'days') > 0){ return new Promise((resolve, reject)=>{
        reject({error:true, errorMessage: 'La fecha es incorrecta'})
      })}
    if (estatus === 'No Interesado' && !razon_no_interes){ return new Promise((resolve, reject)=>{
        reject({error:true, errorMessage: 'Selecciona una razón de no interés'})
      })}

    if(ultimaLlamada){
        date = moment("2099-01-01").format('YYYY-MM-DDTHH:mm:ss')
    }
    else if(prox_act_dt !== 0){
        date = moment().add(prox_act_dt, 'minutes').format('YYYY-MM-DDTHH:mm:ss')
    }
    else if(prox_act_dt === 0){
        date = proximaFecha.hour(proximaHora.hour()).minute(proximaHora.minute()).format('YYYY-MM-DDTHH:mm:ss')
    }
    // Validar que no hayan cambiado encargado si es llamada
    encargado = tipo === 'Llamada' ? auth.getUserId(): encargado;
    config = {area_contactada, contact, descripcion, encargado, estatus, razon_no_interes, tipo,
        lead_id: leadId,
        encargado_prop: ultimaLlamada ? null : (encargado_prop ? encargado_prop : encargado),
        prox_act: ultimaLlamada ? null : prox_act,
        prox_act_anotacion: ultimaLlamada ? null : prox_act_anotacion,
        prox_act_prop: date,
        campaign_id: campaignId,
        call_time: Math.round(moment().diff(initialTime)),
        user_id: auth.getUserId()}

    promises = [auth.postActivity(config)]
    // Mandar a Pipedrive
    if(ultimaLlamada && pipedrive){
        promises.push(auth.editLead(leadId, 'is_pipedrive', true));
    }
    if(ultimaLlamada && !pipedrive){
        promises.push(auth.editLead(leadId, 'score', -3)); // Rechazo
    }
    // if(razon_no_interes == 'Gobierno') promises.push(auth.editLead(leadId, 'score', -1))
    return new Promise((resolve, reject)=>{
        Promise.all(promises).then(res=>{
            if(ultimaLlamada && pipedrive){
                auth.addToPipedrive(leadId, campaignId).then(() => {console.log('response de pipedrive')})
            }
            resolve(config);
        }).catch(err=>{
            console.log(config);
            reject({error:true, errorMessage: 'Error en el server'});
        })
    })
}