import * as React from 'react';
import ListoAuthService from "./utils/ListoAuthService";
import { AxiosResponse } from 'axios';

interface IState{
  campaigns: object[],
}

interface IProps{
  campaignId: number,
  onSelect: any,
  auth?: any,
}

export default class SideBarContent extends React.Component<IProps, IState>{
  static defaultProps = {
    auth: new ListoAuthService(),
  }

  constructor(props: any, context: any){
    super(props, context);
    this.state = {
      campaigns: []
    }
    this.onClick = this.onClick.bind(this);
  }

  componentDidMount(){
    this.props.auth.getCampaigns().then((r: AxiosResponse) =>{
      this.setState({campaigns: r.data.objects})
    })
  }

  onClick(campaign: number){
    this.props.onSelect(campaign)
  }

  rowFactory(item: any, i: number){
    const className = `pointer-cursor ${this.props.campaignId === item.id ? "sb-selected": ""}`
    return <li key={i} className={className} onClick={() => this.onClick(item.id)}>{item.name}</li>
  }

  render(){
    return <div>
      <img id="listo-logo" alt=""/>
      <ul className="sidebar-list">
        <li className="header">Campañas</li>
        <li className="no-padding"><div className="divider"/></li>
        {this.state.campaigns.map((c, i) => this.rowFactory(c, i))}
      </ul>
    </div>
  }
}
