import * as moment from 'moment';
import * as React from 'react';
import { Button, Glyphicon, ListGroup, ListGroupItem, Table } from 'react-bootstrap';
import Alert from 'react-s-alert';
import Select from 'react-select';

// Local Modules
import Popup from "../BasePopUp/Popup";
import BranchesMap from '../CustomComponents/BranchesMap';
import PopupFormBranch from '../CustomPopUps/PopupFormBranch';
import PopupFormTransferBranch from '../CustomPopUps/PopupFormTransferBranch';
import {defaultMarker, mainMarker} from '../data/otros';
import * as selectOptions from '../data/selectOptions';

declare global { interface Window { google: any; } }

class BranchRow extends React.Component<any, any>{
  constructor(props: any){
    super(props);
    this.state={};
    this.onClickInformation = this.onClickInformation.bind(this)
    this.onClickTransfer = this.onClickTransfer.bind(this)
    this.onClickFlag = this.onClickFlag.bind(this)
    this.onClickUpload = this.onClickUpload.bind(this)
  }

  onClickInformation(e: any){
    e.preventDefault();
    Popup.show({
      title: 'Información sucursal',
      content: <PopupFormBranch
        {...this.props}/>
    });
  }

  onClickTransfer(){
    Popup.show({
      title: `Transferir sucursal`,
      content: <PopupFormTransferBranch
        auth={this.props.auth}
        name={this.props.name}
        branchId={this.props.id}
        onTransfer={this.props.onTransfer}/>
    });
  }

  onClickFlag(){
    this.props.auth.editLead(this.props.leadId, 'main_branch', this.props.id).then(() => {
      this.props.mainBranchChange(this.props.id);
    }).catch(()=>{
      Alert.error(`Error`, {position: 'bottom-left', effect: 'jelly',timeout: 2000});
    })
  }

  onClickUpload(){
    this.props.auth.transformBranch(this.props.id).then((res: any)=>{
      this.props.onAscend(this.props.name, this.props.id, res.data.leadId)
      Alert.success(`Sucursal ascendida a Lead`, {position: 'bottom-left', effect: 'jelly',timeout: 2000});
    }).catch(()=>{
      Alert.error(`Error`, {position: 'bottom-left', effect: 'jelly', timeout: 2000});
    })
  }

  render(){
    const direccion = `${this.props.nom_vial ? this.props.nom_vial.toLowerCase() : ''}
      ${this.props.numero_ext ? '#'+this.props.numero_ext : '-sin número-'},
      ${this.props.nomb_asent ? this.props.nomb_asent.toLowerCase() : ''},
      ${this.props.localidad ? this.props.localidad.toLowerCase() : (this.props.municipio ? this.props.municipio.toLowerCase() : '')}`
    return(<tr>
      <td className="capitalize-all-words">{direccion}</td>
      <td className="capitalize-all-words">{this.props.name ? this.props.name.toLowerCase() : ''}</td>
      <td className="capitalize-all-words">{this.props.entidad ? this.props.entidad.toLowerCase() : ''}</td>
      <td>
        <Button className="padding-zero black-font" bsStyle="link" onClick={this.onClickInformation}>
          <Glyphicon glyph="info-sign" className='edit-glyph branches-glyph'/></Button>
        <Button className="padding-zero black-font" bsStyle="link" onClick={this.onClickTransfer}>
          <Glyphicon glyph="transfer" className='edit-glyph branches-glyph'/></Button>
        <Button className="padding-zero black-font" bsStyle="link" onClick={this.onClickFlag}>
          <Glyphicon glyph="flag" className='edit-glyph branches-glyph'
                     style={{color: this.props.mainBranch === this.props.id ? 'red' : '#333'}}
                     /></Button>
        <Button className="padding-zero black-font" bsStyle="link" onClick={this.onClickUpload}>
          <Glyphicon glyph="upload" className='edit-glyph branches-glyph' /></Button>
      </td>
    </tr>)}
}

class NewBranchRow extends React.Component<any, any>{
  constructor(props: any){
    super(props);
    this.state={
      tipo_asent: 'Colonia',
      tipo_vial: 'Calle',
      nom_vial: '',
      name: ''
    }
    this.calleChange = this.calleChange.bind(this)
    this.nameChange = this.nameChange.bind(this)
    this.ciudadChange = this.ciudadChange.bind(this)
    this.send = this.send.bind(this)
  }

  calleChange(e: any){this.setState({nom_vial: e.target.value});}

  nameChange(e: any){this.setState({name: e.target.value});}

  ciudadChange(e: any){this.setState({entidad: e ? e.value : null});}

  send(){
    if(this.state.name && this.state.nom_vial && this.state.entidad){
      const config = Object.assign({}, this.state, {
        leadId: this.props.leadId,
        created_on: moment().toISOString()
      })
      this.props.auth.createBranch(config).then((res: any) => {
        // Obtener Id y regresarlo también
        this.props.pushBranch(Object.assign({}, config, {id: res.data.iid}));
        this.setState({nom_vial: '', name: '', entidad: null})
        Alert.success(`Nuevo branch creado`, {position: 'bottom-left', effect: 'jelly', timeout: 2000})
      }).catch(()=>{
        Alert.error(`Error`, {position: 'bottom-left', effect: 'jelly',timeout: 2000})
        console.log('ocurrió un error en NewBranchRow > createBranch')
        console.log(config)
      })
    }
    else{
      Alert.error(`Debes llenar todos los campos para agregar un branch`, {
        position: 'bottom-left',
        effect: 'jelly',
        timeout: 2000,
      })
    }
  }

  render(){
    if(this.props.permissionToAdd){
      return(<tr>
        <td>
          <div className="popup-tooltip max-width">
            <input className="contact-input" value={this.state.nom_vial}
                   type="text" placeholder="Solo nombre de calle"
                   onChange={this.calleChange}/>
            <span className="popup-tooltiptext">
              {"El resto de la información se puede editar una vez que se haya agregado el branch"}
            </span>
          </div>
        </td>
        <td><input className="contact-input" value={this.state.name}
                   type="text" placeholder="Nombre sucursal"
                   onChange={this.nameChange}/></td>
        <td><Select value={this.state.entidad} options={selectOptions.listaCiudades}
                    onChange={this.ciudadChange} placeholder="Entidad" /></td>
        <td><Button onClick={this.send} className="contact-add"
                    bsStyle="link"><Glyphicon glyph="plus"/></Button></td>
      </tr>)}

    return(<tr><td colSpan={4}><div className="permission-message">
      {"No puedes agregar más branches hasta que guardes la ubicación del branch faltante"}
    </div></td></tr>)
  }
}

class BranchInformation extends React.Component<any, any>{
  constructor(props: any){
    super(props);
    this.state={
      ascended: false,
      branches: [],
      permissionToAdd: true
    }
    this.addBranch = this.addBranch.bind(this)
    this.setCoords = this.setCoords.bind(this)
  }

  componentWillReceiveProps(nextProps: any){
    const emptyCoords = (branch: any) => !Boolean(branch.coordinates)
    const permissionToAdd = !nextProps.branches.some(emptyCoords)
    this.setState({
      permissionToAdd,
      branches: nextProps.branches,
      mainBranch: nextProps.mainBranch,
    })
  }

  addBranch(branch: any){
    this.setState({
      branches: this.state.branches.concat([branch]),
      permissionToAdd: false
    })
  }

  setCoords(point: any){
    const emptyCoords = (branch: any) => !Boolean(branch.coordinates)
    const { branches } = this.state;
    const emptyBranchId = branches.find(emptyCoords).id
    branches.forEach((b: any, i: number, a: any[]) => {if(b.id === emptyBranchId){a[i].coordinates = point} })
    const permissionToAdd = !branches.some(emptyCoords)
    this.props.auth.editBranch(emptyBranchId, 'coordinates', point).then(() => {
      Alert.success(`Coordenadas guardadas`, {position: 'bottom-left', effect: 'jelly', timeout: 2000})
      this.setState({branches, permissionToAdd})
    }).catch(()=>{
      console.log('Error al setCoords en BranchInformation > this.props.auth.editBranch');
      console.log(emptyBranchId, 'coordinates', point);
      Alert.error(`Error`, {position: 'bottom-left', effect: 'jelly',timeout: 2000});
    })
  }

  render(){
    const branches: any[] = [], markers: any[] = [];
    let center: any, message;
    const news = <NewBranchRow auth={this.props.auth} pushBranch={this.addBranch}
                             leadId={this.props.leadId} permissionToAdd={this.state.permissionToAdd}/>

    if(this.state.ascended){
      message = (<tr><td colSpan={4}>
        <ListGroup className="popup-alert branch-message">
          <ListGroupItem bsStyle='success' className="branch-list-group-item">
            {`Sucursal ${this.state.ascendedBranchName} ascendida a nuevo `}
            <a href={this.state.ascendedBranchId}>Lead</a>
        </ListGroupItem></ListGroup>
        </td></tr>);
    }

    this.state.branches.forEach((branch: any, i: any) => {
      branches.push(<BranchRow
        key={i} auth={this.props.auth} mainBranch={this.state.mainBranch}
        leadId={this.props.leadId}
        editBranch={(attr: any, val: any) => this.props.auth.editBranch(branch.id, attr, val)}
        mainBranchChange={(branchId: any) => {this.setState({mainBranch: branchId})}}
        onTransfer={ (branchId: any) => {this.setState({branches: this.state.branches.filter((b: any) => b.id !== branchId)}) }}
        onAscend={ (branchName: any, branchId: any, newLeadId: any) => {
          this.setState({
            ascendedBranchName: branchName, ascendedBranchId: newLeadId,
            ascended: true, branches: this.state.branches.filter((b: any) => b.id !== branchId)})
        }}
        {...branch}/>)

      if (branch.coordinates){
        const coordinates = branch.coordinates.match(/(\d+\.\d+).+?(-?\d+\.\d+)/);
        // if undefined or mainbranch set center
        center = (!center || this.state.mainBranch) ? coordinates : center

        markers.push({ title: branch.name, name: branch.name,
                      icon: branch.id === this.state.mainBranch ? mainMarker : defaultMarker,
                      position: {lat: parseFloat(coordinates[1]), lng: parseFloat(coordinates[2])},
        })
      }
    })

    return (<div><Table striped={true} bordered={true} condensed={true} hover={true}>
      <colgroup>
        <col style={{minWidth: '40%', maxWidth:'70%', width: 'auto'}}/>
        <col style={{minWidth: '20%', maxWidth:'13%', width: 'auto'}}/>
        <col style={{minWidth: '10%', maxWidth:'15%', width: 'auto'}}/>
        <col style={{minWidth: '7%', maxWidth:'10%', width: 'auto'}}/>
      </colgroup>
      <thead>
        <tr>
          <th>Dirección</th>
          <th>Nombre</th>
          <th>Ciudad</th>
          <th>
            <div className="popup-tooltip">
              <Button className="padding-zero black-font" bsStyle="link">
                <Glyphicon glyph="info-sign" className='edit-glyph branches-glyph'/></Button>
              <span className="popup-tooltiptext">Información y edición</span>
            </div>
            <div className="popup-tooltip">
              <Button className="padding-zero black-font" bsStyle="link">
                <Glyphicon glyph="transfer" className='edit-glyph branches-glyph'/></Button>
              <span className="popup-tooltiptext">Transferir a otro Lead</span>
            </div>
            <div className="popup-tooltip">
              <Button className="padding-zero black-font" bsStyle="link">
                <Glyphicon glyph="flag" className='edit-glyph branches-glyph'/></Button>
              <span className="popup-tooltiptext">Marcar como sucursal principal</span>
            </div>
            <div className="popup-tooltip">
              <Button className="padding-zero black-font" bsStyle="link">
                <Glyphicon glyph="upload" className='edit-glyph branches-glyph'/></Button>
              <span className="popup-tooltiptext">Ascender a Lead</span>
            </div>
            <div className="popup-tooltip">
              <Button className="padding-zero black-font" bsStyle="link">
                <Glyphicon glyph="plus" className='edit-glyph branches-glyph'/></Button>
              <span className="popup-tooltiptext">Agregar Branch</span>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        {branches}
        {news}
        {message}
      </tbody>
      </Table>
      <BranchesMap zoom={12} markers={markers} google={window.google}
                   className="google-map" setCoords={this.setCoords}
                   enableSearch={!this.state.permissionToAdd}
                   style={{overflow: 'hidden', width: '100%', height: 467}}
                   center={{
                    lat: parseFloat(center ? center[1] : 19.417435),
                    lng: parseFloat(center ? center[2] : -99.1620404)}
                   }/>
    </div>);
  }
}

export default BranchInformation;