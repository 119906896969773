import * as React from "react";
import { Alert, Button, FormControl, FormGroup } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import ListoAuthService from '../utils/ListoAuthService';
import { CampaignContext } from "src/utils/contexts";

const auth = new ListoAuthService()

export const ConsolePage = () => {
    const [users, setUsers] = React.useState([])
    const [alert, setAlert] = React.useState({ visible: false, type: '', message: '' })

    const campaign: any = React.useContext(CampaignContext)

    const formik = useFormik({
        initialValues: {
            sdr_original: '',
            sdr_nuevo: '',
            cantidadLeads: 0
        },
        validationSchema: Yup.object({
            sdr_original: Yup.string().required("El usuario original es obligatorio"),
            sdr_nuevo: Yup.string().required("El usuario destino es obligatorio"),
            cantidadLeads: Yup.number().min(1, "No es un valor valido").required("Este campo es obligatorio")
        }),
        onSubmit: async (values) => {
            const { sdr_original, sdr_nuevo, cantidadLeads } = values
            const { status, data } = await auth.leadsDistribution(sdr_original, sdr_nuevo, cantidadLeads, campaign)

            if (status !== 200) {
                setAlert({ visible: true, type: 'danger', message: data })
                setTimeout(() => setAlert({ visible: false, type: '', message: "" }), 5000);

            } else {
                formik.resetForm()

                setAlert({ visible: true, type: 'success', message: 'Operación realizada correctamente' })
                setTimeout(() => setAlert({ visible: false, type: '', message: "" }), 5000);
            }
        }
    });

    const getUsers = async () => {
        const res: any = await auth.getUsers()
        setUsers(res.data.objects.map((element: any) => ({
            value: element.email,
            label: element.name
        })))
    }

    React.useEffect(() => { getUsers() }, [])

    return (
        <div
            className="container"
            style={{
                maxWidth: 480,
                backgroundColor: 'white',
                borderRadius: 10,
                padding: '15px',
                marginTop: 20,
                boxShadow: '0px 5px 9px 0px rgba(0,0,0,0.07)'
            }}
        >
            <h3 style={{ marginTop: 0, marginBottom: 15 }}>Reasignar leads</h3>
            <form onSubmit={formik.handleSubmit}>
                <FormGroup>
                    <Select
                        name="sdr_original"
                        placeholder="Usuario original"
                        onChange={(e: any) => formik.setFieldValue("sdr_original", e ? e.value : '')}
                        value={formik.values.sdr_original}
                        options={users}
                    />
                    {formik.touched.sdr_original && formik.errors.sdr_original && (
                        <p style={{ color: "red", marginTop: 5 }}>
                            {formik.errors.sdr_original}
                        </p>
                    )}
                </FormGroup>

                <FormGroup>
                    <Select
                        name="sdr_nuevo"
                        placeholder="Usuario destino"
                        onChange={(e: any) => formik.setFieldValue("sdr_nuevo", e ? e.value : '')}
                        value={formik.values.sdr_nuevo}
                        options={users}
                    />
                    {formik.touched.sdr_nuevo && formik.errors.sdr_nuevo && (
                        <p style={{ color: "red", marginTop: 5 }}>
                            {formik.errors.sdr_nuevo}
                        </p>
                    )}
                </FormGroup>

                <FormGroup>
                    <FormControl
                        name="cantidadLeads"
                        type='number'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.cantidadLeads}
                    />
                    {formik.touched.cantidadLeads && formik.errors.cantidadLeads && (
                        <p style={{ color: "red", marginTop: 5 }}>
                            {formik.errors.cantidadLeads}
                        </p>
                    )}
                </FormGroup>
                {
                    alert.visible && (
                        <Alert bsStyle={alert.type} className="mt-2">
                            {alert.message}
                        </Alert>
                    )
                }
                <Button type="submit" bsStyle="primary">Agregar</Button>
            </form>
        </div>
    )
}
