import * as React from 'react';
import { Table } from 'react-bootstrap';

// Local modules
import { Editable, EditableSelect } from '../CustomComponents/CustomEditable'
import { telFormat, telValue } from '../data/otros';
import * as selectOptions from '../data/selectOptions';

export default class PopupFormBranch extends React.Component<any, any>{
  constructor(props: any){
    super(props);
    this.state = {}
  }

  componentDidMount(){
    this.props.auth.getBranchDetails(this.props.id).then((r: any) => {
      this.setState(r.data)
    })
  }

  render(){
    return (<div>
      <div className='pu-branch-header'>
        <div className='pu-branch-header-name'>
          <Editable
          field="name"
          text={this.state.name}
          forcePlaceholder={true}
          placeholder="Nombre"
          editBranch={(val: any) => this.props.editBranch("name", val)} />
        </div>
        <div className='pu-branch-header-act-name'>
          <div className='to-bottom'>
          <Editable
            field="activity_name"
            forcePlaceholder={true}
            placeholder="Nombre actividad"
            text={this.state.activity_name}
            editBranch={(val: any) => this.props.editBranch("activity_name", val)}/>
          </div>
        </div>
      </div>
      <Table responsive={true}>
        <tbody>
          <tr>
            <td><b>Website:</b></td>
            <td><Editable placeholder={'sin website'} forcePlaceholder={true}
                          editBranch={(val: any) => this.props.editBranch("website", val)}
                          field="website" text={this.state.website} /></td>
            <td><b>Teléfono:</b></td>
            <td><Editable placeholder={'sin teléfono'} forcePlaceholder={true}
                          editBranch={(val: any) => this.props.editBranch("phone", val)}
                          onSave={telValue} formatter={telFormat}
                          field="phone" text={this.state.phone}/></td>
          </tr>
          <tr>
            <td><b>Email:</b></td>
            <td><Editable placeholder={'sin teléfono'} forcePlaceholder={true}
                          editBranch={(val: any) => this.props.editBranch("email", val)}
                          field="email" text={(this.state.email || '').toLowerCase()}/></td>
            <td><b>Código Postal:</b></td>
            <td><Editable placeholder={'sin código postal'} forcePlaceholder={true}
                          editBranch={(val: any) => this.props.editBranch("postal_code", val)}
                          field="postal_code" text={this.state.postal_code}/></td>
          </tr>
          <tr>
            <td><b>Entidad:</b></td>
            <td className="capitalize-all-words">
              <EditableSelect
                field="entidad"
                editBranch={(val: any) => this.props.editBranch("entidad", val)}
                selectProps={{
                  placeholder: 'Entidad',
                  options: selectOptions.listaCiudades,
                }}
                text={(this.state.entidad || '').toLowerCase()}/>
            </td>
            <td><b>Municipio:</b></td>
            <td className="capitalize-all-words">
              <Editable placeholder={'sin municipio'} forcePlaceholder={true}
                        editBranch={(val: any) => this.props.editBranch("municipio", val)}
                        field="municipio" text={(this.state.municipio || '').toLowerCase()}/></td>
          </tr>
          <tr>
            <td><b>Localidad:</b></td>
            <td className="capitalize-all-words">
              <Editable placeholder={'sin localidad'} forcePlaceholder={true}
                        editBranch={(val: any) => this.props.editBranch("localidad", val)}
                        field="localidad" text={(this.state.localidad || '').toLowerCase()}/></td>
            <td className="capitalize-all-words">
              <b>{(this.state.tipo_asent || '').toLowerCase()}:</b></td>
            <td className="capitalize-all-words">
              <Editable placeholder={'sin dato'} forcePlaceholder={true}
                        editBranch={(val: any) => this.props.editBranch("nomb_asent", val)}
                        field="nomb_asent" text={(this.state.nomb_asent || '').toLowerCase()}/></td>
          </tr>
          <tr>
            <td className="capitalize-all-words">
              <b>{(this.state.tipo_vial || '').toLowerCase()}</b></td>
            <td className="capitalize-all-words">
              <Editable placeholder={'sin dato'} forcePlaceholder={true}
                        editBranch={(val: any) => this.props.editBranch("nom_vial", val)}
                        field="nom_vial" text={(this.state.nom_vial || '').toLowerCase()}/></td>
            <td><b>Número ext:</b></td>
            <td><Editable placeholder={'sin num ext.'} forcePlaceholder={true}
                        editBranch={(val: any) => this.props.editBranch("numero_ext", val)}
                        field="numero_ext" text={this.state.numero_ext}/></td>
          </tr>
          <tr>
            <td><b>Edificio:</b></td>
            <td className="capitalize-all-words">
              <Editable placeholder={'sin edificio'} forcePlaceholder={true}
                        editBranch={(val: any) => this.props.editBranch("edificio", val)}
                        field="edificio" text={(this.state.edificio || '').toLowerCase()}/></td>
            <td><b>Número int:</b></td>
            <td><Editable placeholder={'sin num int.'} forcePlaceholder={true}
                        editBranch={(val: any) => this.props.editBranch("numero_int", val)}
                        field="numero_int" text={this.state.numero_int}/></td>
          </tr>
          <tr>
            <td><b>Letra ext:</b></td>
            <td><Editable placeholder={'sin letra ext.'} forcePlaceholder={true}
                        editBranch={(val: any) => this.props.editBranch("letra_ext", val)}
                        field="letra_ext" text={this.state.letra_ext}/></td>
            <td><b>Letra int:</b></td>
            <td><Editable placeholder={'sin letra int.'} forcePlaceholder={true}
                        editBranch={(val: any) => this.props.editBranch("letra_int", val)}
                        field="letra_int" text={this.state.letra_int}/></td>
          </tr>
          <tr>
            <td><b>No. Local:</b></td>
            <td><Editable placeholder={'sin num de local'} forcePlaceholder={true}
                        editBranch={(val: any) => this.props.editBranch("num_local", val)}
                        field="num_local" text={this.state.num_local}/></td>
            <td><b>Manzana:</b></td>
            <td><Editable placeholder={'sin teléfono'} forcePlaceholder={true}
                        editBranch={(val: any) => this.props.editBranch("manzana", val)}
                        field="manzana" text={this.state.manzana}/></td>
          </tr>
        </tbody>
      </Table>
      <div className='pu-branch-footer'>
        <div>
          <EditableSelect
            field="employees"
            forcePlaceholder={true}
            editBranch={(val: any) => this.props.editBranch("employees", val)}
            selectProps={{
              placeholder: 'No. Empleados',
              options: selectOptions.numEmpleados
            }}
            text={this.state.employees}/>
        </div>
      </div>
    </div>);
  }
}