import * as React from 'react';

// Local modules
import ActivityDetailsTable from '../CustomComponents/ActivityDetailsTable';
import ActivityFormContainer from '../CustomComponents/ActivityFormContainer';
import CheckboxesContainer from '../CustomComponents/CheckboxesContainer';
import {Editable, EditableCreatable, EditableSelect} from '../CustomComponents/CustomEditable';
import {telFormat, telValue} from '../data/otros';
import * as selectOptions from '../data/selectOptions';
import {getMainOrDefault} from '../utils/commonFuncs';
import {CampaignContext} from '../utils/contexts';

export default class PopupFormSinAsignar extends React.Component<any, any>{
  constructor(props: any){
    super(props);
    this.state = {
      infoActualizada: false,
      listaCampañas: [],
      error: false,
    }
    this.editOrCreateContact = this.editOrCreateContact.bind(this)
    this.softwareNewOption = this.softwareNewOption.bind(this)
    this.onActivityCreate = this.onActivityCreate.bind(this)
  }

  componentDidMount(){
    Promise.all([
      this.props.auth.getUsers(),
      this.props.auth.getSoftware(),
      this.props.auth.getContacts(this.props.leadId),
      this.props.auth.getCampaigns()])
    .then(responses => {
      const listaModulos = selectOptions.listaModulos;
      const listaCampañas = responses[3].data.objects
      const modules = this.props.modules ? this.props.modules : [];
      this.props.auth.editLead(this.props.leadId, 'campaigns', listaCampañas.map((c: any) => c.id))
      .then(() => {
        this.setState({listaCampañas: listaCampañas.map((c: any) => Object.assign({}, {value: c.id, label: c.name, isChecked: true}))})
      })
      this.setState({
        usuarios: responses[0].data.objects,
        listaSoftware: responses[1].data.objects,
        contacts: responses[2].data.objects,
        listaModulos: listaModulos.map(m => Object.assign({}, m, {isChecked: modules.includes(m.value)}))
      });
    });
  }

  softwareNewOption(option: any){
    this.props.auth.createSoftware(option.name).then(()=>{
      const ops = this.state.listaSoftware;
      ops.unshift({id: null, name: option.name})
      this.setState({listaSoftware: ops})
    })
  }

  onActivityCreate(){
    this.props.deleteRow(this.props.rowId);
    this.props.changePopup();
  }

  editOrCreateContact(value: any){
    // Edit if contact eixted, else create new Contact
    const contact = getMainOrDefault(this.props.main_contact, this.state.contacts)
    if (contact){ return this.props.auth.editContact(contact.id, {name: value}) }
    else{ return this.props.auth.createContact({name: value, lead_id: this.props.leadId}) }
  }

  render(){
    const leadName = <a target="_blank" href={`https://www.google.com.mx/search?q=${this.props.leadName.replace(/ /g, "+")}`}>{this.props.leadName}</a>
    const direccion = `${this.props.nomb_asent ? this.props.nomb_asent.toLowerCase() + ', ' : ''}
      ${this.props.municipio ? this.props.municipio.toLowerCase() + ', ' : ''}
      ${this.props.entidad || ''}`;

    const phone = <div className='phone-big'><div>Tel.:&nbsp;</div><div><Editable
                field="phone" placeholder='sin teléfono' forcePlaceholder={true}
                onSave={telValue} formatter={telFormat} text={this.props.phone}
                editBranch={(val: any) => this.props.auth.editBranch(this.props.id, "phone", val)}/>
                </div></div>

    const empleados = <EditableSelect
                field="employees" forcePlaceholder={true}
                text={this.props.employees}
                selectProps={{placeholder: 'No. Empleados', options: selectOptions.numEmpleados}}
                editBranch={(val: any) => this.props.auth.editBranch(this.props.id, "employees", val)}/>

    const website = <Editable field="website"
                placeholder='sin website' forcePlaceholder={true}
                text={this.props.website}
                editBranch={(val: any) => this.props.auth.editBranch(this.props.id, "website", val)}/>

    const email = <Editable field="email"
                placeholder='sin e-mail' forcePlaceholder={true}
                text={this.props.email}
                editBranch={(val: any) => this.props.auth.editBranch(this.props.id, "email", val)}/>

    const baseContact = <Editable field="Contacto Base"
                placeholder='sin contacto base' forcePlaceholder={true}
                text={(getMainOrDefault(this.props.main_contact, this.state.contacts) || {}).name}
                editBranch={this.editOrCreateContact}/>

    const software = <EditableCreatable field="software"
                text={this.props.software}
                forcePlaceholder={true}
                selectProps={{
                  labelKey: 'name', valueKey: 'name',
                  placeholder: 'sin software', options: this.state.listaSoftware,
                  onNewOptionClick: this.softwareNewOption,
                }}
                editBranch={(val: any) => this.props.auth.editLead(this.props.leadId, "software", val)}/>

    const modulos = <CheckboxesContainer field="modules"
                options={this.state.listaModulos} leadId={this.props.leadId}
                editLead={(id: number, attr: string, val: any) => this.props.auth.editLead(id,attr,val)}/>

    const sucursalName = <span className="capitalize-all-words">{this.props.name}</span>

    return <CampaignContext.Consumer>
      {campaign => <div id="popup-form-actividades-wrapper">
        <div className="left-side with-margin">
          <ActivityDetailsTable title={leadName} subtitle={phone}
            rows={[
              {label: 'Nombre:', value: sucursalName},
              {label: 'Origen', value: this.props.branch_id || this.props.leadId},
              {label: 'Contacto', value: baseContact},
              {label: 'Empleados:', value: empleados},
              {label: 'Software:', value: software},
              {label: 'Tipo actividad:', value: this.props.activity_name ? this.props.activity_name : '-'},
              {label: 'Sucursales:', value: this.props.numSucursales},
              {label: 'Dirección:', value: direccion},
              {label: 'Website', value: website},
              {label: 'Email:', value: email},
              {label: 'Módulos interés:', value: modulos},
            ]} />
        </div>
        <div className="right-side with-margin">
          <ActivityFormContainer
            auth={this.props.auth} leadId={this.props.leadId}
            campaignId={campaign}
            encargado={this.props.auth.getUserId()} tipo="Llamada"
            prox_act="Llamada" usuarios={this.state.usuarios}
            listaSoftware={this.state.listaSoftware}
            contacts={this.state.contacts}
            onActivityCreate={this.onActivityCreate} />
        </div>
      </div>
    }
    </CampaignContext.Consumer>
  }
}