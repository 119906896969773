import * as React from 'react';
import * as ReactDataGrid from 'react-data-grid';
import Alert from 'react-s-alert';
import { Toolbar, Data } from 'react-data-grid-addons'

// Local modules
import { colsUnassigned } from '../data/columns';
import { RSLink, Ellipsis, WebSite, Phone, Score, LocalizacionFormatter } from '../CustomComponents/GridComponents';
import GridNuevosButton from "../CreateActivityButtons/GridNuevosButton";
import ListoAuthService from '../utils/ListoAuthService';

const { Selectors } = Data

interface IState{
  columns: ReactDataGrid.Column[],
  divheight: number,
  rows: any[],
  finishedLoading: boolean,
  filters: object
}

interface IProps{
  auth: any,
  edit: boolean,
  campaignId: number,
}

class GridNuevos extends React.Component<IProps, IState>{
  static defaultProps = {
    auth: new ListoAuthService()
  }

  constructor(props: any){
    super(props);
    this.state = {
      columns: [],
      divheight: 950,
      rows: [],
      finishedLoading: false,
      filters: {}
    };
    this.getRows = this.getRows.bind(this);
    this.getSize = this.getSize.bind(this);
    this.rowGetter = this.rowGetter.bind(this);
    this.getLeadsFromRequest = this.getLeadsFromRequest.bind(this);
    this.handleGridSort = this.handleGridSort.bind(this)
    this.handleRowUpdated = this.handleRowUpdated.bind(this)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.onClearFilters = this.onClearFilters.bind(this)
    this.rowGetter = this.rowGetter.bind(this)
  }

  componentWillReceiveProps(np: IProps){
    if(np.campaignId !== this.props.campaignId){
      this.setState({rows: [], finishedLoading: false}, () => this.getLeadsFromRequest(np.campaignId))
    }
  }

  componentDidMount(){
    const columns = colsUnassigned;

    columns.find(d => d.key === 'website')!.formatter =  <WebSite />;
    columns.find(d => d.key === 'phone')!.formatter =  <Phone />;
    columns.find(d => d.key === 'score')!.formatter =  <Score />;
    columns.find(d => d.key === 'localizacion')!.formatter =  <LocalizacionFormatter />;
    columns.find(d => d.key === 'name')!.formatter =  <RSLink rowGetter={(x: any) => this.rowGetter(x)}/>;
    columns.find(d => d.key === 'llamar')!.formatter =  <GridNuevosButton
      edit={this.props.edit}
      auth={this.props.auth}
      rowGetter={(x: any) => this.rowGetter(x)}
      deleteRow={(i: any)=>{
        const rows = this.getRows();
        console.log('Deleting, ', i)
        rows.splice(i,1)
        this.setState({ rows })
      }}/>;

    this.setState({columns})

    this.getLeadsFromRequest();

    window.addEventListener("resize", () => {
      this.setState({divheight: window.innerHeight});
    });
  }

  getLeadsFromRequest(campaign: any = null){
    this.props.auth.getLeadsWithoutActivities(campaign || this.props.campaignId).then((res: any)=> {
      const rows: any[] = [];
      const leads = res.data.results;
      leads.forEach((obj: any, i: number) => {
        const ib = obj.important_branch;
        rows.push(Object.assign({}, obj, ib, {
          llamar: i,
          leadId: obj.id,
          name: obj.name,
          score: obj.score || '',
          localizacion: (`${(ib.nomb_asent || '').toLowerCase()}, `) + (ib.municipio || ''),
          sucursales: obj.total_branches,
        }));
      });
      this.setState({ rows, finishedLoading: true});
    })
  }

  getRows() {
    return Selectors.getRows(this.state);
  }

  getSize() {
    return this.getRows().length;
  }

  rowGetter(i: any){
    const rows = this.getRows();
    return rows[i];
  }

  handleGridSort(sortColumn: any, sortDirection: any) {
    const state = Object.assign({}, this.state, { sortColumn, sortDirection});
    this.setState(state);
  }

  handleRowUpdated(e: any){
    const rows = this.state.rows;
    Object.assign(rows[e.rowIdx], e.updated);
    this.setState({ rows });
  }

  handleFilterChange(filter: any){
    const newFilters = Object.assign({}, this.state.filters);
    if (filter.filterTerm) {
      newFilters[filter.column.key] = filter;
    } else {
     delete newFilters[filter.column.key];
    }
    this.setState({filters: newFilters});
  }

  onClearFilters(){
    this.setState({filters: {} });
  }

  render(){
    if (this.getSize() === 0){
      return <div className="no-columns-message">{this.state.finishedLoading ? 'No se tiene información' : <Ellipsis />}</div>
    }
    return (
      <div>
        <ReactDataGrid
          enableCellSelect={true}
          onGridSort={this.handleGridSort}
          onRowUpdated={this.handleRowUpdated}
          onAddFilter={this.handleFilterChange}
          onClearFilters={this.onClearFilters}
          toolbar={<Toolbar enableFilter={true} />}
          columns={this.state.columns}
          rowGetter={this.rowGetter}
          rowsCount={this.getSize()}
          minHeight={this.state.divheight-43-48-20} />;
        <Alert stack={{limit: 3}} html={true} />
      </div>);
  }
}

export default GridNuevos;