import * as React from "react";
import * as Glyphicon from "react-bootstrap/lib/Glyphicon";
import Popup from "../BasePopUp/Popup";
import PopupFormActividades from "../CustomPopUps/PopupFormActividades";

export default class EditButton extends React.Component<any, any>{
    constructor(props: any){
      super(props);
      this.state={activityCreated: false}
      this.handleClick = this.handleClick.bind(this);
      this.updateRow = this.updateRow.bind(this);
    }
    updateRow(leadId: number, newData: any){
      this.props.updateRow(leadId, newData);
      this.setState({activityCreated: true});
      Popup.close();
    }

    handleClick(){
      const row = this.props.rowGetter(this.props.rowIdx);
      this.props.auth.getPopupActividades(row.leadId).then((res: any) => {
        const {users, softwares, contacts, campaigns, lead, branch} = res.data;
        Popup.show({
          title: 'Introduce la información de la llamada',
          confirmCondition: () => this.state.activityCreated,
          content: <PopupFormActividades
            auth={this.props.auth}
            updateRow={this.updateRow}
            usuarios={users}
            contacts={contacts}
            listaSoftware={softwares}
            listaCampañas={campaigns}
            leadName={lead.name}  // Para no confundir con branch.name
            {...row}  // Datos de la ultima actividad
            {...lead}  // Datos del lead
            {...branch}  // Datos del branch
            />
          })
      })
    }

    render(){
      return (<div>
          <span
            onClick={this.props.edit===false ? () => undefined : this.handleClick.bind(this)}
            className={`edit-span ${this.props.edit===false ? 'disabled-edit' : ''}`}>
            <Glyphicon glyph="plus"/>
            <span> Crear actividad</span>
          </span>
        </div>);
    }
  }
