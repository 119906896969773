import * as React from 'react';
import { Redirect } from 'react-router';

// Local modules
import * as selectOptions from '../data/selectOptions';
import ListoAuthService from '../utils/ListoAuthService';
import ActivityInformation from './ActivityInformation';
import BranchInformation from './BranchInformation';
import ContactInformation from './ContactInformation';
import DetailsInformation from './DetailsInformation';
import Popup from '../BasePopUp/Popup';

export default class DetailsPage extends React.Component<any, any>{
  static defaultProps = {
    auth: new ListoAuthService()
  }

  constructor(props: any){
    super(props);
    this.state = {activities: [], branches: []}
  }

  componentWillMount(){
    if(!this.props.auth.loggedIn()){
      this.setState({redirect: true})
    }
  }

  componentDidMount(){
    this.props.auth.getLeadDetails(this.props.location.pathname.replace('/', ''))
      .then((res: any) => {
        const activitiesList = res.data.activities.map((obj: any) => obj.id),
          modules = res.data.modules || [],
          campaigns = res.data.campaigns || [];
        Promise.all([
          this.props.auth.getActivities(activitiesList),
          this.props.auth.getUsers(),
          this.props.auth.getSoftware(),
          this.props.auth.getCampaigns()]).then(responses =>{
            const users = responses[1].data.objects.filter((obj: any) => obj.active)
            this.setState(Object.assign({}, res.data, {
              users,
              activities: responses[0],
              listaSoftware: responses[2].data.objects.map((obj: any) => ({label: obj.name, value: obj.name}) ),
              listaModulos: selectOptions.listaModulos.map((m: any) => Object.assign({}, m, {isChecked: modules.includes(m.value)})),
              listaCampañas: responses[3].data.objects.map((c: any) => Object.assign({}, {value: c.id, label: c.name, isChecked: campaigns.includes(c.id)})),
            }));
          })
      });
  }

  setMainBranch(id: number){
    this.setState({ main_branch: id })
  }

  checkLead(lead: any): boolean {
    if (new Date(lead.prox_act_prop).getFullYear() === 2099) {
      return false
    }
    return true
  }

  async deleteLastActivity(activity: any) {
    if (confirm("¿Deseas restablecer el lead?")) {
      const res = await this.props.auth.deleteActivity(activity.id)

      if (res.data.status_code !== 200) {
        alert(res.data.message || 'Ocurrió un error, no fue posible procesar la solicitud')
      } else {
        this.setState((state: any) => ({
          ...state,
          activities: state.activities.filter((a: any) => a.id !== activity.id)
        }))
  
        alert("Realizado exitosamente")
      } 
    }
  }

  render(){
    if(this.state.redirect){
      return <Redirect to="/login" push={true} />
    }

    let actividades;
    actividades = this.state.activities.map((obj: any, i: number) => {return <ActivityInformation
        key={i} i={i + 1} users={this.state.users}
        contacts={this.state.contacts} {...obj} />
    })

    const userPermissions = this.props.auth.getUserPermissions() || {};

    return (<div id="detailsPage">
      <div id="details-header">
        <div id="details-header-left">
          <h2 id='details-lead-name' className="capitalize-all-words">
            <a
              target="_blank"
              className="details-lead-name-a"
              href={`https://www.google.com.mx/search?q=${this.state.name ? this.state.name.replace(/ /g, "+") : ''}`}>
              {this.state.name ? this.state.name : ''}
            </a>
          </h2>
        </div>
      </div>
      <div className="detailspage">
        <div className='dp-left-div'>
          <div className="details-div">
            <h3>Sucursales:</h3>
            <div>
              <BranchInformation
                auth={this.props.auth}
                branches={this.state.branches}
                leadId={this.state.id}
                mainBranch={this.state.main_branch} />
            </div>
          </div>
          <div style={{margin:50}}>
            <h3>Personas de contacto:</h3>
            <div>
              <ContactInformation
                auth={this.props.auth}
                contacts={this.state.contacts}
                leadId={this.state.id}
                mainContact={this.state.main_contact}/>
            </div>
          </div>
        </div>
        <div className='dp-right-div'>
          <div className="details-div">
            <h3 style={{marginTop: 0}}>Detalles:</h3>
            <DetailsInformation auth={this.props.auth} {...this.state}/>
          </div>
          <div className="details-div">
            <h3 style={{marginTop: 0}}>Actividades:</h3>
            {this.state.activities.length > 0 ? actividades : <div>Sin actividades</div>}
            {this.state.activities.length > 0 && userPermissions.console && (
              <button
                className="btn"
                children="Restablecer"
                style={{ marginTop: 5 }}
                disabled={this.checkLead(this.state.activities.slice(-1)[0])}
                onClick={() => this.deleteLastActivity(this.state.activities.slice(-1)[0])}
              />
            )}
          </div>
        </div>
      </div>
      <div style={{clear:'both'}}>&nbsp;</div>
      <Popup />
    </div>);
  }
}