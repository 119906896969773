import axios from 'axios';
import { EventEmitter } from 'events';
import * as moment from 'moment';
import { baseUrl } from '../data/otros';

/********************
De esta clase se obtiene toda la información del servidor
********************/
export default class ListoAuthService extends EventEmitter{
  constructor(){
    super();
    this.on('login', () => { window.location.href = "/" });
    this.on('loginFail', () => { alert('Error de usuario y contraseña') });
    // this.on('logout', () => { location.reload(true) });
    // if(!localStorage.last_login || moment().diff(moment(localStorage.last_login, 'YYYY-MM-DD hh:mm'), 'hours') > 23){
    //   if(this.getemail() && this.getpassword()) this.login(this.getemail(), this.getpassword())
    // }
  }
  changeCredentials(email: string, password: string){
    localStorage.setItem('authToken', btoa(email + ':' + password));
    localStorage.setItem('email', email)
  }
  getemail(){ return localStorage.getItem('email'); }
  getBasicToken(){ return localStorage.getItem('authToken'); }
  getBaseCampaign(){ return JSON.parse(localStorage.getItem('profile')!).base_campaign; }
  getOrganizationId(){ return JSON.parse(localStorage.getItem('profile')!).organization_id; }
  getUserId(){ return JSON.parse(localStorage.getItem('profile')!).id; }
  getUserColors(){ return JSON.parse(localStorage.getItem('profile')!).colors; }
  getUserName(){ return JSON.parse(localStorage.getItem('profile')!).name; }
  getUserPermissions(){ return (JSON.parse(localStorage.getItem('profile')!) || {}).permissions; }
  getUsersList(){return (JSON.parse(localStorage.getItem('profile')!) || {}).users; }
  login(email: string, password: string){
    this.changeCredentials(email, password);
    axios({
      headers: {Authorization: `Basic ${this.getBasicToken()}`},
      method: 'get',
      url: baseUrl + '/auth',
    })
      .then(res => {
        localStorage.setItem('loggedIn', "1");
        localStorage.setItem('last_login', moment().format('YYYY-MM-DD hh:mm').toString());
        localStorage.setItem('profile', JSON.stringify(res.data));
        this.emit('login')})
      .catch(err => {this.emit('loginFail')});
  }
  loggedIn(){
    return localStorage.getItem('loggedIn');
  }
  logout(){
    localStorage.removeItem('loggedIn');
    localStorage.removeItem('email');
    localStorage.removeItem('password');
    localStorage.removeItem('profile');
    location.reload(true);
  }

  /**
   * Endpoint dedicado para obtener información del usuario
   *
   */
  getUserProfile(){
    axios({
      headers: {Authorization: `Basic ${this.getBasicToken()}`},
      method: 'get',
      url: baseUrl + `/profile_info`,
    }).then(res => {
      localStorage.setItem('profile', JSON.stringify(res.data));
    })
  }

  /**
   * Endpoint dedicado para carga eficiente de Leads nuevos
   * ordenados por score descendente
   *
   * @param {integer} offset default 0
   * @param {integer} limit default 200
   */
  getLeadsWithoutActivities(campaign: number, offset=0, limit=200){
    return axios({
      headers: {Authorization: `Basic ${this.getBasicToken()}`},
      method: 'get',
      url: baseUrl + `/api/leads_nuevos`,
      params: {
        limit,
        offset,
        campaign,
        user_email: this.getemail(),
      }
    })
  }


  /**
   * Leads sin actividades y con Score mayor a 0
   */
  getAssignedScored(user=null){
    return axios({
      headers: {Authorization: `Basic ${this.getBasicToken()}`},
      method: 'get',
      url: baseUrl + `/api/clientes?q={"filters":[
        {"name":"user__email","op":"any","val":"${user ? user : this.getemail()}"},
        {"name":"activities","op":"is_null"},{"name":"score","op":">","val":0}
      ]}`,
    });
  }

  /**
   * Leads sin actividades ni usuario asignado
   */
  getUnAssigned(){
    return axios({
      headers: {Authorization: `Basic ${this.getBasicToken()}`},
      method: 'get',
      url: baseUrl + `/api/clientes?q={"filters":[
        {"name":"user__email","op":"any","val":""},
        {"name":"activities","op":"is_null"}
      ]}`,
    });
  }

  /**
   * Todos los usuarios dados de alta en el sistema
   */
  getUsers(){
    return new Promise((resolve, reject) => {
      axios({
        headers: {Authorization: `Basic ${this.getBasicToken()}`},
        method: 'get',
        url: baseUrl + '/api/usuarios',
      }).then(res=>{
        const result = {data: {objects: res.data.objects.filter((u: any) => u.active)}}
        resolve(result);
      }).catch(err=>reject(err))
    });
  }

  /**
   * Todos los software registrados en el sistema
   */
  getSoftware(){
    return axios({
      headers: {Authorization: `Basic ${this.getBasicToken()}`},
      method: 'get',
      url: baseUrl + '/api/software',
    });
  }

  /**
   * Crear un nuevo software en la DB
   *
   * @param {string} name
   *  Nombre del software
   */
  createSoftware(name: string){
    return axios({
      data:{ name },
      headers: {Authorization: `Basic ${this.getBasicToken()}`},
      method: 'post',
      url: baseUrl + '/api/software/create',
    });
  }

  /**
   * Contactos que pertenecen a un Lead
   *
   * @param {integer} leadId
   *  Id en la DB del lead
   */
  getContacts(leadId: number){
    return axios({
      headers: {Authorization: `Basic ${this.getBasicToken()}`},
      method: 'get',
      url: baseUrl + `/api/contactos?q={"filters":[{"name":"lead_id","op":"eq","val":${leadId}}]}`,
    });
  }

  /**
   * Leads de una lista. Se puede hacer más eficiente y evitar el doble request para la primer page
   *
   * @param {array} leadsList
   *  Lista con ids de leads en la DB
   */
  getLeads(leadsList: any[]){
    const request = (list: any[]) => axios({
      headers: {Authorization: `Basic ${this.getBasicToken()}`},
      method: 'get',
      url: baseUrl + `/api/clientes?q={"filters":[{"name":"id","op":"in","val":[${list}]}]}`,
    });
    let offset = 0;
    const size = 25;
    const requests: any[] = []
    const objects: any[] = [];
    const nextLeadsPromise = new Promise((resolve, reject) => {
      for(let i=0;i<=leadsList.length/size;i++){
        requests.push(request(leadsList.slice(offset, offset + size)));
        offset += size;
      }
      Promise.all(requests).then(resps=>{
        resps.forEach(obj=>{obj.data.objects.forEach((act: any) => {objects.push(act)})});
        resolve(objects);
      })
    });
    return nextLeadsPromise;
  }

  /**
   * Leads existentes en la DB separados por página
   *
   * @param {integer} page
   *  Página para el API de Listo
   */
  getAllLeads(page: number){
    return axios({
      headers: {Authorization: `Basic ${this.getBasicToken()}`},
      method: 'get',
      url: baseUrl + `/api/clientes?q={}&page=${page}`,
    })
  }

  /**
   * Máximo 10 leads con nombre parecido
   *
   * @param {string} name
   *  Nombre del lead
   */
  searchLeads(name: string){
    return axios({
      headers: {Authorization: `Basic ${this.getBasicToken()}`},
      method: 'get',
      url: baseUrl + `/api/clientes?q={"filters":[{"name":"name","op":"ilike","val":"%${name}%"}]}&size=10`,
    })
  }

  /**
   * Todos los Leads que tienen actividades
   * ordenados por fecha de actividad descendente
   *
   * @param {integer} page
   */
  getLeadsWithActivities(page: number){
    return axios({
      headers: {Authorization: `Basic ${this.getBasicToken()}`},
      method: 'get',
      url: baseUrl + `/api/clientes?q={"filters":[
        {"name":"activities","op":"is_not_null"},
        {"name":"user__email", "op":"any", "val":"${this.getemail()}"}],
        "order_by":[{"field":"activities__timestamp","direction":"desc"}]}
        &page=${page}`,
    });
  }

  /**
   * Todos los Leads que ya no tendrán más actividades
   * ordenados por fecha de actividad descendente
   *
   * @param {integer} page
   */
  getFinishedLeads(page: number){
    return axios({
      headers: {Authorization: `Basic ${this.getBasicToken()}`},
      method: 'get',
      url: baseUrl + `/api/clientes?q={"filters":[
        {"name":"activities__prox_act_prop","op":"any","val":"2099-01-01"},
        {"name":"user__email", "op":"any", "val":"${this.getemail()}"}],
        "order_by":[{"field":"activities__timestamp","direction":"desc"}]}
        &page=${page}`,
    });
  }

  /**
   * Endpoint dedicado para carga de actividades eficiente
   * ordenadas por fecha descendente
   *
   * @param {integer} offset default 0
   * @param {integer} limit default 100
   */
  getLeadsActividades(campaign: string, offset=0, limit=100){
    return axios({
      headers: {Authorization: `Basic ${this.getBasicToken()}`},
      method: 'get',
      url: baseUrl + '/api/leads_actividades',
      params: {limit, offset, campaign}
    })
  }

  /**
   * Endpoint dedicado para carga de citas eficiente
   * ordenadas por fecha descendente
   *
   * @param {integer} offset default 0
   * @param {integer} limit default 100
   */
  getLeadsCitas(offset=0, limit=100){
    return axios({
      headers: {Authorization: `Basic ${this.getBasicToken()}`},
      method: 'get',
      url: baseUrl + `/api/leads_citas?user_email=${this.getemail()}&limit=${limit}&offset=${offset}`,
    })
  }

  /**
   * Actividades de un usuario ordenadas descendente
   * ignorando las propuestas para el 2099
   */
  getProxActivities(page: number){
    return axios({
      headers: {Authorization: `Basic ${this.getBasicToken()}`},
      method: 'get',
      url: baseUrl + `/api/actividades?q={"filters":[
        {"name":"prox_act_prop","op":"lt","val":"2099-01-01"},
        {"name":"user_id", "op":"eq", "val":${this.getUserId()}}],
        "order_by":[
          {"field":"timestamp","direction":"desc"},
          {"field":"prox_act_prop","direction":"desc"}
        ]}
        &page=${page}`,
    });
  }

  /**
   * Todas las actividades
   */
  getNextActivities(){
    const actRequest = (page: number) => axios({
      headers: {Authorization: `Basic ${this.getBasicToken()}`},
      method: 'get',
      url: baseUrl + `/api/actividades?q={"filters":[{"name":"user_id","op":"eq","val":${this.getUserId()}}]}&page=${page}`,
    });
    const myFirstPromise = new Promise((resolve, reject) => {
      actRequest(1).then(res=>{
        const pages = res.data.total_pages;
        const requests: any[] = [];
        const objects: any[] = [];
        for(let p = 1; p <= pages; p++){ requests.push(actRequest(p)) }
        Promise.all(requests).then(resps=>{
          resps.forEach(obj => { obj.data.objects.forEach((act: any) => { objects.push(act) }) })
          resolve(objects)
        })
      })
    });
    return myFirstPromise;
  }

  /**
   * Actividades en una lista
   *
   * @param {array} activitiesList
   *  Lista con ids de actividades
   */
  getActivities(activitiesList: any[]){
    const request = (list: any[]) => axios({
      headers: {Authorization: `Basic ${this.getBasicToken()}`},
      method: 'get',
      url: baseUrl + `/api/actividades?q={"filters":[{"name":"id","op":"in","val":[${list}]}]}`,
    });
    const size = 25;
    const requests: any[] = [];
    const objects: any[] = [];
    let offset = 0;
    const nextActivitiesPromise = new Promise((resolve, reject) => {
      for(let i=0;i<=activitiesList.length/size;i++){
        requests.push(request(activitiesList.slice(offset, offset + size)));
        offset += size;
      }
      Promise.all(requests).then(resps=>{
        resps.forEach(obj=>{obj.data.objects.forEach((act: any)=>{ objects.push(act) })});
        resolve(objects);
      })
    });
    return nextActivitiesPromise;
  }

  /**
   * No regresa actividades como tal, sino todos Leads que tengan actividades.
   * Posteriormente se debe utilizar getActivities(activitiesList) para obtener las actividades
   */
  getAllActivities(offset: number, limit: number, userId: number){
    return axios({
      headers: {Authorization: `Basic ${this.getBasicToken()}`},
      method: 'get',
      url: baseUrl + `/api/all_activities`,
      params: {limit, offset, userId}
    })
  }

  /**
   * Todas las actividades que no sean del tipo Llamada, o cuya próxima actividad no sea Llamada
   */
  getCitas(){
    const request = (page: number) => axios({
      headers: {Authorization: `Basic ${this.getBasicToken()}`},
      method: 'get',
      url: baseUrl + `/api/actividades?q={"filters":[{"or":[{"name":"tipo","op":"not_equal_to","val":"Llamada"},{"name":"prox_act","op":"not_equal_to","val":"Llamada"}]}]}&page=${page}`,
    });
    const nextActivitiesPromise = new Promise((resolve, reject) => {
      request(1).then(res=>{
        const pages = res.data.total_pages;
        const requests = [];
        const objects: any[] = [];
        for(let p = 1; p <= pages; p++){ requests.push(request(p)) }
        Promise.all(requests).then(resps=>{
          resps.forEach(obj=> { obj.data.objects.forEach((act: any) => { objects.push(act) }) })
          resolve(objects)
        })
      })
    });
    return nextActivitiesPromise;
  }

  /**
   * Toda la información de un Lead
   *
   * @param {integr} id
   *  Id del lead
   */
  getLeadDetails(id: number){
    return axios({
      headers: {Authorization: `Basic ${this.getBasicToken()}`},
      method: 'get',
      url: baseUrl + `/api/clientes/${id}`,
    });
  }

  /**
   * Toda la información de un Branch
   *
   * @param {integr} id
   *  Id del branch
   */
  getBranchDetails(id: number){
    return axios({
      headers: {Authorization: `Basic ${this.getBasicToken()}`},
      method: 'get',
      url: baseUrl + `/api/sucursales/${id}`,
    });
  }

  /**
   * Crear una nueva Actividad
   *
   * @param {object} config
   *  key: value de acuerdo al modelo Activity en la DB
   */
  postActivity(config: any){
    if(this.getUserPermissions().create_activities === false){
      return true
    }
    return axios({
      data: config,
      headers: {Authorization: `Basic ${this.getBasicToken()}`},
      method: 'post',
      url: baseUrl + '/api/activity/create',
    });
  }

  /**
   * Asignar Lead a otro usuario
   *
   * @param {object} config
   *  Se necesita leadId y email
   */
  assignAnotherUser(config: any){
    return axios({
      data: config,
      headers: {Authorization: `Basic ${this.getBasicToken()}`},
      method: 'post',
      url: baseUrl + '/api/user/assign',
    });
  }

  /**
   * Editar un atributo del Lead
   *
   * @param {integer} iid
   *  Id del lead a editar
   * @param {string} attr
   *  Nombre del campo a editar
   * @param {T} val
   *  Nuevo valor del campo
   */
  editLead(id: number, attr: string, val: any){
    return axios({
      data: {id, attr, val},
      headers: {Authorization: `Basic ${this.getBasicToken()}`},
      method: 'post',
      url: baseUrl + '/api/lead/edit',
    });
  }

  /**
   * Crear un nuevo Lead, regresa id
   *
   * @param {integer} iid
   *  Id del lead a editar
   * @param {string} attr
   *  Nombre del campo a editar
   * @param {T} val
   *  Nuevo valor del campo
   */
  createLead(config: any){
    return axios({
      data: config,
      headers: {Authorization: `Basic ${this.getBasicToken()}`},
      method: 'post',
      url: baseUrl + '/api/lead/create',
    });
  }

  /**
   * Crear un nuevo Branch, regresa id
   *
   * @param {config} id
   *  Todos los parametros que pertenezcan al Branch model
   */
  createBranch(config: any){
    return axios({
      data: config,
      headers: {Authorization: `Basic ${this.getBasicToken()}`},
      method: 'post',
      url: baseUrl + '/api/branch/create',
    });
  }

  /**
   * Editar un atributo de Branch
   *
   * @param {integer} id
   *  Id del branch a editar
   * @param {string} attr
   *  Nombre del campo a editar
   * @param {T} val
   *  Nuevo valor del campo
   */
  editBranch(id: number, attr: string, val: any){
    return axios({
      data: {id, attr, val},
      headers: {Authorization: `Basic ${this.getBasicToken()}`},
      method: 'post',
      url: baseUrl + '/api/branch/edit',
    });
  }

  /**
   * Ascender un Branch a nivel Lead
   *
   * @param {integer} id
   *  Id del branch a ascender
   */
  transformBranch(id: number){
    return axios({
      data: {id},
      headers: {Authorization: `Basic ${this.getBasicToken()}`},
      method: 'post',
      url: baseUrl + '/api/branch/transform',
    });
  }

  /**
   * Editar un contacto
   *
   * @param {integer} id
   *  Id del lead a editar
   * @param {object} data
   *  Se necesita name, role, email y phone
   */
  editContact(id: number, data: any){
    return axios({
      data: {id, data},
      headers: {Authorization: `Basic ${this.getBasicToken()}`},
      method: 'post',
      url: baseUrl + '/api/contact/edit',
    });
  }

  /**
   * Editar un atributo del Lead
   *
   * @param {object} config
   *  Se necesita name, role, email, phone y leadId
   */
  createContact(config: any){
    return axios({
      data: config,
      headers: {Authorization: `Basic ${this.getBasicToken()}`},
      method: 'post',
      url: baseUrl + '/api/contact/create',
    });
  }

  /**
   * Editar un atributo del Usuario
   *
   * @param {integer} iid
   *  Id del usuario a editar
   * @param {string} attr
   *  Nombre del campo a editar
   * @param {T} val
   *  Nuevo valor del campo
   */
  editUser(id: number, attr: string, val: any){
    return axios({
      data: {id, attr, val},
      headers: {Authorization: `Basic ${this.getBasicToken()}`},
      method: 'post',
      url: baseUrl + '/api/user/edit',
    });
  }

  /**
   * Todas las campañas de la base de datos
   */
  getCampaigns(){
    return axios({
      headers: {Authorization: `Basic ${this.getBasicToken()}`},
      method: 'get',
      url: baseUrl + '/api/campanhas',
    })
  }

  /**
   * Información necesaria para el popup de GridActividades
   */
  getPopupActividades(leadId: number){
    return axios({
      headers: {Authorization: `Basic ${this.getBasicToken()}`},
      method: 'get',
      url: baseUrl + `/api/popup_actividades/${leadId}`,
    })
  }

  /**
   * Mandar a pipedrive
   */
  addToPipedrive(leadId: number, campaignId: number){
    return axios({
      data: {lead_id: leadId, campaign_id: campaignId},
      headers: {Authorization: `Basic ${this.getBasicToken()}`},
      method: 'post',
      url: baseUrl + `/api/send_to_pipedrive`,
    })
  }

  /**
   * Agregar nuevo
   */
  addNewLead(name: string, phone: string, contact: string){
    return axios({
      data: {name, phone, contact},
      headers: {Authorization: `Basic ${this.getBasicToken()}`},
      method: 'post',
      url: baseUrl + `/api/add_new_lead`,
    })
  }

  deleteActivity(activityId: number){
    return axios({
      headers: {Authorization: `Basic ${this.getBasicToken()}`},
      method: 'delete',
      url: baseUrl + `/api/delete_activity/${activityId}`,
    })
  }

  leadsDistribution(sdrOriginal: string, sdrNuevo: string, howMany: number, campaignId: number){
    return axios({
      data: { sdr_original: sdrOriginal, sdr_nuevo: sdrNuevo, how_many: howMany, campaign_id: campaignId },
      headers: {Authorization: `Basic ${this.getBasicToken()}`},
      method: 'post',
      url: baseUrl + `/api/leads_distributions`,
    })
  }
  
}