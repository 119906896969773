import * as React from 'react';
import { Redirect } from 'react-router';
import Sidebar from 'react-sidebar';
import {Button, Glyphicon} from 'react-bootstrap';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

// Local modules
import GridCitas from './Grids/GridCitas';
import GridNuevos from './Grids/GridNuevos';
import GridTodosLeads from './Grids/GridTodosLeads';
import GridTodosNuevos from './Grids/GridTodosNuevos';
import GridActividades from './Grids/GridActividades';
import GridTodasActividades from './Grids/GridTodasActividades';
import NewLeadPage from './NewLead/NewLeadPage';
import ListoAuthService from './utils/ListoAuthService';
import SideBarContent from './SideBarContent';
import {CampaignContext} from './utils/contexts';
import Popup from './BasePopUp/Popup';
import { ConsolePage } from './console/ConsolePage';

interface IState{
  tabIndex: number,
  Tabs: Tab[],
  TabPanels: TabPanel[],
  urls: string[],
  campaignId: number,
  organization?: number,
  redirect?: boolean,
}

class TabManager extends React.Component<any, IState>{
  static defaultProps = {
    auth: new ListoAuthService()
  }

  constructor(props: any, context: any){
    super(props, context);
    this.state={
      tabIndex: 0,
      Tabs: [],
      TabPanels: [],
      urls: [],
      campaignId: 0,
    };
    this.onSelectTab = this.onSelectTab.bind(this)
    this.onSelectCampaign = this.onSelectCampaign.bind(this)
    this.generateTabs = this.generateTabs.bind(this);
  }

  componentWillMount(){
    if(!this.props.auth.loggedIn()){
      this.setState({redirect: true})
    }
    else{
      this.props.auth.getUserProfile();
      this.setState({
        organization: this.props.auth.getOrganizationId(),
        campaignId: parseInt(new URLSearchParams(document!.location!.search).get("campaign") || "", 10) || this.props.auth.getBaseCampaign(),
      })
    }
  }

  componentDidMount(){
    this.generateTabs()
  }

  generateTabs(){
    const res: any = {Tabs: [], TabPanels: [], urls: []};
    const p = this.props.auth.getUserPermissions() || {};  // THIS SOLVES A BUG; BUT SHOULD BE ANALYZED
    const pathname = document!.location!.pathname;
    let tabIndex = 0;

    // Próximas actividades
    if(!('next_activities' in p && p.next_activities.view === false)){
      res.Tabs.push(<Tab key={2} className="listo-tab" >Próximas Actividades</Tab>)
      res.TabPanels.push(<TabPanel key={2}>
        <GridActividades campaignId={this.state.campaignId} {...p.next_activities}/>
      </TabPanel>)
      res.urls.push('/actividades')
      if (pathname === '/actividades'){ tabIndex = res.Tabs.length - 1 }
    }

    // Leads sin actividades
    if(!('new_leads' in p && p.new_leads.view === false)){
      res.Tabs.push(<Tab key={3} className="listo-tab">Leads sin Actividades</Tab>)
      res.TabPanels.push(<TabPanel key={3}>
        <GridNuevos campaignId={this.state.campaignId} {...p.new_leads}/>
      </TabPanel>)
      res.urls.push('/nuevos')
      if (pathname === '/nuevos'){ tabIndex = res.Tabs.length - 1 }
    }

    // Citas
    if(!('meetings' in p && p.meetings.view === false)){
      res.Tabs.push(<Tab key={4} className="listo-tab">Citas</Tab>)
      res.TabPanels.push(<TabPanel key={4}><GridCitas {...p.meetings}/></TabPanel>)
      res.urls.push('/citas')
      if (pathname === '/citas'){ tabIndex = res.Tabs.length - 1 }
    }

    // Todas las actividades
    if(!('all_activities' in p && p.all_activities.view === false)){
      res.Tabs.push(<Tab key={5} className="listo-tab">Todas las Actividades</Tab>)
      res.TabPanels.push(<TabPanel key={5}><GridTodasActividades {...p.all_activities}/></TabPanel>)
      res.urls.push('/todas_actividades')
      if (pathname === '/todas_actividades'){ tabIndex = res.Tabs.length - 1 }
    }

    // Todos los Leads
    if(!('all_leads' in p && p.all_leads.view === false)){
      res.Tabs.push(<Tab key={6} className="listo-tab">Todos los Leads</Tab>)
      res.TabPanels.push(<TabPanel key={6}><GridTodosLeads transfer_lead={p.transfer_lead} {...p.all_leads}/></TabPanel>)
      res.urls.push('/todos_leads')
      if (pathname === '/todos_leads'){ tabIndex = res.Tabs.length - 1 }
    }

    // Todos los Nuevos Leads que ven los usuarios
    if(!('all_new_leads' in p && p.all_new_leads.view === false)){
      res.Tabs.push(<Tab key={7} className="listo-tab">Todos los Nuevos Leads</Tab>)
      res.TabPanels.push(<TabPanel key={7}><GridTodosNuevos {...p.all_new_leads}/></TabPanel>)
      res.urls.push('/todos_nuevos')
      if (pathname === '/todos_nuevos'){ tabIndex = res.Tabs.length - 1 }
    }

    // Nuevo Lead
    if(!('create_lead' in p && p.create_lead.view===false)){
      res.Tabs.push(<Tab key={8} className="listo-tab">Añadir Lead</Tab>)
      res.TabPanels.push(<TabPanel key={8}><NewLeadPage /></TabPanel>)
      res.urls.push('/agregar')
      if (pathname === '/agregar'){ tabIndex = res.Tabs.length - 1;}
    }

    // Consola
    if (p.console) {
      res.Tabs.push(<Tab key={9} className="listo-tab">Consola</Tab>)
      res.TabPanels.push(<TabPanel key={8}><ConsolePage /></TabPanel>)
      res.urls.push('/consola')
      if (pathname === '/consola'){ tabIndex = res.Tabs.length - 1;}
    }

    // Logout
    res.Tabs.push(<Tab key={99} className="listo-tab tab-float-right">
                    <Button bsStyle="link" style={{width:'100%', height: '29px', padding: '5px', margin: 0, color: '#c5cdd0'}} onClick={this.props.auth.logout} >
                      <Glyphicon glyph="log-out" /> Salir
                    </Button>
                  </Tab>)
    res.TabPanels.push(<TabPanel key={99}>Logging Out</TabPanel>)

    this.setState({Tabs: res.Tabs, TabPanels: res.TabPanels, urls: res.urls, tabIndex})
  }

  onSelectCampaign(campaignId: number){
    if(campaignId !== this.state.campaignId){
      this.setState({campaignId}, ()=>{
        this.props.history.push(`${this.state.urls[this.state.tabIndex]}?campaign=${campaignId}`);
        this.generateTabs();
      })
    }
  }

  onSelectTab(tabIndex: number){
    if(tabIndex === this.state.Tabs.length - 1){
      this.setState({redirect: true})
    }
    else{
      this.props.history.push(`${this.state.urls[tabIndex]}?campaign=${this.state.campaignId}`);
      this.setState({ tabIndex })
    }
  }

  render(){
    if(this.state.redirect){
      return <Redirect to="/login" push={true} />
    }
    return  <CampaignContext.Provider value={this.state.campaignId}>
      <Sidebar
        sidebar={<SideBarContent campaignId={this.state.campaignId} onSelect={this.onSelectCampaign}/>}
        docked={true} styles={{ sidebar: { background: "#233f4b", width: "70px" } }}>
        <Tabs selectedIndex={this.state.tabIndex} onSelect={this.onSelectTab}>
          <TabList className="main-tablist">
            {this.state.Tabs}
          </TabList>
          {this.state.TabPanels}
        </Tabs>
      </Sidebar>
      <Popup />
    </CampaignContext.Provider>
  }
}

export default TabManager;