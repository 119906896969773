import * as React from 'react';
import { Glyphicon } from 'react-bootstrap';
import Alert from 'react-s-alert';
import Select, {Creatable} from 'react-select';

interface IEditableProps {
  editBranch: any,
  onSave?: any,
  formatter?: any,
  placeholder?: string,
  field: string,
  text: string
  forcePlaceholder?: boolean,
  selectProps?: any,
}

export class Editable extends React.Component<IEditableProps, any>{
  constructor(props: any){
    super(props);
    this.state = {
      editing: false
    };
    this.update = this.update.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.inputChange = this.inputChange.bind(this)
    this.onClick = this.onClick.bind(this)
  }

  componentDidMount(){
    this.setState({value: this.props.text})
  }

  componentWillReceiveProps(np: any){
    this.setState({value: np.text})
  }

  inputChange(e: any){
    this.setState({value: e.target.value});
  }

  onClick(e: any){
    this.setState({editing: true});
  }

  handleKeyDown(e: any){
    if(e.key === 'Enter'){
      this.setState({editing:false});
      if(this.props.onSave){
        this.setState({value: this.props.onSave(this.state.value)}, () => this.update())
      }
      else{
        this.update();
      }
    }
    else if(e.key === 'Escape'){
      this.setState({editing:false, value: this.props.text});
    }
  }

  update(){
    this.props.editBranch(this.state.value).catch(() => {
      console.log('Error en editBranch', this.state.value);
      Alert.error(`Error`, {position: 'bottom-left', effect: 'jelly', timeout: 2000});
    })
  }

  render(){
    let text = this.props.formatter ? this.props.formatter(this.state.value) : this.state.value;
    if(!text && this.props.forcePlaceholder){ text = this.props.placeholder }
    if (this.props.field === "website"){
      if(!this.state.value){ text = this.props.placeholder;}
      else{ text = <a target="_blank" href={`http://${this.state.value}`}>{this.state.value}</a>;}
    }
    else if (this.props.field === "phone"){
      if(!this.state.value){ text = this.props.placeholder;}
      else{
        text = <a target="_blank" href={`tel:${(this.state.value || '').replace(/ /g, '')}`}>
          {this.props.formatter ? this.props.formatter(this.state.value) : this.state.value}
        </a>;
      }
    }
    if (this.state.editing){
      return <span><input
        className="branch-input" value={this.state.value}
        onChange={this.inputChange} onKeyDown={this.handleKeyDown}
        placeholder={this.props.placeholder}
        ref={input => input && input.focus()}/>
      </span>
    }

    return <div>
      <span className={text === this.props.placeholder ? 'placeholderstyle' : ''}>{text}</span>
      &nbsp;
      <sup>
        <Glyphicon
          onClick={this.onClick}
          className='edit-glyph small-glyph'
          glyph="pencil"/>
      </sup>
    </div>
  }
}

export class EditableSelect extends React.Component<IEditableProps, any>{
  constructor(props: any){
    super(props);
    this.state = {editing: false};
    this.update = this.update.bind(this);
    this.onChange = this.onChange.bind(this)
    this.onClickSave = this.onClickSave.bind(this)
    this.onClickCancel = this.onClickCancel.bind(this)
    this.onClickEdit = this.onClickEdit.bind(this)
  }

  componentDidMount(){
    this.setState({
      value: this.props.text,
      valueKey: this.props.selectProps.valueKey || 'value'})
  }

  componentWillReceiveProps(np: any){
    this.setState({
      value: np.text,
      valueKey: np.selectProps.valueKey || 'value'
    })
  }

  onChange(e: any){
    this.setState({value: e ? e[this.state.valueKey] : null});
  }

  update(){
    this.props.editBranch(this.state.value).catch(() => {
      Alert.error(`Error`, {position: 'bottom-left', effect: 'jelly', timeout: 2000});
    })
  }

  onClickSave(){
    this.setState({editing: false});
    this.update();
  }

  onClickCancel(){
    this.setState({editing: false});
  }

  onClickEdit(){
    this.setState({editing: true});
  }

  render(){
    let text = this.state.value;
    if(!text && this.props.forcePlaceholder){ text = this.props.selectProps.placeholder }
    if (this.state.editing){
      return (<div className='editableSelect-cont'>
        <div className='editableSelect-select'><Select
          value={this.state.value}
          onChange={this.onChange}
          {...this.props.selectProps}/></div>
        <div>
          <div className="popup-tooltip">
            <sup><Glyphicon
              onClick={this.onClickSave}
              className='save-glyph editableSelect-glyph'
              glyph="ok"/></sup>
            <span className="popup-tooltiptext">Guardar</span>
          </div>
          <div className="popup-tooltip">
            <sup><Glyphicon
              onClick={this.onClickCancel}
              className='discard-glyph editableSelect-glyph'
              glyph="trash"/></sup>
            <span className="popup-tooltiptext">Descartar</span>
          </div>
        </div>
      </div>)
    }
    return (<div>
      <span className={text === this.props.selectProps.placeholder ? 'placeholderstyle' : ''}>{text}</span>
      &nbsp;
      <sup>
        <Glyphicon
          onClick={this.onClickEdit}
          className='edit-glyph small-glyph'
          glyph="pencil"/>
      </sup>
    </div>)
  }
}

export class EditableCreatable extends React.Component<IEditableProps, any>{
  constructor(props: any){
    super(props);
    this.state = {editing: false};
    this.update = this.update.bind(this);
    this.onChange = this.onChange.bind(this)
    this.onClickCancel = this.onClickCancel.bind(this)
    this.onClickEdit = this.onClickEdit.bind(this)
    this.onClickSave = this.onClickSave.bind(this)
  }

  componentDidMount(){
    this.setState({
      value: this.props.text,
      valueKey: this.props.selectProps.valueKey || 'value'})
  }

  componentWillReceiveProps(np: any){
    this.setState({
      value: np.text,
      valueKey: np.selectProps.valueKey || 'value'
    })
  }

  onClickSave(){
    this.setState({editing: false});
    this.update();
  }

  onClickCancel(){
    this.setState({editing: false});
  }

  onClickEdit(){
    this.setState({editing: true});
  }

  onChange(e: any){
    this.setState({value: e ? e[this.state.valueKey] : null});
  }

  update(){
    this.props.editBranch(this.state.value).catch( () => {
      Alert.error(`Error`, {position: 'bottom-left', effect: 'jelly', timeout: 2000});
    })
  }

  render(){
    let text = this.state.value;
    if(!text && this.props.forcePlaceholder){ text = this.props.selectProps.placeholder }
    if (this.state.editing){
      return (<div className='editableSelect-cont'>
        <div className='editableSelect-select'><Creatable
          value={this.state.value}
          onChange={this.onChange}
          {...this.props.selectProps}/></div>
        <div>
          <div className="popup-tooltip">
            <sup><Glyphicon
              onClick={this.onClickSave}
              className='save-glyph editableSelect-glyph'
              glyph="ok"/></sup>
            <span className="popup-tooltiptext">Guardar</span>
          </div>
          <div className="popup-tooltip">
            <sup><Glyphicon
              onClick={this.onClickCancel}
              className='discard-glyph editableSelect-glyph'
              glyph="trash"/></sup>
            <span className="popup-tooltiptext">Descartar</span>
          </div>
        </div>
      </div>)
    }
    return (<div>
      <span className={text === this.props.selectProps.placeholder ? 'placeholderstyle' : ''}>{text}</span>
      &nbsp;
      <sup>
        <Glyphicon
          onClick={this.onClickEdit}
          className='edit-glyph small-glyph'
          glyph="pencil"/>
      </sup>
    </div>)
  }
}