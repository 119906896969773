import * as moment from 'moment';
import TimePicker from 'rc-time-picker';
import * as React from 'react';
import {Button, Glyphicon, ListGroup, ListGroupItem, Table} from 'react-bootstrap';
import DatePicker from 'react-datepicker';

// Local modules
import { CustomDropdown } from '../CustomComponents/CustomSelect';
import { Activity } from '../data/models'
import * as selectOptions from '../data/selectOptions';

function ExampleCustomInput(props: any){
  return <input
      style={{width: 179}}
      onClick={props.onClick}
      disabled={props.disabled}
      className="rc-date-picker-input" value={props.value} />
}

function SimpleRow(props: any){
  return <tr>
    <td><b>{props.label}</b></td>
    <td>{props.value}</td>
  </tr>
}

export default class PopupFormCitas extends React.Component<any, any>{
  constructor(props: any){
    super(props);
    this.state = {
      infoActualizada: false,
      error: false,
    }
    this.buttonClick = this.buttonClick.bind(this);
    this.encargadoOnChange = this.encargadoOnChange.bind(this);
    this.estatusOnChange = this.estatusOnChange.bind(this);
    this.comentariosOnChange = this.comentariosOnChange.bind(this);
    this.proximaFechaOnChange = this.proximaFechaOnChange.bind(this);
    this.proximaHoraOnChange = this.proximaHoraOnChange.bind(this)
    this.proximosComentariosOnChange = this.proximosComentariosOnChange.bind(this);
    this.proximoEncargadoOnChange = this.proximoEncargadoOnChange.bind(this)
    this.proximaActividadOnChange = this.proximaActividadOnChange.bind(this)
  }

  componentDidMount(){
    this.props.auth.getUsers()
      .then((res: any) => {
        const ejecutivos = res.data.objects.filter((obj: any) => obj.active)
          .map((obj: any) => ({label: obj.name, value: obj.id}) )
        this.setState({
          ejecutivos,
          tipo: this.props.tipo,
          estatus: this.props.estatus,
          encargado: this.props.encargado_prop,
          is_pipedrive: this.props.is_pipedrive,
          proximaActividad: this.props.prox_act,
          proximaHora: moment(this.props.fechaCita),
          proximaFecha: moment(this.props.fechaCita),
        })
      })
  }

  buttonClick(ultimaLlamada: boolean, pipedrive: boolean){
    // Por si presionan el botón más de una vez que no se registre como 2 actividades
    if (this.state.infoActualizada){ return true }

    // No se puede actualizar una cita que ya se ha completado
    if (this.props.estatus === 'Completada'){ return true }

    // Validación
    if (!this.state.estatus){
      this.setState({error:true, errorMessage: 'Estatus no puede ser vacío'});
      return true;
    }
    if (!this.state.encargado){
      this.setState({error:true, errorMessage: 'Encargado no puede estar vacío'});
      return true;
    }
    if (!this.state.proximaActividad && ultimaLlamada !== true &&
        (this.state.estatus==='Completada' || this.state.estatus==='Pendiente de Reagendamiento' || this.state.estatus==='Cancelada')){
      this.setState({error:true, errorMessage: 'Próxima actividad no puede estar vacía'});
      return true;
    }
    if (this.state.proximaFecha && moment().diff(this.state.proximaFecha, 'days') > 0){
      this.setState({error:true, errorMessage: 'La fecha es incorrecta'});
      return true;
    }
    if (this.state.estatus === this.props.estatus &&
        this.state.encargado === this.props.encargado &&
        this.state.is_pipedrive === this.props.is_pipedrive &&
        this.state.proximaFecha === moment(this.props.fechaCita) &&
        this.state.proximaHora === moment(this.props.fechaCita)){
      this.setState({error:true, errorMessage: 'Debes cambiar al menos un dato'});
      return true;
    }

    let config: Activity, promises, date;
    if(this.state.proximaActividad && !Boolean(this.state.encargado_prop)){
      this.setState({encargado_prop: this.props.auth.getUserId()});
    }
    date = !ultimaLlamada && this.state.proximaFecha.hour(this.state.proximaHora.hour()).minute(this.state.proximaHora.minute()).format('YYYY-MM-DDThh:mm:ss')
    config = {
      lead_id: this.props.leadId,
      user_id: this.props.auth.getUserId(),
      tipo: this.state.tipo,
      encargado: this.state.encargado,
      contact: this.props.contact,
      estatus: this.state.estatus,
      descripcion: this.state.descripcion,
      prox_act: ultimaLlamada ? null : ((this.state.estatus === 'Completada' || this.state.estatus === 'Pendiente de Reagendamiento' || this.state.estatus === 'Cancelada') ? this.state.proximaActividad : this.state.tipo),
      encargado_prop: ultimaLlamada ? null : this.state.encargado_prop,
      prox_act_prop: ultimaLlamada ? moment("2099-01-01").format('YYYY-MM-DDThh:mm:ss') : date,
      prox_act_anotacion: ultimaLlamada ? null : ((this.state.estatus === 'Completada' || this.state.estatus === 'Pendiente de Reagendamiento' || this.state.estatus === 'Cancelada') ? this.state.proximosComentarios : this.props.prox_act_anotacion),
    }

    promises = [this.props.auth.postActivity(config)]
    if(ultimaLlamada && pipedrive){
      promises.push(this.props.auth.editLead(this.props.leadId, 'is_pipedrive', true));
    }
    Promise.all(promises)
      .then(() => {
        this.setState({infoActualizada: true, error: false, errorMessage: ''});
      })
      .catch(() => {
        this.setState({error: true, errorMessage: 'Error'});
        console.log("Error en postActivity");
        console.log(config);
      });
    return
  }

  encargadoOnChange(e: any){ this.setState({encargado: e ? e.value : null}); }

  estatusOnChange(e: any){ this.setState({estatus: e ? e.value : null}); }

  proximoEncargadoOnChange(e: any){ this.setState({encargado_prop: e ? e.value : null}); }

  comentariosOnChange(e: any){ this.setState({descripcion: e.target.value});}

  proximaFechaOnChange(e: any){ this.setState({proximaFecha: e})}

  proximaHoraOnChange(e: any){ this.setState({proximaHora: e})}

  proximaActividadOnChange(e: any){ this.setState({proximaActividad: e ? e.value : null}); }

  proximosComentariosOnChange(e: any){ this.setState({proximosComentarios: e.target.value}); }

  render(){
    let message, alert, encargado;
    if (this.state.infoActualizada || this.state.error){
      message = (
          <ListGroup className="popup-alert">
            <ListGroupItem bsStyle={this.state.error ? 'danger' : 'success'}>
              {this.state.error ? this.state.errorMessage : 'Información Actualizada'}
            </ListGroupItem>
          </ListGroup>);
    }
    if (this.props.estatus === 'Completada'){
      alert = (
          <ListGroup className="popup-alert" style={{marginBottom:10}}>
            <ListGroupItem bsStyle='danger'>No se puede cambiar la información de citas completadas</ListGroupItem>
          </ListGroup>);
    }
    if (this.state.proximaActividad && this.state.proximaActividad !== 'Llamada' && this.props.estatus !== 'Completada'){
      encargado = <CustomDropdown
        placeholder="Encargado"
        label="Próximo encargado"
        labelClassName="popup-dropdown-label dt"
        selectContClassName="popup-citas-dropdown sin-asignar"
        value={this.state.encargado_prop}
        onChange={this.proximoEncargadoOnChange}
        options={this.state.ejecutivos} />
    }
    return (
      <div id="popup-form-actividades-wrapper">
        <div className="left-side-small-popup with-margin" style={{marginRight:0}}>
          <h3 className="capitalize-all-words">{this.props.rs}</h3>
          {this.props.totalBranches > 1 ? <h4 style={{margin:0}} className="capitalize-all-words">{this.props.name}</h4> : null}
          <Table responsive={true}>
            <colgroup>
              <col style={{width:'30%'}}/>
            </colgroup>
            <tbody>
              <SimpleRow label="En Pipedrive:" value={this.props.is_pipedrive ? 'Si' : 'No'}/>
              <SimpleRow label="Empleados:" value={this.props.employees || '-'}/>
              <SimpleRow label="Website:" value={this.props.website || '-'}/>
              <SimpleRow label="Software:" value={this.props.software || '-'}/>
            </tbody>
          </Table>
          <h3 style={{marginTop:0}}>Información de Cita</h3>
          <Table responsive={true}>
            <colgroup>
              <col style={{width:'30%'}}/>
            </colgroup>
            <tbody>
              <SimpleRow label="Creada:" value={moment(this.props.timestamp).locale('es').format("DD MMM YYYY HH:mm")}/>
              <SimpleRow label="Fecha Cita:" value={moment(this.props.fechaCita).locale('es').format("DD MMM YYYY HH:mm")}/>
              <SimpleRow label="Tipo:" value={this.props.tipo || '-'}/>
              <SimpleRow label="Estatus:" value={this.props.estatus || '-'}/>
              <SimpleRow label="Encargado:"
                         value={this.state.ejecutivos && this.state.ejecutivos.find((e: any) => e.value === this.props.encargado).label}/>
              <SimpleRow label="Ejecutivo:" value={this.props.user || '-'}/>
              <SimpleRow label="Contacto:" value={this.props.contacto ? this.props.contacto.name : '-'}/>
              <SimpleRow label="Puesto:" value={this.props.contacto ? this.props.contacto.role : '-'}/>
              <SimpleRow label="Teléfono:" value={this.props.contacto ? this.props.contacto.phone : '-'}/>
              <SimpleRow label="Correo:" value={this.props.contacto ? this.props.contacto.email : '-'}/>
              <SimpleRow label="Descripción:" value={this.props.descripcion ? this.props.descripcion : '-'}/>
            </tbody>
          </Table>
        </div>
        <div className="right-side-small-popup with-margin">
          <h3>Acciones:</h3>
          {alert}
          <CustomDropdown
            disabled={this.props.estatus === 'Completada'}
            label="Cambiar encargado"
            placeholder="Encargado"
            labelClassName="popup-dropdown-label dt"
            selectContClassName="popup-citas-dropdown sin-asignar"
            value={this.state.encargado}
            onChange={this.encargadoOnChange}
            options={this.state.ejecutivos} />
          <CustomDropdown
            disabled={this.props.estatus === 'Completada'}
            label="Cambiar estatus"
            placeholder="Estatus"
            labelClassName="popup-dropdown-label dt"
            selectContClassName="popup-citas-dropdown sin-asignar"
            value={this.state.estatus}
            onChange={this.estatusOnChange}
            options={selectOptions.listaEstatusCita} />
          {this.state.estatus !== 'Reagendada' ? null : <div><div style={{width: '100%', overflow: 'hidden'}}>
            <div className="popup-dropdown-label dt">Nueva fecha</div>
            <DatePicker
              dateFormat="DD MMM YYYY"
              locale="es"
              utcOffset={-5}
              disabled={this.props.estatus === 'Completada'}
              customInput={<ExampleCustomInput/>}
              selected={this.state.proximaFecha}
              onChange={this.proximaFechaOnChange} />
          </div>
          <div style={{width: '100%', overflow: 'hidden'}}>
            <div className="popup-dropdown-label dt">Nueva hora</div>
            <TimePicker
              className="narrow-timepicker"
              onChange={this.proximaHoraOnChange}
              disabled={this.props.estatus === 'Completada'}
              defaultValue={moment(this.props.fechaCita)}
              showSecond={false} />
          </div></div>}
          <h4>Motivo de cambio:</h4>
          <textarea
            rows={2}
            placeholder="Descripción..."
            value={this.state.comentarios}
            onChange={this.comentariosOnChange}
            disabled={this.props.estatus === 'Completada'}/>
          {
            (this.state.estatus === 'Completada' || this.state.estatus === 'Pendiente de Reagendamiento' || this.state.estatus === 'Cancelada' ) ? <div>
            <CustomDropdown
              disabled={this.props.estatus === 'Completada'}
              label="Próxima Actividad"
              placeholder="Actividad"
              labelClassName="popup-dropdown-label dt"
              selectContClassName="popup-citas-dropdown sin-asignar"
              value={this.state.proximaActividad}
              onChange={this.proximaActividadOnChange}
              options={selectOptions.listaTipo} />
            {encargado}
            <div><div style={{width: '100%', overflow: 'hidden'}}>
              <div className="popup-dropdown-label dt">Fecha</div>
              <DatePicker
              dateFormat="DD MMM YYYY"
              locale="es"
              utcOffset={-5}
              disabled={this.props.estatus === 'Completada'}
              customInput={<ExampleCustomInput/>}
              selected={this.state.proximaFecha}
              onChange={this.proximaFechaOnChange} />
            </div>
            <div style={{width: '100%', overflow: 'hidden'}}>
              <div className="popup-dropdown-label dt">Hora</div>
              <TimePicker
              className="narrow-timepicker"
              onChange={this.proximaHoraOnChange}
              disabled={this.props.estatus === 'Completada'}
              defaultValue={moment(this.props.fechaCita)}
              showSecond={false} />
            </div></div>
            <textarea
              rows={2}
              placeholder="Anotaciones para la próxima actividad..."
              value={this.state.proximosComentarios}
              onChange={this.proximosComentariosOnChange}
              disabled={this.props.estatus === 'Completada'}/>
            </div> : null
          }
        </div>
        <div className="right-side with-margin full-width">
          <div className="popup-tooltip">
            <Button
              style={{float: 'left', marginRight:10}}
              bsStyle="success"
              onClick={() => this.buttonClick(true, true)}
              disabled={this.props.estatus === 'Completada'}>
              <Glyphicon glyph="ok" /> Mandar a Pipedrive</Button>
            <span className="popup-tooltiptext">Si el lead quiere conocer más sobre nosotros</span>
          </div>
          <div className="popup-tooltip">
            <Button
              style={{float: 'left'}}
              bsStyle="danger"
              onClick={() => this.buttonClick(true, false)}
              disabled={this.props.estatus === 'Completada'}>
              <Glyphicon glyph="remove" /> Rechazo</Button>
            <span className="popup-tooltiptext">Si el lead rechazó nuestros servicios</span>
          </div>
          <Button
            style={{float: 'right'}}
            bsStyle="primary"
            onClick={() => this.buttonClick(false, false)}
            disabled={this.props.estatus === 'Completada'}>
            <Glyphicon glyph="save" /> Aplicar</Button>
          {message}
        </div>
      </div>
    );
  }
}