import * as React from 'react';
import Alert from 'react-s-alert';
import Value from "react-select/lib/Value";

// Local modules
import CheckboxesContainer from '../CustomComponents/CheckboxesContainer';
import { CustomCreatable, CustomDropdown } from '../CustomComponents/CustomSelect';
import { listaScore } from '../data/selectOptions';

function ValueComponent(props: any){
  if (props.value.value === props.highlighted){
    props.value.className = 'Select-value-highlighted';
  }
  return (<Value {...props}/>);
}


export default class DetailsInformation extends React.Component<any, any>{
	constructor(props: any){
    super(props);
    this.state={
      user: [undefined]
    }
    this.scoreOnChange = this.scoreOnChange.bind(this)
    this.asignadoOnChange = this.asignadoOnChange.bind(this)
    this.softwareOnChange = this.softwareOnChange.bind(this)
    this.pipedriveOnChange = this.pipedriveOnChange.bind(this)
  }

  componentWillReceiveProps(nextProps: any){
    this.setState(Object.assign({}, nextProps))
  }

  scoreOnChange(e: any){
    const value = e ? e.value : null;
    this.props.auth.editLead(this.state.id, 'score', value).then(()=>{
      this.setState({score: value});
      Alert.success(`Score cambiado correctamente`, {position: 'bottom-left', effect: 'jelly', timeout: 2000 });
    }).catch(()=>{
      Alert.error(`Error`, { position: 'bottom-left', effect: 'jelly', timeout: 2000 });
    })
  }

  asignadoOnChange(e: any){
    const value = e ? e.value : null;
    console.log(this.state.id, value)
    this.props.auth.assignAnotherUser({lead_id: this.state.id, email: value}).then(()=>{
      this.setState({user: [this.state.users.find((usr: any) => usr.email === value)]});
      Alert.success(`Usuario cambiado correctamente`, {position: 'bottom-left', effect: 'jelly', timeout: 2000 });
    }).catch(()=>{
      Alert.error(`Error`, { position: 'bottom-left', effect: 'jelly', timeout: 2000 });
    })
  }

  softwareOnChange(e: any){
    const value = e ? e.value : null;
    this.props.auth.editLead(this.state.id, 'software', value).then(()=>{
      this.setState({software: value});
      Alert.success(`Software cambiado correctamente`, {position: 'bottom-left', effect: 'jelly', timeout: 2000 });
    }).catch(()=>{
      Alert.error(`Error`, { position: 'bottom-left', effect: 'jelly', timeout: 2000 });
    })
  }

  pipedriveOnChange(e: any){
    const value = e ? e.value : null;
    this.props.auth.editLead(this.state.id,'is_pipedrive', value).then(()=>{
      this.setState({is_pipedrive: value});
      Alert.success(`Pipedrive cambiado correctamente`, {position: 'bottom-left', effect: 'jelly', timeout: 2000 });
    }).catch(()=>{
      Alert.error(`Error`, { position: 'bottom-left', effect: 'jelly', timeout: 2000 });
    })
  }

	render(){
    let scoreDd, asignadoDd, softwareDd, pipedriveDd, modulos, campaigns;
    const permissions = this.props.auth.getUserPermissions() || {};
    console.log(this.state)

    scoreDd = <CustomDropdown
        label="Score"
        placeholder="Score"
        divClassName="details-information"
        labelClassName="details-information-label"
        selectContClassName="details-information-select"
        value={this.state.score}
        onChange={this.scoreOnChange}
        ValueComponent={ValueComponent}
        options={listaScore.map((o: any) => o.values.includes(this.state.score) ? Object.assign({}, o, {value:this.state.score }): o)} />

    if(!('transfer_lead' in permissions && permissions.transfer_lead.view===false)){
      asignadoDd = <CustomDropdown
        label="Asignado a"
        placeholder="Asignado a"
        divClassName="details-information"
        labelClassName="details-information-label"
        selectContClassName="details-information-select"
        disabled={permissions.transfer_lead ? permissions.transfer_lead.edit===false : false}
        value={this.state.user[0] && this.state.user.length>0 ? this.state.user[0].email : null}
        onChange={this.asignadoOnChange}
        options={this.state.users && this.state.users.map((user: any, i: number)=> ({value: user.email, label: user.name}))} />
      }

    softwareDd = <CustomCreatable
        label="Software"
        placeholder="Software"
        divClassName="details-information"
        labelClassName="details-information-label"
        selectContClassName="details-information-select"
        value={this.state.software}
        onChange={this.softwareOnChange}
        options={this.state.listaSoftware}
        onNewOptionClick={(option: any) =>{
          this.props.auth.createSoftware(option.label).then(()=>{
            const ops = this.state.listaSoftware;
            ops.unshift({label: option.label, value: option.value})
            this.setState({listaSoftware: ops})
          })
        }}/>

    if(!('pipedrive' in permissions && permissions.pipedrive.view===false)){
      pipedriveDd = <CustomDropdown
        label="Pipedrive"
        placeholder="Pipedrive"
        divClassName="details-information"
        labelClassName="details-information-label"
        selectContClassName="details-information-select"
        disabled={permissions.pipedrive ? permissions.pipedrive.edit===false : false}
        value={Boolean(this.state.is_pipedrive)}
        onChange={this.pipedriveOnChange}
        options={[{value:true, label: 'Dado de alta en Pipedrive'}, {value:false, label: 'No registrado en Pipedrive'}]} />
    }

    modulos = <CheckboxesContainer
      field="modules"
      options={this.state.listaModulos}
      leadId={this.state.id}
      editLead={(id: number, attr: string, val: any) => this.props.auth.editLead(id,attr,val)}/>

    campaigns = <CheckboxesContainer
      field="campaigns"
      options={this.state.listaCampañas}
      leadId={this.state.id}
      editLead={(id: number, attr: string, val: any) => this.props.auth.editLead(id,attr,val)}/>

		return(<div>
      {scoreDd}
      {asignadoDd}
      {softwareDd}
      {pipedriveDd}
      <h4>Módulos de interés</h4>
      {modulos}
      <h4>Campañas trabajadas</h4>
      {campaigns}
    </div>);
	}
}