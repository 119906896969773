import * as React from 'react';

export default class PopupHeader extends React.Component<any, any>{
  static displayName = 'PopupHeader'

  constructor(props: any){
    super(props);
  }

  render() {
    return (
    <header className="popup-header">
      <h4 className='popup-header__title'>{this.props.title}</h4>
      <button onClick={this.props.onClose} className='popup-header__close' />
    </header>
    );
  }
}
