import * as moment from 'moment';
import * as React from 'react';
import { NavLink } from 'react-router-dom';

// Local modules
import { telFormat } from '../data/otros';
import { listaScore } from '../data/selectOptions';

export class WebSite extends React.Component<any, any>{
  render(){
    return <a href={`http://${this.props.value}`} target="_blank">{this.props.value}</a>;
  }
}

export class Phone extends React.Component<any, any>{
  render(){
    return <span>{telFormat(this.props.value)}</span>;
  }
}

export class LocalizacionFormatter extends React.Component<any, any>{
  render(){
    return <span className="capitalize-all-words">{this.props.value}</span>;
  }
}

export class PipedriveFormatter extends React.Component<any, any>{
  render(){
    return <span>{this.props.value ? 'Registrado' : 'No registrado'}</span>
  }
}

export class Score extends React.Component<any, any>{
  render(){
    const score = listaScore.find((o: any) => o.values.includes(this.props.value || 0))
    return (<div>
      <div className={`score-formatter ${score ? score.label.replace(/ /g, "-") : ""}`} />
      <span>
        {score ? score.label : ""}
      </span>
    </div>);
  }
}

export class RSLink extends React.PureComponent<any, any>{
  render(){
      const row = this.props.rowGetter(this.props.rowIdx);
      return <NavLink to={`/${row.leadId}`} target="_blank" className="capitalize-all-words">{this.props.value}</NavLink>;
  }
}

export class Estatus extends React.Component<any, any>{
  render(){
    const row = this.props.rowGetter(this.props.rowIdx);
    return (<div>
      <div className="score-formatter" style={{borderColor: row.color}} />
      <span>{this.props.value}</span>
    </div>);
  }
}

export class ActivityDate extends React.Component<any, any>{
  render(){
    if(this.props.value && typeof(this.props.value) === "object"){
      return <span>{this.props.value.locale('es').format("DD MMM YYYY HH:mm")}</span>
    }
    return <span>{moment(this.props.value).isValid() ? moment(this.props.value.replace(' GMT', '')).locale('es').format("DD MMM YYYY HH:mm") : '-'}</span>
  }
}

export class Ellipsis extends React.Component<any, any>{
  private timer: any = undefined

  constructor(props: any){
    super(props);
    this.state={i: 1}
  }

  componentDidMount(){
    this.timer = setInterval(() => {
      if(this.state.i < 4){
        this.setState({i: this.state.i + 1})
      }
      else{
        this.setState({i: 1})
      }
    }, 1000);
  }

  componentWillUnmount(){
    clearInterval(this.timer);
  }

  render(){
    return <span>{`Cargando${Array(this.state.i).join(". ")}`}</span>
  }
}