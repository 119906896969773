import {Column} from 'react-data-grid';

// Columnas del GridNuevos
export const colsUnassigned:Column[] = [
  {key: 'score', width: 80, sortable : true, filterable: true, name: 'Score'},
  {key: 'llamar', width: 150, name: 'Agregar actividad'},
  {key: 'name', sortable : true, filterable: true, name: 'Razón Social'},
  {key: 'localizacion', width: 300, sortable : true, filterable: true, name: 'Colonia'},
  {key: 'employees', width: 150, sortable : true, filterable: true, name: 'No. Empleados'},
  {key: 'email', width: 250, sortable : true, filterable: true, resizable: true, name: 'E-Mail'},
  {key: 'phone', width: 150, sortable : true, filterable: true, resizable: true, name: 'Teléfono'},
  {key: 'website', width: 180, sortable : true, filterable: true, resizable: true, name: 'Página web'},
  {key: 'activity_name', width: 200, sortable : true, filterable: true, name: 'Tipo Actividad'},
  {key: 'sucursales', width: 110, sortable : true, filterable: true, name: 'Sucursales'},
];

// Columnas del GridActividades
export const colsActivities:Column[] = [
  {key: 'estatus', width: 200, sortable : true, filterable: true, name: 'Resultado anterior'},
  {key: 'id', width: 150, name: 'Agregar actividad'},
  {key: 'prox_act_prop', name: 'Fecha Programada', width: 170, sortable : true, filterable: true},
  {key: 'total_activities', width: 150, name: 'Total actividades', sortable : true, filterable: true},
  {key: 'timestamp', width: 150, name: 'Actividad anterior', sortable : true, filterable: true},
  {key: 'prox_act', width: 200, sortable : true, filterable: true, name: 'Tipo'},
  {key: 'tipo', width: 200, sortable : true, filterable: true, name: 'Última Actividad'},
  {key: 'name', sortable : true, filterable: true, name: 'Razón Social'},
];

// Columnas del GridCitas
export const colsMeetings:Column[] = [
  {key: 'creacionCita', name: 'Creación de Cita', width: 170, sortable : true, filterable: true},
  {key: 'fechaCita', name: 'Fecha y hora de cita', width: 170, sortable : true, filterable: true},
  {key: 'user', name: 'Ejecutivo', width: 120, sortable : true, filterable: true},
  {key: 'encargadoName', name: 'Encargado', width: 120, sortable : true, filterable: true},
  {key: 'name', name: 'Razón Social', width: 300, sortable : true, filterable: true},
  {key: 'is_pipedrive', name: 'Pipedrive', width: 120, sortable : true, filterable: true},
  {key: 'estatus', name: 'Estatus de cita', width: 170, sortable : true, filterable: true},
  {key: 'tipoCita', name: 'Tipo de cita', width: 170, sortable : true, filterable: true},
  {key: 'descripcion', name: 'Descripción', width: 200, sortable : true, filterable: true},
  {key: 'contactName', name: 'Contacto', sortable : true, filterable: true},
  {key: 'contactPhone', name: 'Teléfono', sortable : true, filterable: true},
  {key: 'edit', width: 80, name: 'Editar'},
];

// Columnas del GridTodasActividades
export const colsAllActivities:Column[] = [
  {key: 'ejecutivo', name: 'Ejecutivo', width: 120, sortable : true, filterable: true},
  {key: 'encargado', name: 'Encargado', width: 120, sortable : true, filterable: true},
  {key: 'total_activities', width: 150, name: 'Total actividades', sortable : true, filterable: true},
  {key: 'name', name: 'Razón Social', sortable : true, filterable: true},
  {key: 'tipo', name: 'Última actividad', width: 150, sortable : true, filterable: true},
  {key: 'timestamp', name: 'Fecha', width: 150, sortable : true, filterable: true},
  {key: 'estatus', name: 'Estatus', width: 150, sortable : true, filterable: true},
  {key: 'prox_act', name: 'Próxima Actividad', width: 150, sortable : true, filterable: true},
  {key: 'prox_act_prop', name: 'Fecha Próxima', width: 150, sortable : true, filterable: true},
];

// Columnas del GridTodosLeads
export const colsAllLeads:Column[] = [
  {key: 'score', width: 170, sortable : true, filterable: true, name: 'Score'},
  {key: 'name', sortable : true, filterable: true, name: 'Razón Social'},
  {key: 'localizacion', width: 350, sortable : true, filterable: true, name: 'Colonia'},
  {key: 'employees', width: 150, sortable : true, filterable: true, name: 'No. Empleados'},
  {key: 'postal_code', width: 100, sortable : true, filterable: true, name: 'C.P.'},
  {key: 'sucursales', width: 110, sortable : true, filterable: true, name: 'Sucursales'},
  {key: 'ejecutivo', name: 'Asignado a:', width: 170, sortable : true, filterable: true},
  {key: 'asignar', width: 150, name: ''},
]

// Columnas del GridTodosNuevos
export const colsAllUnassigned:Column[] = [
  {key: 'score', width: 80, sortable : true, filterable: true, name: 'Score'},
  {key: 'ejecutivo', sortable : true, filterable: true, name: 'Ejecutivo'},
  {key: 'name', sortable : true, filterable: true, name: 'Razón Social'},
  {key: 'localizacion', width: 300, sortable : true, filterable: true, name: 'Colonia'},
  {key: 'employees', width: 150, sortable : true, filterable: true, name: 'No. Empleados'},
  {key: 'email', width: 250, sortable : true, filterable: true, resizable: true, name: 'E-Mail'},
  {key: 'phone', width: 150, sortable : true, filterable: true, resizable: true, name: 'Teléfono'},
  {key: 'website', width: 180, sortable : true, filterable: true, resizable: true, name: 'Página web'},
  {key: 'activity_name', width: 200, sortable : true, filterable: true, name: 'Tipo Actividad'},
  {key: 'sucursales', width: 110, sortable : true, filterable: true, name: 'Sucursales'},
];
