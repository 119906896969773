import * as React from 'react';
import { Button } from 'react-bootstrap';
import { draggableMarker } from '../data/otros';

export default class BranchesMap extends React.Component<any, any>{
  constructor(props: any){
    super(props);
    this.state={map: null, virgin: true}
    this.enableSearch = this.enableSearch.bind(this)
    this.onClick = this.onClick.bind(this)
  }
  componentDidMount(){
    const {google, center, zoom, disableDefaultUI} = this.props
    const map = new google.maps.Map(document.getElementById('map'), {
      center: center ? center : { lat: 19.417435, lng: -99.1620404 },
      zoom: zoom ? zoom : 13,
      disableDefaultUI: disableDefaultUI ? disableDefaultUI : true
    });
    this.setState({map}, this.enableSearch)
  }
  componentWillReceiveProps(nextProps: any){
    const {markers} = nextProps, map = this.state.map, {google} = this.props;
    if(!markers || this.state.virgin){ return true;}
    map.setCenter(nextProps.center)
    markers.map((m: any) => new google.maps.Marker(Object.assign({}, {map}, m)) )
    return
  }
  componentDidUpdate(){
    if(this.state.virgin) {this.setState({virgin: false})}
    // if(this.props.enableSearch && this.state.virgin) this.enableSearch();
  }
  enableSearch(){
    const map = this.state.map
    let marker: any, infowindow;
    const {google} = this.props

    // Create the search box and link it to the UI element.
    const input = document.getElementById('pac-input');
    const button = document.getElementById('save-coords-btn');
    const searchBox = new google.maps.places.SearchBox(input);
    map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);
    map.controls[google.maps.ControlPosition.TOP_LEFT].push(button);

    // Bias the SearchBox results towards current map's viewport.
    map.addListener('bounds_changed', () => searchBox.setBounds(map.getBounds()));
    searchBox.addListener('places_changed', () => {
      const places = searchBox.getPlaces();
      if (places.length === 0) { return; }

      // For each place, get the icon, name and location.
      const bounds = new google.maps.LatLngBounds();
      const place = places[0]

      // Si tiene coordenadas
      if (place.geometry) {
        if( marker ){ marker.setMap(null) }
        marker = new google.maps.Marker({
          map,
          draggable: true,
          title: place.name,
          icon: draggableMarker,
          position: place.geometry.location,
        });
        infowindow = new google.maps.InfoWindow({
          content: '<div>Puedes arrastrarme a la posición deseada.<br/>Recuerda guardar la posición.</div>',
        });
        infowindow.open(map, marker);
        if (place.geometry.viewport) {
          // Only geocodes have viewport.
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
        this.setState({ marker })
      }
      map.fitBounds(bounds);
    });
    this.setState({ map })
  }
  onClick(){
    const marker = this.state.marker, map = this.state.map
    if(!marker){ return false }
    this.props.setCoords(`POINT(${marker.position.lat()} ${marker.position.lng()})`)
    marker.setMap(null);
    map.setZoom(this.props.zoom);
    this.setState({ marker, map });
    return
  }
  render(){
    let input, button;
    input = <input
      type="text"
      id="pac-input"
      placeholder="Search Box"
      className={this.props.enableSearch ? '' : 'customgmap-hidden'}/>
    button = <Button
      bsStyle="success"
      id="save-coords-btn"
      onClick={this.onClick}
      className={this.props.enableSearch ? 'customgmap-shown' : 'customgmap-hidden'}>
        {this.props.enableSearch ? 'Guardar posición' : ''}
      </Button>
    return (<div style={this.props.style}>
      {input}
      {button}
      <div id="map"/>
    </div>)
  }
}
