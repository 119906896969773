import * as moment from 'moment';
import * as React from 'react';

// Local modules
import ActivityDetailsTable from '../CustomComponents/ActivityDetailsTable';
import ActivityFormContainer from '../CustomComponents/ActivityFormContainer';
import CheckboxesContainer from '../CustomComponents/CheckboxesContainer';
import {Editable, EditableCreatable, EditableSelect} from '../CustomComponents/CustomEditable';
import {telFormat, telValue} from '../data/otros';
import * as selectOptions from '../data/selectOptions';
import {getMainOrDefault} from '../utils/commonFuncs';
import {CampaignContext} from '../utils/contexts';

export default class PopupFormActividades extends React.Component<any, any>{
  constructor(props: any){
    super(props);
    this.state={
      contacts: []
    }
    this.editOrCreateContact = this.editOrCreateContact.bind(this)
  }

  componentDidMount(){
    const listaModulos = selectOptions.listaModulos,
        listaCampañas = this.props.listaCampañas,
        modules = this.props.modules || [],
        campaigns = this.props.campaigns || [];
    this.setState({
      usuarios: this.props.usuarios,
      listaSoftware: this.props.listaSoftware,
      contacts: this.props.contacts,
      listaModulos: listaModulos.map(m => Object.assign({}, m, {isChecked: modules.includes(m.value)})),
      listaCampañas: listaCampañas.map((c: any) => Object.assign({}, {value: c.id, label: c.name, isChecked: campaigns.includes(c.id)})),
    });
  }

  softwareNewOption(option: any){
    this.props.auth.createSoftware(option.name).then(()=>{
      const ops = this.state.listaSoftware;
      ops.unshift({id: null, name: option.name})
      this.setState({listaSoftware: ops})
    })
  }

  editOrCreateContact(value: any){
    // Edit if contact eixted, else create new Contact
    const contact = getMainOrDefault(this.props.main_contact, this.state.contacts)
    if (contact){ return this.props.auth.editContact(contact.id, {name: value})}
    else{ return this.props.auth.createContact({name: value, lead_id: this.props.leadId})}
  }

  render(){
    const contact = (this.state.contacts.length > 0 && this.props.contact) ? this.state.contacts.find((c: any) => c.id === this.props.contact) || {} : {}
    const leadName = <a target="_blank" href={`https://www.google.com.mx/search?q=${this.props.leadName.replace(/ /g, "+")}`}>{this.props.leadName}</a>
    const contactName = <span className={contact.name ? '' : 'placeholderstyle'}>{contact.name ? contact.name : 'Sin contacto'}</span>
    const contactPhone = <a href={`tel:${contact.phone ? contact.phone.replace(/ /g, '') : ''}`}><span className={contact.phone ? '' : 'placeholderstyle'}>{contact.phone ? telFormat(contact.phone) : 'Sin teléfono'}</span></a>
    const descripcion = (<span className={this.props.descripcion ? '' : 'placeholderstyle'}>
                          {this.props.descripcion ? this.props.descripcion : 'Sin descripcion de actividad'}
                        </span>)
    const anotaciones = (<span className={this.props.prox_act_anotacion ? '' : 'placeholderstyle'}>
                          {this.props.prox_act_anotacion ? this.props.prox_act_anotacion : 'Sin anotaciones'}
                        </span>)
    const direccion = `${this.props.nomb_asent ? this.props.nomb_asent.toLowerCase() + ', ' : ''}
                      ${this.props.municipio ? this.props.municipio.toLowerCase() + ', ' : ''}
                      ${this.props.entidad || ''}`

    const empleados = <EditableSelect field="employees"
                forcePlaceholder={true} text={this.props.employees}
                selectProps={{placeholder: 'No. Empleados', options: selectOptions.numEmpleados}}
                editBranch={(val: any) => this.props.auth.editBranch(this.props.id, "employees", val)}/>

    const phone = <div className='phone-big'><div>Tel.:&nbsp;</div><div><Editable
                field="phone" placeholder='sin teléfono' forcePlaceholder={true}
                onSave={telValue} formatter={telFormat} text={this.props.phone}
                editBranch={(val: any) => this.props.auth.editBranch(this.props.id, "phone", val)}/>
                </div></div>

    const website = <Editable field="website" placeholder='sin website'
                forcePlaceholder={true} text={this.props.website}
                editBranch={(val: any) => this.props.auth.editBranch(this.props.id, "website", val)}/>

    const email = <Editable field="email" placeholder='sin e-mail'
                forcePlaceholder={true} text={this.props.email}
                editBranch={(val: any) => this.props.auth.editBranch(this.props.id, "email", val)}/>

    const baseContact = <Editable field="Contacto Base"
                placeholder='sin contacto base' forcePlaceholder={true}
                text={(getMainOrDefault(this.props.main_contact, this.state.contacts) || {}).name}
                editBranch={this.editOrCreateContact}/>

    const software = <EditableCreatable field="software"
                text={this.props.software} forcePlaceholder={true}
                selectProps={{labelKey: 'name', valueKey: 'name', placeholder: 'sin software',
                  options: this.state.listaSoftware, onNewOptionClick: this.softwareNewOption.bind(this),
                }}
                editBranch={(val: any) => this.props.auth.editLead(this.props.leadId, "software", val)}/>

    const modulos = <CheckboxesContainer field="modules"
                options={this.state.listaModulos} leadId={this.props.leadId}
                editLead={(id: number, attr: string, val: any) => this.props.auth.editLead(id,attr,val)}/>

    return <CampaignContext.Consumer>
      {campaign => <div id="popup-form-actividades-wrapper">
        <div className="left-side with-margin">
          <ActivityDetailsTable title={leadName} subtitle={phone}
            rows={[
              {label: 'No. empleados:', value: empleados},
              {label: 'Origen', value: this.props.branch_id || this.props.leadId},
              {label: 'Contacto', value: baseContact},
              {label: 'Software:', value: software},
              {label: 'Tipo actividad:', value: this.props.activity_name ? this.props.activity_name : '-'},
              {label: 'Dirección:', value: direccion},
              {label: 'Website', value: website},
              {label: 'Email:', value: email},
              {label: 'Módulos interés:', value: modulos},
            ]}/>
          <ActivityDetailsTable title='Última Actividad'
            rows={[
              {label: 'Fecha:', value: moment(this.props.timestamp.replace(' GMT', '')).locale('es').format("DD MMM YYYY HH:mm")},
              {label: 'Tipo:', value: this.props.tipo ? this.props.tipo : '-'},
              {label: 'Encargado:', value: this.state.usuarios && this.props.encargado && this.state.usuarios.find((obj: any) => obj.id === this.props.encargado).name},
              {label: 'Estatus:', value: this.props.estatus ? this.props.estatus : '-'},
              {label: 'Área contactada:', value: this.props.area_contactada ? this.props.area_contactada : '-'},
              {label: 'Contacto:', value: contactName},
              {label: 'Tel. contacto:', value: contactPhone},
              {label: 'Descripción:', value: descripcion},
            ]}/>
          <ActivityDetailsTable title='Próxima Actividad'
            rows={[
              {label: 'Tipo:', value: this.props.prox_act ? this.props.prox_act : '-'},
              {label: 'Fecha:', value: moment(this.props.prox_act_prop.replace(' GMT', '')).locale('es').format("DD MMM YYYY HH:mm")},
              {label: 'Anotaciones:', value: anotaciones},
            ]}/>
        </div>
        <div className="right-side with-margin">
          <h3>Registrar Actividad:</h3>
          <ActivityFormContainer auth={this.props.auth}
            leadId={this.props.leadId} tipo={this.props.prox_act}
            campaignId={campaign}
            encargado={this.props.encargado_prop} area_contactada={this.props.area_contactada}
            software={this.props.software} contact={this.props.contact}
            usuarios={this.state.usuarios} listaSoftware={this.state.listaSoftware}
            contacts={this.state.contacts}
            onActivityCreate={(newData: any)=>{this.props.updateRow(this.props.leadId, newData)}}/>
        </div>
      </div>
    }
    </CampaignContext.Consumer>
  }
}