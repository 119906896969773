import * as React from 'react';
import {Switch, Route, Redirect } from 'react-router';

// Local modules
import DetailsPage from './DetailsPage/DetailsPage';
// import Layout from './Layout';
import ListoAuthService from './utils/ListoAuthService';
import Login from './Login';
import NotFoundPage from './NotFoundPage';
import TabManager from './TabManager';

const auth = new ListoAuthService();
const userPermissions = auth.getUserPermissions() || {};

const listoAuth = (_: any, replace: any) => {
  if(!auth.loggedIn()){
    replace({ pathname: '/login' })
  }
}

export default () => (
  <Switch>
    <Route exact={true} path="/" render={() => <Redirect to="/actividades" push={true} />} auth={auth} />
    <Route exact={true} path="/login" component={Login}  />
    <Route exact={true} path="/actividades" component={TabManager} onEnter={listoAuth}/>
    <Route exact={true} path="/nuevos" component={TabManager} onEnter={listoAuth}/>
    <Route exact={true} path="/citas" component={TabManager} onEnter={listoAuth}/>
    <Route exact={true} path="/todas_actividades" component={TabManager} onEnter={listoAuth}/>
    <Route exact={true} path="/todos_leads" component={TabManager} onEnter={listoAuth}/>
    <Route exact={true} path="/todos_nuevos" component={TabManager} onEnter={listoAuth}/>
    <Route exact={true} path="/agregar" component={TabManager} onEnter={listoAuth}/>
    { userPermissions.console && <Route exact={true} path="/consola" component={TabManager} onEnter={listoAuth} /> }
    <Route path="/:id(\d+)" component={DetailsPage} onEnter={listoAuth} />
    <Route path="*" component={NotFoundPage}/>
  </Switch>
)
