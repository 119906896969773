import * as React from "react";
import {EventEmitter} from 'events';
import PopupHeader from './PopupHeader';

interface State{
  content: any;
  title: string;
  visible?: boolean;
  confirmCondition: () => boolean;
}

const SHOW_EVENT    = 'show';
const CLOSE_EVENT   = 'close';
const INITIALSTATE = {
  content: null,
  visible: false,
  confirmCondition: () => true,
  title: "",
}

export default class Popup extends React.Component<any, State>{
  static displayName = 'PopupHeader'

  static ManagerClass: any = class extends EventEmitter{}

  static Manager = new Popup.ManagerClass()

  static close(){
    Popup.Manager.emit(CLOSE_EVENT);
  }

  static show(data: any){
    Popup.Manager.emit(SHOW_EVENT, data);
  }

  constructor(props: any){
    super(props)
    this.state = INITIALSTATE
    this.onClose = this.onClose.bind(this)
  }

  componentDidMount(){
    Popup.Manager.on(SHOW_EVENT, (data: State) =>{
      this.setState(Object.assign({}, INITIALSTATE, {visible: true}, data))
    })
    Popup.Manager.on(CLOSE_EVENT, () =>{
      this.setState(INITIALSTATE)
    })
  }

  onClose(){
    if (this.state.confirmCondition()){
      Popup.close();
    }
    else{
      if(confirm('No has guardado la actividad. ¿Quieres cerrar la ventana?')){
        Popup.close();
      }
    }
  }

  render(){
    if(this.state.visible){
      return <div className="popup-wrapper">
        <article className="popup-article">
          <PopupHeader onClose={this.onClose} title={this.state.title}
            confirmCondition={this.state.confirmCondition}/>
          <div className="popup-content-box">
            {this.state.content}
          </div>
        </article>
      </div>
    }
    else{
      return null
    }
  }
}