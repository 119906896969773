import * as React from 'react';
import { Table } from 'react-bootstrap';

const TableRow = (props: any) => {
    return(<tr>
      <td><b>{props.label}</b></td>
      <td>{props.value}</td>
    </tr>)
}

export default class ActivityDetailsTable extends React.Component<any, any>{
  constructor(props: any){
    super(props);this.state={}
  }
  render(){
    const rows = this.props.rows && this.props.rows.map((r:any, i:any) => <TableRow key={i} {...r}/>)
    return(<div>
      <h3 className="capitalize-all-words">{this.props.title}</h3>
      {this.props.subtitle}
      <Table className="short-rows" responsive={true}>
        <tbody>
          {rows}
        </tbody>
      </Table>
    </div>)
  }
}
