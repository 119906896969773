import * as React from 'react';
import * as ReactDataGrid from 'react-data-grid';
import Alert from 'react-s-alert';

// Local modules
import { colsAllUnassigned } from '../data/columns';
import { RSLink, Ellipsis, WebSite, Phone, Score, LocalizacionFormatter } from '../CustomComponents/GridComponents';
import { Toolbar, Data } from 'react-data-grid-addons'
import ListoAuthService from '../utils/ListoAuthService';
import {getMainOrDefault} from '../utils/commonFuncs';

const { Selectors } = Data

class GridTodosNuevos extends React.Component<any, any>{
  static defaultProps = {
    auth: new ListoAuthService()
  }

  constructor(props: any){
    super(props);
    this.state = {
      divheight: 950,
      rows: [],
      finishedLoading: false,
    };
    this.getRows = this.getRows.bind(this);
    this.getSize = this.getSize.bind(this);
    this.handleGridSort = this.handleGridSort.bind(this)
    this.handleRowUpdated = this.handleRowUpdated.bind(this)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.onClearFilters = this.onClearFilters.bind(this)
    this.rowGetter = this.rowGetter.bind(this)
  }

  componentDidMount(){
    const cols = colsAllUnassigned;

    const removeDuplicates = (myArr: any, prop: any) => {
      return myArr.filter((obj: any, i: number, arr: any[]) => arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === i);
    }

    cols.find(d => d.key === 'website' )!.formatter =  <WebSite />;
    cols.find(d => d.key === 'phone' )!.formatter =  <Phone />;
    cols.find(d => d.key === 'score' )!.formatter =  <Score />;
    cols.find(d => d.key === 'localizacion' )!.formatter =  <LocalizacionFormatter />;
    cols.find(d => d.key === 'name' )!.formatter =  <RSLink rowGetter={(x: any) => this.rowGetter(x)}/>;
    this.props.auth.getUsers().then((res: any)=>{
      const requests: any = []
      res.data.objects.map((u: any) => u.email).forEach((e: any) => {
        requests.push(this.props.auth.getAssignedScored(e));
      })
      Promise.all(requests).then(responses=>{
        const rows: any = [];
        let leads: any = [];
        responses.forEach((r: any) => {leads = leads.concat(r.data.objects)})
        leads = removeDuplicates(leads, 'id')
        leads.sort((a: any, b: any) => b.score - a.score)
        leads.forEach((obj: any, i: number) => {
          const mainBranch = getMainOrDefault(obj.main_branch, obj.branches);
          rows.push(Object.assign({}, mainBranch, {
            leadId: obj.id,
            ejecutivo: res.data.objects.find((user: any) => user.email === obj.user[0].email).name,
            name: obj.name,
            score: obj.score ? obj.score : '',
            localizacion: (mainBranch.nomb_asent ? `${mainBranch.nomb_asent.toLowerCase()}, `: '') + (mainBranch.municipio ? mainBranch.municipio: ''),
            sucursales: obj.branches.length,
          }));
        });
        this.setState({ rows, divheight: window.innerHeight, columns: cols, finishedLoading: true});
      })
    })

    window.addEventListener("resize", () => {
      this.setState({divheight: window.innerHeight});
    });

  }

  getRows() {
    return Selectors.getRows(this.state);
  }

  getSize() {
    return this.getRows().length;
  }

  rowGetter(i: any){
    const rows = this.getRows();
    return rows[i];
  }

  handleGridSort(sortColumn: any, sortDirection: any) {
    const state = Object.assign({}, this.state, {sortColumn, sortDirection});
    this.setState(state);
  }

  handleRowUpdated(e: any){
    const rows = this.state.rows;
    Object.assign(rows[e.rowIdx], e.updated);
    this.setState({ rows });
  }

  handleFilterChange(filter: any){
    const newFilters = Object.assign({}, this.state.filters);
    if (filter.filterTerm) {
      newFilters[filter.column.key] = filter;
    } else {
     delete newFilters[filter.column.key];
    }
    this.setState({filters: newFilters});
  }

  onClearFilters(){
    this.setState({filters: {} });
  }

  render(){
    if (this.state.rows.length === 0){
      return <div className="no-columns-message">{this.state.finishedLoading ? 'No se tiene información' : <Ellipsis />}</div>
    }
    return (
      <div>
        <ReactDataGrid
          enableCellSelect={true}
          onGridSort={this.handleGridSort}
          onRowUpdated={this.handleRowUpdated}
          onAddFilter={this.handleFilterChange}
          onClearFilters={this.onClearFilters}
          toolbar={<Toolbar enableFilter={true} />}
          columns={this.state.columns}
          rowGetter={this.rowGetter}
          rowsCount={this.getSize()}
          minHeight={this.state.divheight-43-48-20} />;
        <Alert stack={{limit: 3}} html={true} />
      </div>);
  }
}

export default GridTodosNuevos;