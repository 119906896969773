import * as moment from 'moment';
import * as React from 'react';
import Collapsible from "react-collapsible";
import {ActivityInformationHeaderProps, ActivityInformation} from './models';

function ActivityInformationHeader(props: ActivityInformationHeaderProps){
  return (<div>
    <h4>{`Actividad ${props.i}`}</h4>
    <span className="activity-information-header-span">{moment(props.timestamp).locale('es').format("DD MMM YYYY")}</span>
    <span className="activity-information-header-span">{props.tipo || 'Sin tipo'}</span>
    <span className="activity-information-header-span">{props.estatus || 'Sin estatus'}</span>
    <span className="activity-information-header-span">{props.area_contactada || 'Sin área contactada'}</span>
    <span className="activity-information-header-span">{props.descripcion ? `${props.descripcion.substring(0,17)}...` : 'Sin comentarios'}</span>
  </div>);
}

function ActivityInformation(props: ActivityInformation){
  return (<Collapsible trigger={<ActivityInformationHeader {...props} />}>
    <p className="left-aligned"><b>Fecha actividad realizada: </b>{props.timestamp ? moment(props.timestamp).locale('es').format('DD MMM YYYY HH:mm') : '-'}</p>
    <p className="left-aligned"><b>Tipo: </b>{props.tipo || '-'}</p>
    <p className="left-aligned"><b>Encargado: </b>{props.encargado ? props.users.filter((obj: any) => obj.id === props.encargado)[0].name : '-'}</p>
    <p className="left-aligned"><b>Contacto: </b>{props.contact ? (props.contacts.find((c: any) => c.id === props.contact) || {} as any).name : '-'}</p>
    <p className="left-aligned"><b>Estatus: </b>{props.estatus || '-'}</p>
    <p className="left-aligned"><b>Area contactada: </b>{props.area_contactada || '-'}</p>
    <p className="left-aligned"><b>Descripción: </b>{props.descripcion || '-'}</p>
    <p className="left-aligned"><b>Próxima actividad: </b>{props.prox_act || '-'}</p>
    <p className="left-aligned"><b>Anotaciones para próxima actividad: </b>{props.prox_act_anotacion || '-'}</p>
    <p className="left-aligned"><b>Fecha próxima actividad propuesta: </b>{props.prox_act_prop ? moment(props.prox_act_prop).locale('es').format('DD MMM YYYY HH:mm') : '-'}</p>
    <p className="left-aligned"><b>Razón de no interés: </b>{props.razon_no_interes || '-'}</p>
  </Collapsible>);
}

export default ActivityInformation;