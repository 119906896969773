import * as React from "react";
import { FaCalendar,  FaCheck, FaClock, FaHourglassHalf, FaRss, FaThumbsDown, FaUsers, FaUserTimes,
         FaPhoneSlash, FaDesktop, FaBuilding, FaMailBulk } from 'react-icons/fa';
import { MdAssignment, MdAssignmentLate, MdAutorenew, MdBusiness, MdCancel, MdContactPhone,  MdEvent,
         MdLocalCafe, MdPermIdentity,  MdPhoneForwarded,  MdPhoneLocked, MdSimCardAlert, MdSupervisorAccount,
         MdWarning } from "react-icons/md";
import { TiAdjustBrightness, TiExport, TiGroup } from "react-icons/ti"

const seguimientoLabel = <span><MdPhoneForwarded className='status-button-big-icon'/>Seguimiento</span>;
const envioInfoLabel = <span><TiExport className='status-button-big-icon'/> Envío de información</span>;
// const envioCorreoLabel = <span><MdMail className='status-button-big-icon'/> Envío de correo</span>;
const noLocalizadoLabel = <span><FaUserTimes className='status-button-big-icon'/> No localizado</span>;
const bloqueoRecLabel = <span><MdPhoneLocked className='status-button-big-icon'/> Bloqueo de recepción</span>;
const datosIncorrLabel = <span><MdSimCardAlert className='status-button-big-icon'/> Datos incorrectos</span>;
const contactoDupLabel = <span><MdSupervisorAccount className='status-button-big-icon'/> Contacto duplicado</span>;
const noInteresaLabel = <span><MdWarning className='status-button-big-icon'/> No interesado</span>;
const noCualificadoLabel = <span><FaThumbsDown className='status-button-big-icon'/> No cualificado</span>;
const obtuvoCitaLabel = <span><MdEvent className='status-button-big-icon'/> Obtuvo Cita</span>;

const completadaLabel = <span><FaCheck className='status-button-big-icon'/> Completada</span>;
const cambioEncargadoLabel = <span><MdAutorenew className='status-button-big-icon'/> Cambiar encargado</span>;
const reagendadaLabel = <span><MdAssignment className='status-button-big-icon'/> Reagendada</span>;
const pendienteReagendamientoLabel = <span><MdAssignmentLate className='status-button-big-icon'/> Pendiente de Reagendamiento</span>;
const recBloqueaLabel = <span><MdPhoneLocked className='status-button-big-icon'/> Bloqueo de recepción</span>;
const canceladaLabel = <span><MdCancel className='status-button-big-icon'/> Cancelada</span>;

const llamadaLabel = <span><FaCheck className='status-button-big-icon'/> Llamada</span>;
const citaRemLabel = <span><FaRss className='status-button-big-icon'/> Cita remota</span>;
const citaListoLabel = <span><FaUsers className='status-button-big-icon'/> Cita en Listo</span>;
const citaClienteLabel = <span><MdBusiness className='status-button-big-icon'/> Cita con cliente</span>;
const noEnlazada = <span><FaPhoneSlash className='status-button-big-icon'/> No enlazada</span>;
const emailLabel = <span><FaMailBulk className='status-button-big-icon'/> Email</span>;

const recepcionLabel = <span><MdContactPhone className='status-button-big-icon'/> Recepción</span>
const encargadoLabel = <span><MdPermIdentity className='status-button-big-icon'/> Encargado</span>
const gerenciaLabel = <span><MdPermIdentity className='status-button-big-icon'/> Gerencia</span>
const tesoreriaLabel = <span><MdPermIdentity className='status-button-big-icon'/> Tesorería</span>
const contralorLabel = <span><MdPermIdentity className='status-button-big-icon'/> Contralor</span>
const fiscalLabel = <span><MdPermIdentity className='status-button-big-icon'/> Fiscal</span>
const dirFinanzasLabel = <span><FaBuilding className='status-button-big-icon'/> Director de Finanzas</span>
const dirGeneralLabel = <span><FaBuilding className='status-button-big-icon'/> Director General</span>
const areaComLabel = <span><TiGroup className='status-button-big-icon'/> Área de Compras</span>
const areaFinLabel = <span><TiGroup className='status-button-big-icon'/> Área de Finanzas</span>
const areaConLabel = <span><TiGroup className='status-button-big-icon'/> Área Contabilidad</span>
const cxpLabel = <span><TiGroup className='status-button-big-icon'/> Cuentas por pagar</span>
const cxcLabel = <span><TiGroup className='status-button-big-icon'/> Cuentas por cobrar</span>
const sistemasLabel = <span><FaDesktop className='status-button-big-icon'/> Área de Sistemas</span>

const minutosLabel = <span><FaHourglassHalf className='status-button-big-icon'/> En 20 minutos</span>;
const horaLabel = <span><FaClock className='status-button-big-icon'/> En una hora</span>;
const comidaLabel = <span><MdLocalCafe className='status-button-big-icon'/> Después de la comida</span>;
const mañanaLabel = <span><TiAdjustBrightness className='status-button-big-icon'/> Mañana</span>;
const especifLabel = <span><FaCalendar className='status-button-big-icon'/> Especificar</span>;

export const listaTipo = [
  { value: 'Llamada', label: llamadaLabel, class: 'type-act-btn'},
  { value: 'Cita remota', label: citaRemLabel, class: 'type-act-btn'},
  { value: 'Cita presencial en Listo', label: citaListoLabel, class: 'type-act-btn'},
  { value: 'Cita presencial con cliente', label: citaClienteLabel, class: 'type-act-btn'},
  { value: 'No enlazada', label: noEnlazada, class: 'type-act-btn'},
  { value: 'Email', label: emailLabel, class: 'type-act-btn'},
];
export const listaTipoOld = [
  { value: 'Llamada', label: 'Llamada'},
  { value: 'Cita remota', label: 'Cita remota'},
  { value: 'Cita presencial en Listo', label: 'Cita presencial en Listo'},
  { value: 'Cita presencial con cliente', label: 'Cita presencial con cliente'},
];
export const listaEstatusCitaOld = [
  { value: 'Completada', label: 'Completada'},
  { value: 'Cambio de encargado', label: 'Cambio de encargado'},
  { value: 'Reagendada', label: 'Reagendada'},
  { value: 'Pendiente de Reagendamiento', label: 'Pendiente de Reagendamiento'},
  { value: 'Recepción bloquea la llamada', label: 'Recepción bloquea la llamada'},
  { value: 'Cancelada', label: 'Cancelada'},
];
export const listaEstatusLlamada = [
  {value: 'Seguimiento', class:'good-act-btn', label: seguimientoLabel, required: ['area_contactada', 'contact', 'descripcion', 'prox_act', 'btn-pipedrive', 'btn-save']},
  {value: 'Envío de Información', class:'good-act-btn', label: envioInfoLabel, required: ['area_contactada', 'contact', 'descripcion', 'prox_act', 'btn-pipedrive', 'btn-save']},
  {value: 'Obtuvo Cita', class:'good-act-btn', label: obtuvoCitaLabel, required: ['area_contactada', 'contact', 'descripcion', 'prox_act', 'btn-pipedrive', 'btn-save']},
  {value: 'No Localizado', class:'warning-act-btn', label: noLocalizadoLabel, required: ['descripcion', 'prox_act', 'btn-reject', 'btn-save']},
  {value: 'Bloqueo de recepción', class:'warning-act-btn', label: bloqueoRecLabel, required: ['descripcion', 'prox_act', 'btn-reject', 'btn-save']},
  {value: 'Datos Incorrectos', class:'bad-act-btn', label: datosIncorrLabel, required: ['descripcion', 'prox_act', 'btn-reject', 'btn-save']},
  {value: 'Contacto Duplicado', class:'bad-act-btn', label: contactoDupLabel, required: ['descripcion', 'prox_act', 'btn-reject', 'btn-save']},
  {value: 'No Interesado', class:'bad-act-btn', label: noInteresaLabel, required: ['descripcion', 'razon_no_interes', 'btn-reject']},
  {value: 'No Cualificado', class:'bad-act-btn', label: noCualificadoLabel, required: ['descripcion', 'razon_no_interes', 'btn-reject']},
];
export const listaEstatusCita = [
  {value: 'Completada', class:'good-act-btn', label: completadaLabel, required: ['encargado', 'area_contactada', 'contact', 'descripcion', 'prox_act', 'btn-pipedrive', 'btn-save']},
  {value: 'Cambio de encargado', class:'warning-act-btn',label: cambioEncargadoLabel, required: ['encargado', 'descripcion', 'prox_act', 'btn-pipedrive', 'btn-save']},
  {value: 'Reagendada', class:'warning-act-btn', label: reagendadaLabel, required: ['area_contactada', 'contact', 'descripcion', 'prox_act', 'btn-pipedrive', 'btn-save']},
  {value: 'Pendiente de Reagendamiento', class:'warning-act-btn', label: pendienteReagendamientoLabel, required: ['descripcion', 'prox_act', 'btn-reject', 'btn-save']},
  {value: 'Recepción bloquea la llamada', class:'bad-act-btn', label: recBloqueaLabel, required: ['descripcion', 'prox_act', 'btn-reject', 'btn-save']},
  {value: 'Cancelada', class:'bad-act-btn', label: canceladaLabel, required: ['descripcion', 'prox_act', 'btn-reject', 'btn-save']},
];
export const listaEstatusLlamadaOld = [
  { value: 'Envío de Información', label: 'Envío de Información'},
  { value: 'Seguimiento', label: 'Seguimiento'},
  { value: 'Envío de correo', label: 'Envío de correo'},
  { value: 'No Localizado', label: 'No Localizado'},
  { value: 'Bloqueo de recepción', label: 'Bloqueo de recepción'},
  { value: 'Datos Incorrectos', label: 'Datos Incorrectos'},
  { value: 'No Interesado', label: 'No Interesado'},
  { value: 'Contacto Duplicado', label: 'Contacto Duplicado'}
];
export const listaAreaContactada = [
  { value: 'Área Contabilidad', label: areaConLabel},
  { value: 'Área de Compras', label: areaComLabel},
  { value: 'Área de Finanzas', label: areaFinLabel},
  { value: 'Área de Sistemas', label: sistemasLabel},
  { value: 'Contralor', label: contralorLabel},
  { value: 'Cuentas por cobrar', label: cxcLabel},
  { value: 'Cuentas por pagar', label: cxpLabel},
  { value: 'Director de Finanzas', label: dirFinanzasLabel},
  { value: 'Director General', label: dirGeneralLabel},
  { value: 'Encargado', label: encargadoLabel},
  { value: 'Fiscal', label: fiscalLabel},
  { value: 'Gerencia', label: gerenciaLabel},
  { value: 'Recepción', label: recepcionLabel},
  { value: 'Tesorería', label: tesoreriaLabel},
  { value: 'No localizado', label: noLocalizadoLabel},
];
export const listaRazonesNoInteres = [
  { value: 'Ya tiene otra herramienta', label: 'Ya tiene otra herramienta'},
  { value: 'Utiliza un despacho externo', label: 'Utiliza un despacho externo'},
  { value: 'No maneja viáticos', label: 'No maneja viáticos'},
  { value: 'No tiene poder de decision', label: 'No tiene poder de decision'},
  { value: 'No proporciona razones', label: 'No proporciona razones'},
  { value: 'Necesidades ya cubiertas', label: 'Necesidades ya cubiertas'},
  { value: 'Gobierno', label: 'Gobierno'},
];
export const proximasFechas = [
  { value: 20, label: minutosLabel},
  { value: 60, label: horaLabel},
  { value: 240, label: comidaLabel},
  { value: 1440, label: mañanaLabel},
  { value: 0, label: especifLabel},
];
export const listaScore = [
  { values: [10], label: 'Urgente', value: 10},
  { values: [5,6,7,8,9], label: 'Alto', value: 5},
  { values: [0,1,2,3,4], label: 'Normal', value: 0},
  { values: [-1], label: 'Gobierno', value: -1},
  { values: [-2], label: 'No se desea trabajar', value: -2},
  { values: [-3], label: 'No estuvo interesado', value: -3},
  { values: [-4], label: 'No hay datos de contacto', value: -4},
  { values: [-99], label: 'En revisión', value: -99},
];
export const listaCiudades = [
  { value: 'distrito federal', label: 'Distrito Federal'},
  { value: 'mexico', label: 'Estado de México'},
  { value: 'nuevo leon', label: 'Nuevo León'},
  { value: 'jalisco', label: 'Jalisco'},
];
export const listaSpeech = [
  { value: 'speech1', label: 'Campaña 1'},
  { value: 'speech2', label: 'Campaña 2'},
];
export const numEmpleados = [
  { value: '0 a 5 personas', label: '0 a 5 personas'},
  { value: '6 a 10 personas', label: '6 a 10 personas'},
  { value: '11 a 30 personas', label: '11 a 30 personas'},
  { value: '31 a 50 personas', label: '31 a 50 personas'},
  { value: '51 a 100 personas', label: '51 a 100 personas'},
  { value: '101 a 250 personas', label: '101 a 250 personas'},
  { value: '251 y más personas', label: '251 y más personas'},
];
export const listaModulos = [
  { value: 'Viáticos', label: 'Viáticos'},
  { value: 'Bancos', label: 'Bancos'},
  { value: 'Descarga', label: 'Descarga'},
  { value: 'Facturación', label: 'Facturación'},
  { value: 'Impuestos', label: 'Impuestos'},
  { value: 'Reportes', label: 'Reportes'},
  { value: 'Portal prov.', label: 'Portal prov.'},
];