import * as React from 'react';
import Select, {Creatable} from "react-select";

export class CustomDropdown extends React.Component<any, any>{
  render(){
    return (
      <div className={this.props.divClassName}>
        <div className={this.props.labelClassName}>{this.props.label}</div>
        <div className={this.props.selectContClassName}>
          <Select {...this.props} />
        </div>
      </div>
    );
  }
}

export class CustomCreatable extends React.Component<any, any>{
  render(){
    return (
      <div className={this.props.divClassName}>
        <div className={this.props.labelClassName}>{this.props.label}</div>
        <div className={this.props.selectContClassName}>
          <Creatable {...this.props} />
        </div>
      </div>
    );
  }
}