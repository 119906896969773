import * as React from 'react';
import * as ReactDataGrid from 'react-data-grid';
import { Glyphicon } from 'react-bootstrap';
import { Toolbar, Data } from 'react-data-grid-addons'

// Local modules
import { colsAllLeads } from '../data/columns';
import { RSLink, Ellipsis, Score, LocalizacionFormatter } from '../CustomComponents/GridComponents';
import ListoAuthService from '../utils/ListoAuthService';
import Popup from '../BasePopUp/Popup';
import PopupFormTransferir from '../CustomPopUps/PopupFormTransferir';
import {getMainOrDefault} from '../utils/commonFuncs';

const { Selectors } = Data

class AssignToOtherFormatter extends React.Component<any, any>{
  constructor(props: any){
    super(props);
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick(){
    const row = this.props.rowGetter(this.props.rowIdx);
    Popup.show({
      title: 'Asignar a otro ejecutivo',
      content: <PopupFormTransferir
        callback={(newEmail: any) => { this.props.rowModifier(this.props.rowIdx, newEmail) }}
        razon_social={row.name}
        leadId={row.leadId}
        auth={this.props.auth}
        options={this.props.ejecutivos} />
    });
  }

  render(){
    if(this.props.edit === false || this.props.view === false){
      return(<div>
        <span className="transfer-span disabled-edit">
          <Glyphicon glyph="share"/>
          <span> Asignar a otro</span>
        </span>
      </div>);
    }

    return(<div>
        <span className="transfer-span" onClick={this.handleClick}>
          <Glyphicon glyph="share"/>
          <span> Asignar a otro</span>
        </span>
      </div>);
  }
}

class GridTodosLeads extends React.Component<any, any>{
  static defaultProps = {
    auth: new ListoAuthService()
  }

  private mounted: boolean = false

  constructor(props: any){
    super(props);
    this.state = {
      columns: [{key: 'color', name: ' ', width: 50}],
      divheight: 900,
      rows: [],
    }
    this.getRows = this.getRows.bind(this);
    this.getSize = this.getSize.bind(this);
    this.handleGridSort = this.handleGridSort.bind(this)
    this.handleRowUpdated = this.handleRowUpdated.bind(this)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.onClearFilters = this.onClearFilters.bind(this)
    this.rowGetter = this.rowGetter.bind(this)
  }

  componentWillUnmount(){
    this.mounted = false;
  }

  componentDidMount(){
    this.mounted = true;
    const cols = colsAllLeads;
    let users: any;
    cols.find(d => d.key === 'score' )!.formatter =  <Score />;
    cols.find(d => d.key === 'localizacion' )!.formatter =  <LocalizacionFormatter />;
    cols.find(d => d.key === 'name' )!.formatter =  <RSLink rowGetter={(x: any) => this.rowGetter(x)}/>;
    const recursivePromise = (page: any) => {
      this.props.auth.getAllLeads(page).then((res: any)=> {
        const rows = res.data.objects.map((obj: any)=>{
          const mainBranch = getMainOrDefault(obj.main_branch, obj.branches);
          return {
            name: obj.name,
            localizacion: mainBranch ? ((mainBranch.nomb_asent ? `${mainBranch.nomb_asent.toLowerCase()}, `: '') + (mainBranch.municipio ? mainBranch.municipio: '')) : '',
            leadId: obj.id,
            score: obj.score,
            employees: mainBranch ? mainBranch.employees : '',
            postal_code: mainBranch ? mainBranch.postal_code : '',
            sucursales: obj.branches.length,
            ejecutivo: users.find((usr: any) => usr.email === obj.user[0].email ).name,
          }})
        this.setState({ rows: this.state.rows.concat(rows), finishedLoading: true })
        if(this.mounted && res.data.objects.length !== 0){ recursivePromise(page + 1) }
      })
    }

    this.props.auth.getUsers().then((res: any)=> {
      users = res.data.objects;
      cols.find(d => d.key === 'asignar' )!.formatter =  <AssignToOtherFormatter
        edit={this.props.transfer_lead ? this.props.transfer_lead.edit : true}
        view={this.props.transfer_lead ? this.props.transfer_lead.view : true}
        auth={this.props.auth}
        ejecutivos={users.map((obj: any) => ({label: obj.name, value: obj.email}))}
        rowGetter={(x: any) => this.rowGetter(x)}
        rowModifier={ (i: any, newEmail: any) => {
          const rows = this.state.rows;
          rows[i].ejecutivo = users.find((usr: any) => usr.email === newEmail ).name;
          this.setState({ rows }) }}/>;

      this.setState({divheight: window.innerHeight, columns: cols})
      recursivePromise(1)
    })

    window.addEventListener("resize", () => {
      this.setState({divheight: window.innerHeight});
    });
  }

  getRows() {
    return Selectors.getRows(this.state);
  }

  getSize() {
    return this.getRows().length;
  }

  rowGetter(i: number){
    const rows = this.getRows();
    return rows[i];
  }

  handleGridSort(sortColumn: any, sortDirection: any) {
    const state = Object.assign({}, this.state, { sortColumn, sortDirection});
    this.setState(state);
  }

  handleRowUpdated(e: any){
    const rows = this.state.rows;
    Object.assign(rows[e.rowIdx], e.updated);
    this.setState({ rows });
  }

  handleFilterChange(filter: any){
    const newFilters = Object.assign({}, this.state.filters);
    if (filter.filterTerm) {
      newFilters[filter.column.key] = filter;
    } else {
     delete newFilters[filter.column.key];
    }
    this.setState({filters: newFilters});
  }

  onClearFilters(){
    this.setState({filters: {} });
  }

  render(){
    if (this.state.rows.length === 0){
      return <div className="no-columns-message">{this.state.finishedLoading ? 'No se tiene información' : <Ellipsis />}</div>
    }
    return (
      <div>
        <ReactDataGrid
          enableCellSelect={true}
          onGridSort={this.handleGridSort}
          onRowUpdated={this.handleRowUpdated}
          onAddFilter={this.handleFilterChange}
          onClearFilters={this.onClearFilters}
          toolbar={<Toolbar enableFilter={true} />}
          columns={this.state.columns}
          rowGetter={this.rowGetter}
          rowsCount={this.getSize()}
          minHeight={this.state.divheight-43-48-20} />;
      </div>);
  }
}

export default GridTodosLeads;