import * as React from 'react';
import Alert from 'react-s-alert';

const Checkbox = (props: any) =><div className="checkbox custom-chk">
    <input
      type="checkbox"
      checked={props.isChecked}
      onChange={props.nullFunction} />
    <label onClick={props.onClick}>{props.label}</label>
  </div>


export default class CheckboxesContainer extends React.Component<any, any>{
  constructor(props: any){
    super(props);
    this.state={
      modules: []
    }
    this.onClickOption = this.onClickOption.bind(this)
  }

  componentWillReceiveProps(np: any){
    this.setState({modules: np.options})
  }

  onClickOption(value: any){
    const { modules } = this.state;
    const values = modules.map((o: any) => o.isChecked ? o.value : null).filter((o: any) => o)
    const mod = modules.find((m: any) => m.value === value);
    mod.isChecked = !mod.isChecked;
    this.props.editLead(this.props.leadId, this.props.field, values).then((res: any)=>{
      this.setState({ modules })
    }).catch(()=>{
      console.log(this.props.leadId, this.props.field, values);
      Alert.error(`Error`, { position: 'bottom-left', effect: 'jelly', timeout: 2000});
    })
  }

  render(){
    const {modules} = this.state;
    const nullFunction  = () => undefined
    return(<div>
      {modules.map((option: any, i: any) => {
        const onClick = () => this.onClickOption(option.value)
        return <Checkbox
          key={i}
          onClick={onClick}
          onChange={nullFunction}
          {...option}/>
      })
      }
    </div>)
  }
}
